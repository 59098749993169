import { Divider, Modal, Space, Spin, message } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { CreditCardOutlined } from '@ant-design/icons';
import { InvoiceTable } from './InvoiceTable';
import { BillTable } from './BillTable';
import { useApp } from '@/utils/useapp';
import { TBill } from '@/types';
import AccountingActionTips from '@/pages/dispatchs/components/AccountingActionTips';
import Decimal from 'decimal.js';
import { INVOICE_TYPE_FTL } from '@/pages/accounting/invoices/components/data';
import { BILL_TYPE_FTL } from '@/pages/accounting/bills/components/data';
import { FTLShipmentInterface } from '../../shipments/Interfaces/ShipmentInterface';
import {
  transferTasksIconColor,
  ACCOUNT_ICON,
} from '@/components/Tasks/Interfaces/TaskIconColorInterface';

export const AccountingModal: React.FC<{
  ftlShipment: FTLShipmentInterface;
  onChanged?: () => void;
  showAccountingTips?: boolean;
}> = ({ ftlShipment, onChanged, showAccountingTips = false }) => {
  const app = useApp();

  const [open, setOpen] = React.useState<boolean>(false);

  const accountIconList: any[] = useMemo(() => {
    return transferTasksIconColor(ftlShipment.task_icon_colors);
  }, [ftlShipment]);

  const accountIconColor = useMemo(
    () =>
      ftlShipment.task_icon_colors
        ? ftlShipment.task_icon_colors[ACCOUNT_ICON] || 'gray'
        : 'gray',
    [ftlShipment],
  );

  const [invoicesTotal, setInvoicesTotal] = React.useState(0);
  const [billsTotal, setBillsTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [allInvoices, setAllInvoices] = React.useState<Array<any>>([]);
  const [allBills, setAllBills] = React.useState<TBill[]>([]);
  const [invoicesSelected, setInvoiceSelected] = React.useState<any>({});
  const fetchInvoicesData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get('orderInvoices', {
        params: {
          invoiceable_id: ftlShipment.id,
          invoiceable_type: INVOICE_TYPE_FTL,
          per_page: 1000,
        },
      });

      setAllInvoices(response.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBillsData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get('orderBills', {
        params: {
          billable_id: ftlShipment.id,
          billable_type: BILL_TYPE_FTL,
          per_page: 1000,
        },
      });

      setAllBills(response.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleSavedBill = () => {
    fetchBillsData();
    onChanged && onChanged();
  };

  const handleSavedInvoice = () => {
    fetchInvoicesData();
    onChanged && onChanged();
  };

  const handleSettled = () => {
    fetchInvoicesData();
  };

  const invoices = React.useMemo(() => {
    return allInvoices;
  }, [allInvoices]);

  const activeSelectedInvoices = React.useMemo(() => {
    // convert object to array
    return Object.values(invoicesSelected);
  }, [invoicesSelected, allInvoices]);

  const bills = React.useMemo(() => {
    return allBills;
  }, [allBills]);

  const total = useMemo(() => {
    return new Decimal(invoicesTotal).minus(new Decimal(billsTotal));
  }, [invoicesTotal, billsTotal]);

  React.useEffect(() => {
    if (open && ftlShipment?.id) {
      // fetchContainers();
      fetchBillsData();
      fetchInvoicesData();
    }
  }, [open, ftlShipment]);

  return (
    <>
      {showAccountingTips ? (
        <AccountingActionTips list={accountIconList} color={accountIconColor}>
          <div onClick={() => setOpen(!open)}>
            <CreditCardOutlined />
          </div>
        </AccountingActionTips>
      ) : (
        <div onClick={() => setOpen(!open)}>
          <CreditCardOutlined />
        </div>
      )}
      {open && (
        <>
          <Modal
            title="Accounting"
            open={open}
            onCancel={() => setOpen(!open)}
            width="80vw"
            footer={
              <Space className="w100" direction="vertical" align="end">
                <h3>Total {total.toFixed(2)}</h3>
              </Space>
            }
          >
            <Spin spinning={loading}>
              <>
                <InvoiceTable
                  id={ftlShipment.id}
                  invoices={invoices}
                  rowSelection={{
                    onSelect: (a: any, selectedRows: any) => {
                      setInvoiceSelected({
                        ...invoicesSelected,
                        ...selectedRows,
                      });
                    },
                    onSelectAll: (a: any, selectedRows: any) => {
                      setInvoiceSelected({
                        ...selectedRows,
                      });
                    },
                  }}
                  activeSelectedInvoices={activeSelectedInvoices}
                  onSaved={handleSavedInvoice}
                  onDeleted={handleSavedInvoice}
                  onSettled={handleSettled}
                  setInvoiceTotal={(total: number) => setInvoicesTotal(total)}
                />
                <Divider />
                <BillTable
                  bills={bills}
                  onSaved={handleSavedBill}
                  id={ftlShipment.id}
                  setBillsTotal={(total: number) => setBillsTotal(total)}
                />
              </>
            </Spin>
          </Modal>
        </>
      )}
    </>
  );
};
