import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Switch,
  message,
  Popconfirm,
  Space,
  TablePaginationConfig,
  Tooltip,
  Typography,
  Table,
  Tag,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { TInvoice, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import Import from './components/Import';
import { SorterResult } from 'antd/lib/table/interface';
import ReceivePayment from './components/ReceivePayment';
import EditInvoice from './components/EditInvoice';
import AutoResizeTable from '@/components/AutoResizeTable';
import { saveAs } from 'file-saver';
import { truncate } from 'lodash';
import { auth } from '@/stores';
import { Filter } from './components/Filter';
import { CustomerConfirmColumn } from './components/CustomerConfirmColumn';
import { LogActivities } from '@/components/LogActivities';
// import { CACHE_CNTR_STATUS } from '@/stores/bufferCache';
import { observer } from 'mobx-react';
import { BulkUpdate } from './components/BulkUpdate';
import { DISPUTE_TYPE_INVOICE, DisputeMemo } from '@/components/DisputeMemo';
import { STATUS_IN_DISPUTE, STATUS_MAP } from './components/data';
import { useLocation } from 'umi';
import { INVOICE_TYPE_LTL, INVOICE_TYPE_ORDER } from './components/data';

const CNTRStatus: FC<{ numbers: any[] }> = observer(({ numbers }) => {
  if (!numbers || numbers.length == 0) {
    return <>-</>;
  }

  const app = useApp();
  const dispatch_status_name = useMemo(() => {
    return (
      app.store.bufferCache.getCntrStatus(numbers[0])?.dispatch_status_name ||
      '-'
    );
  }, [app.store.bufferCache.data]);

  return (
    <>
      {dispatch_status_name +
        ' ' +
        `${numbers.length > 1 ? numbers.length - 1 : ''}` || ''}{' '}
    </>
  );
});

const Index = ({ mode = {} }) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [selected, setSelected] = React.useState<Array<TInvoice>>([]);
  const [sorter, setSorted] = React.useState<
    SorterResult<TInvoice> | undefined
  >();
  const [data, setData] = React.useState<TCollection<TInvoice>>();
  const [openId, setOpenId] = React.useState(0);
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (
      pagination?: TablePaginationConfig,
      _?: any,
      _sorter?: SorterResult<TInvoice>,
    ) => {
      setLoading(true);
      setSorted(_sorter);

      try {
        const result = await app.service.get('orderInvoices', {
          params: {
            ...filter.getFieldsValue(),
            ..._,
            ...mode,
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
            sort_by: _sorter?.columnKey || _sorter?.field,
            sort_value:
              _sorter?.order === 'ascend'
                ? 'asc'
                : _sorter?.order === 'descend'
                ? 'desc'
                : undefined,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error || 'System Error');
      }

      setLoading(false);
    },
    [sorter, setSorted],
  );

  const location = useLocation();

  React.useEffect(() => {
    // fetchData();
    const queryString = location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const invoiceId: any = params.get('invoiceId') || 0;

      const _params: any = {};
      const salesId: any = params.get('salesId') || 0;
      const userId = params.get('userId') || 0;
      const due_start_date = params.get('dueStartDate') || '';
      const due_end_date = params.get('dueEndDate') || '';
      const state = params.get('state') || '';

      if (invoiceId) {
        _params.id = invoiceId;
      }

      if (userId) {
        _params.user_id = +userId;
      }

      if (due_start_date) {
        _params.date_field = 'penalty_at';
        _params.start_date = due_start_date;
      }

      if (due_end_date) {
        _params.date_field = 'penalty_at';
        _params.end_date = due_end_date;
      }

      if (salesId) {
        _params.has_due_amount = 'true';
        _params.releated_person_id = +salesId;
      }

      if (state == 'dispute') {
        _params.dispute = STATUS_IN_DISPUTE + '';
      } else {
        _params.type = state;
      }

      filter.setFieldsValue(_params);
      fetchData(pagination, { id: invoiceId });
      setOpenId(invoiceId);
    } else {
      fetchData();
    }
  }, [location.search]);

  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`orderInvoices/${id}`);

      message.success('Deleted');

      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleClearAll = async () => {
    filter.resetFields();
    await fetchData();
  };

  const handleConfirm = async (id: number, values: object) => {
    try {
      await app.service.patch(`orderInvoices/${id}/confirm`, { data: values });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    await fetchData();
  };

  const handleSaleConfirm = async (id: number, values: object) => {
    try {
      await app.service.patch(`orderInvoices/${id}/salesConfirm`, {
        data: values,
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    await fetchData();
  };

  const handleExport = async () => {
    setLoading(true);

    try {
      const file = await app.service.get('orderInvoices/export', {
        params: {
          ...filter.getFieldsValue(),
        },
        responseType: 'blob',
      });
      const filename = 'drayeasy-invoices.xlsx';
      saveAs(file, filename);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const refreshData = useCallback(async () => {
    await fetchData(pagination, null, sorter);
  }, [pagination.current, sorter]);

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'uid',
        key: 'id',
        sorter: true,
        width: 120,
        render: (text, record) => (
          <EditInvoice
            id={record.id}
            invoiceableId={record.invoiceable_id}
            invoiceableType={record.invoiceable_type}
            onSaved={fetchData}
            onDeleted={fetchData}
          >
            <a>{text}</a>
          </EditInvoice>
        ),
      },
      {
        title: 'Order#/LTL#',
        dataIndex: ['order', 'uid'],
        key: 'invoiceable_id',
        sorter: true,
        width: 100,
        render: (text, record) => {
          return record?.invoiceable?.uid || '';
        },
      },
      {
        title: 'MBL#/REF#',
        dataIndex: ['order', 'mbl_number'],
        key: 'order_id',
        // sorter: true,
        width: 160,
        render: (text, record) => {
          if (record.invoiceable_type == INVOICE_TYPE_ORDER) {
            return record?.invoiceable?.mbl_number || '';
          }
          if (record.invoiceable_type == INVOICE_TYPE_LTL) {
            return record?.invoiceable?.customer_reference_number || '';
          }
        },
      },
      {
        title: 'CNTR#/Pro#',
        dataIndex: 'container_numbers',
        key: 'container_numbers',
        render: (text, record) => {
          if (record?.invoiceable_type == INVOICE_TYPE_ORDER) {
            return (
              <>
                {record.container_numbers
                  ? record.container_numbers.join(',')
                  : '-'}
              </>
            );
          }
          if (record?.invoiceable_type == INVOICE_TYPE_LTL) {
            return record.invoiceable.pro_number || '';
          }
        },
        sorter: true,
        width: 150,
      },
      {
        title: 'Dispatch Status',
        dataIndex: 'container_numbers',
        key: 'container_status',
        render: (numbers: any, record) => {
          if (record.invoiceable_type == INVOICE_TYPE_ORDER) {
            if (numbers && numbers.length > 0) {
              for (const cntr of numbers) {
                app.store.bufferCache.debounceFetch('containerStatus', cntr);
              }
            }

            return <CNTRStatus numbers={numbers} />;
          }
          if (record.invoiceable_type == INVOICE_TYPE_LTL) {
            return record.invoiceable.bussiness_status_name;
          }
        },
        width: 150,
      },
      {
        title: 'Customer',
        dataIndex: ['user', 'name'],
        ellipsis: {
          showTitle: false,
        },
        width: 200,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            {truncate(text, { length: 20 })}{' '}
            {record.user?.bill_to ? `(${record.user.bill_to?.name})` : ''}
          </Tooltip>
        ),
      },
      {
        title: 'Dispute',
        key: 'dispute_at',
        width: 130,
        render: (record: TInvoice) => {
          return (
            <>
              <DisputeMemo
                type={DISPUTE_TYPE_INVOICE}
                model={record}
                onSaved={() => refreshData()}
                icon={
                  record?.dispute_status ? (
                    <Tag color="error">
                      {
                        STATUS_MAP[
                          (record.dispute_status as unknown) as keyof typeof STATUS_MAP
                        ]
                      }
                    </Tag>
                  ) : (
                    <></>
                  )
                }
                open={openId == record.id}
              />
            </>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'state',
        width: 100,
      },
      {
        title: 'Sales Confirm',
        dataIndex: 'confirm_at',
        width: 120,
        render: (text, record) => {
          return (
            <Switch
              checked={!!record.is_sales_confirmed}
              onChange={(checked) =>
                handleSaleConfirm(record.id, {
                  is_sales_confirmed: checked,
                })
              }
              disabled={!auth.hasRole(['accounting', 'controller'])}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          );
        },
      },
      {
        title: 'Customer Confirm',
        dataIndex: 'confirm_at',
        width: 170,
        render: (text, record) => {
          return (
            <CustomerConfirmColumn
              orderInvoiceId={record.id}
              confirmed_at={record.confirmed_at}
              cancel_customer_confirmed_reason={
                record.cancel_customer_confirmed_reason
              }
              onSaved={(id: number) => fetchData()}
            />
          );
        },
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
        key: 'amount_total',
        sorter: true,
        width: 120,
      },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
        key: 'amount_due',
        sorter: true,
        width: 120,
        render: (text, record) => {
          if (record.due_at && moment().diff(record.due_at, 'days') > 0) {
            return (
              <Tooltip title="overdue">
                <Typography.Text type="danger">
                  {record.amount_due}
                </Typography.Text>
              </Tooltip>
            );
          }
          return <Typography.Text>{record.amount_due}</Typography.Text>;
        },
      },
      {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
        key: 'amount_paid',
        sorter: true,
        width: 120,
      },
      {
        title: 'Invoiced At',
        dataIndex: 'invoiced_at',
        key: 'invoiced_at',
        sorter: true,
        width: 120,
      },
      {
        title: 'Due At',
        dataIndex: 'due_at',
        key: 'due_at',
        sorter: true,
        width: 100,
      },
      {
        title: 'Penalty At',
        dataIndex: 'penalty_at',
        key: 'penalty_at',
        sorter: true,
        width: 100,
      },
      {
        title: 'Sent At',
        dataIndex: 'sent_at',
        key: 'sent_at',
        sorter: true,
        width: 150,
      },
      {
        title: 'Paid At',
        dataIndex: 'paid_at',
        key: 'paid_at',
        sorter: true,
        width: 100,
      },
      {
        title: 'Deposited At',
        dataIndex: 'deposited_at',
        key: 'deposited_at',
        sorter: true,
        width: 120,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <EditInvoice
              id={record.id}
              invoiceableId={record.invoiceable_id}
              invoiceableType={record.invoiceable_type}
              onSaved={fetchData}
              onDeleted={fetchData}
            >
              <a>Edit</a>
            </EditInvoice>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
            <LogActivities
              id={record.id}
              icon={<a> Log</a>}
              type="orderInvoices"
            />
          </Space>
        ),
      },
    ],
    [sorter, setSorted, openId, pagination],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  const footer = React.useCallback(() => {
    let amount_total = 0,
      amount_due = 0,
      amount_paid = 0;

    selected.forEach((row) => {
      amount_due += parseFloat((row.amount_due as any) || 0);
      amount_total += parseFloat((row.amount_total as any) || 0);
      amount_paid += parseFloat((row.amount_paid as any) || 0);
    });
    return (
      selected &&
      selected.length > 0 && (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={11}>
              Total Selected {selected.length}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {amount_total.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {amount_due.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {amount_paid.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4} colSpan={10}></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )
    );
  }, [selected]);

  return (
    <div className={styles.main}>
      <Header
        title="Invoices"
        rightElement={
          <Space>
            <Button type="primary" onClick={handleExport} disabled={loading}>
              Export
            </Button>
            <ReceivePayment onSaved={fetchData} invoices={selected} />
            <Import onUploaded={fetchData} />
            <BulkUpdate ids={selected} onDone={fetchData} />
          </Space>
        }
      ></Header>

      <Filter filter={filter} onSearch={fetchData} loading={loading} />

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        rowSelection={{
          onChange: (_: React.Key[], selectedRows: TInvoice[]) => {
            setSelected(selectedRows);
          },
        }}
        scroll={{ x: '100%' }}
        summary={footer}
      />
    </div>
  );
};

export default Index;
