import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import React, { useMemo, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { CompanyForm } from './CompanyForm';
import { CACHE_COMPANY_KEY } from '@/stores/cache';

export type TCompanyDrawerProps = {
  id?: number | null;
  open: boolean;
  disabled?: boolean;
  onClose: () => void;
  onSaved?: (id: number) => void;
};

export const CompanyDrawer: React.FC<TCompanyDrawerProps> = ({
  id = 0,
  open,
  disabled = false,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const fetchData = async () => {
    if (!id) {
      return;
    }

    try {
      const resp = await app.service.get(`companies/${id}`);
      await form.setFieldsValue(resp.data);
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    values.vendor_ids = values.vendors ? values.vendors.map((v) => v.id) : [];
    delete values.vendors;

    if (values.tier_rate === undefined) {
      values.tier_rate = null;
    }

    setLoading(true);

    const request = !values.id
      ? app.service.post('companies', { data: values })
      : app.service.put(`companies/${values.id}`, { data: values });

    request
      .then((resp: any) => {
        message.success('Saved');
        onSaved && onSaved(resp.data);
        app.store.cache.fetch(CACHE_COMPANY_KEY, true);

        handleClose();
      })
      .catch((err: any) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const initForm = () => {
    form.resetFields();
    form.setFieldsValue({
      code: '',
      name: '',
      address: '',
      billing_address: '',
    });
  };

  useEffect(() => {
    if (open) {
      if (!id) {
        initForm();
      } else {
        fetchData();
      }
    }
  }, [open, id]);

  return (
    <>
      <Drawer
        title={`${id ? 'Edit' : 'Create'} Company`}
        placement="right"
        width="80%"
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <CompanyForm form={form} onSubmit={handleSave} />
      </Drawer>
    </>
  );
};
