import React from 'react';
import { Button, Modal, message, Space, Table, Tag } from 'antd';
import { useApp } from '@/utils/useapp';
import { TInvoice, TLocalStatement } from '@/types';
import { ColumnsType } from 'antd/lib/table';

interface IReloadModal {
  open: boolean;
  localStatement: TLocalStatement;
  onClose: () => void;
  onDone?: () => void;
}

export const ReloadModal: React.FC<IReloadModal> = ({
  open,
  localStatement,
  onClose,
  onDone,
}) => {
  const app = useApp();
  const [invoices, setInvoices] = React.useState<TInvoice[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<TInvoice[]>([]);

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(
        `accounting/localStatements/${localStatement.id}`,
      );

      setInvoices(resp.data.invoices);
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleGenerateNew = async () => {
    if (selected.length === 0) {
      message.error('Please select at least one invoice.');
      return;
    }

    setLoading(true);
    try {
      await app.service.post(
        `accounting/localStatements/${localStatement.id}/generateByInvoices`,
        {
          data: {
            invoice_ids: selected.map((i) => i.id),
          },
        },
      );
      message.success('Successfully generated new local statement.');
      onDone && onDone();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const toReturnDate = (order: any) => {
    if (!order) {
      return;
    }

    const containers = order.containers || [];

    if (containers.length === 0) {
      return;
    }
    // get the first return date
    const returnDate = containers
      .map((c) => c.actual_empty_returned_at)
      .filter((d) => d)
      .sort()[0];

    return returnDate;
  };

  const columns: ColumnsType<TInvoice> = React.useMemo(
    () => [
      {
        title: 'INV#',
        dataIndex: 'uid',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Amt. Conf',
        render: (record: TInvoice) =>
          record.amount_confirmed ? (
            <Tag color="processing">Yes</Tag>
          ) : (
            <Tag color="default">No</Tag>
          ),
      },

      {
        title: 'Sales Conf',
        render: (text: any, record: TInvoice) =>
          record.is_sales_confirmed ? (
            <Tag color="processing">Yes</Tag>
          ) : (
            <Tag color="default">No</Tag>
          ),
      },
      {
        title: 'Customer Conf',
        render: (record: TInvoice) =>
          record.confirmed_at ? (
            <Tag color="processing">Yes</Tag>
          ) : (
            <Tag color="default">No</Tag>
          ),
      },
      {
        title: 'Order#',
        dataIndex: ['order', 'uid'],
        sorter: (a, b) => a.order_id - b.order_id,
      },
      {
        title: 'Order Status',
        dataIndex: ['order', 'state'],
      },
      {
        title: 'Customer Ref',
        dataIndex: ['order', 'customer_reference_number'],
      },
      {
        title: 'ETA',
        dataIndex: ['order', 'port_of_discharge_eta'],
        // compare two date string
        sorter: (a, b) => {
          if (
            !a.order ||
            !b.order ||
            !a.order.port_of_discharge_eta ||
            !b.order.port_of_discharge_eta
          ) {
            return -1;
          }
          const dateA = new Date(a.order.port_of_discharge_eta).getTime();
          const dateB = new Date(b.order.port_of_discharge_eta).getTime();

          return dateA - dateB;
        },
      },
      {
        title: 'Return Date',
        render: (record) => {
          return toReturnDate(record.order);
        },
        sorter: (a, b) => {
          const d1 = toReturnDate(a.order);
          const d2 = toReturnDate(b.order);
          if (!d1 || !d2) {
            return -1;
          }

          const dateA = new Date(d1).getTime();
          const dateB = new Date(d2).getTime();

          return dateA - dateB;
        },
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
        sorter: (a, b) => a.amount_total - b.amount_total,
      },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
      },
      {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
      },
      {
        title: 'Order Profit',
        dataIndex: ['order', 'profit_amount_total'],
      },
      {
        title: 'Create Date',
        dataIndex: 'created_at',
        sorter: (a, b) => {
          if (!a.created_at || !b.created_at) {
            return -1;
          }
          return (
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
        },
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiced_at',
        sorter: (a, b) => {
          if (!a.invoiced_at || !b.invoiced_at) {
            return -1;
          }
          return (
            new Date(a.invoiced_at).getTime() -
            new Date(b.invoiced_at).getTime()
          );
        },
      },
      {
        title: 'Due Date',
        dataIndex: 'due_at',
        sorter: (a, b) => {
          if (!a.due_at || !b.due_at) {
            return -1;
          }
          return new Date(a.due_at).getTime() - new Date(b.due_at).getTime();
        },
      },
    ],
    [],
  );

  const footer = React.useCallback(() => {
    let amount_total = 0,
      amount_due = 0,
      amount_paid = 0;

    selected.forEach((row) => {
      amount_due += parseFloat((row.amount_due as any) || 0);
      amount_total += parseFloat((row.amount_total as any) || 0);
      amount_paid += parseFloat((row.amount_paid as any) || 0);
    });
    return (
      selected &&
      selected.length > 0 && (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={9}>
              Total Selected {selected.length}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {amount_total.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {amount_due.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {amount_paid.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5} colSpan={10}></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )
    );
  }, [selected]);

  React.useEffect(() => {
    localStatement.id && fetchInvoices();
  }, [localStatement]);

  return (
    <Modal
      title="Reload"
      width={'80%'}
      onCancel={onClose}
      destroyOnClose={true}
      footer={
        <Space>
          <Button loading={loading} color="primary" onClick={handleGenerateNew}>
            Generate New Local Statement
          </Button>
          <Button onClick={onClose}>Close</Button>
        </Space>
      }
      open={open}>
      <Table
        className="mt-md"
        size="small"
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={invoices}
        pagination={false}
        scroll={{ y: 500, x: '100%' }}
        rowSelection={{
          onChange: (_: React.Key[], selectedRows: TInvoice[]) => {
            setSelected(selectedRows);
          },
        }}
        summary={footer}
      />
    </Modal>
  );
};
