import { useApp } from '@/utils/useapp';
import { Space, Button, message, Drawer, Divider, Popconfirm, Tag } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import styles from 'res/css/ui.scss';
import QuoteForm from '../components/QuoteForm';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import AutoResizeTable from '@/components/AutoResizeTable';
import { HiRefresh } from 'react-icons/hi';
import { QuoteBusinessStatus } from '../constants';

export interface Quote {
  id: number;
  uid: string;
  pickup_date: string;
  pickup_zipcode: string;
  destination_zipcode: string;
  quote_at: string;
  status: string;
  margin_percent: number;
  business_status: QuoteBusinessStatus;
}

const Index: React.FC = () => {
  const app = useApp();
  const [data, setData] = useState<TCollection<Quote>>();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [model, setModel] = useState<Quote>();
  const pagination = usePagination(data);

  const fetchData = async (
    pagination?: TablePaginationConfig,
    sorter?: SorterResult<Quote> | SorterResult<Quote>[],
  ) => {
    setLoading(true);
    try {
      const resp = await app.service.get('tl/ltl/quotes', {
        params: {
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
          sort_by: Array.isArray(sorter)
            ? undefined
            : sorter?.columnKey || sorter?.field,
          sort_value: Array.isArray(sorter)
            ? undefined
            : sorter?.order === 'ascend'
            ? 'asc'
            : sorter?.order === 'descend'
            ? 'desc'
            : undefined,
        },
      });
      setData(resp);
      setLoading(false);
    } catch (err: any) {
      message.error(err?.data?.message ?? 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Quote> | SorterResult<Quote>[],
  ) => {
    fetchData(pagination, sorter);
  };

  const handleNewLTLQuote = () => {
    setModel(undefined);
    setIsCopy(false);
    setDrawerVisible(true);
  };

  const handleEdit = (m: any) => {
    setModel(m);
    setIsCopy(false);
    setDrawerVisible(true);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await app.service.delete(`tl/ltl/quotes/${id}`);
      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const handleDuplicate = (record: any) => {
    setModel(record);
    setIsCopy(true);
    setDrawerVisible(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<Quote> = [
    {
      title: 'Quote#',
      dataIndex: 'uid',
      key: 'id',
      sorter: true,
    },
    {
      title: 'User',
      dataIndex: ['user', 'company', 'name'],
    },
    {
      title: 'Origin Zip',
      dataIndex: 'pickup_zipcode',
    },
    {
      title: 'Destination Zip',
      dataIndex: 'destination_zipcode',
    },
    {
      title: 'Pickup Date',
      dataIndex: 'pickup_date',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      sorter: true,
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Status',
      dataIndex: 'bussiness_status',
      render: (text, record) => {
        if (record.business_status === QuoteBusinessStatus.DRAFT) {
          return <Tag color="default">DRAFT</Tag>;
        } else if (record.business_status === QuoteBusinessStatus.SUBMITED) {
          return <Tag color="yellow">SUBMITED</Tag>;
        } else if (record.business_status === QuoteBusinessStatus.CONFIRMED) {
          return <Tag color="blue">CONFIRMED</Tag>;
        } else if (record.business_status === QuoteBusinessStatus.BOOKED) {
          return <Tag color="green">BOOKED</Tag>;
        } else if (record.business_status === QuoteBusinessStatus.EXPIRED) {
          return <Tag color="gray">EXPIRED</Tag>;
        } else {
          return <Tag color="gray">UNKNOW</Tag>;
        }
      },
    },
    {
      title: 'API Stage',
      dataIndex: 'status',
      render: (text, record) => {
        const [done, total] = text.split('/');
        if (done === total && total === '0') {
          return <Tag color="default">NOTSTARTED</Tag>;
        } else if (done === total) {
          return <Tag color="green">COMPLETED</Tag>;
        } else {
          return <Tag color="blue">PROGRESSING</Tag>;
        }
      },
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <Space>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <a>Delete</a>
          </Popconfirm>

          <a onClick={() => handleDuplicate(record)}>Duplicate</a>
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="LTL Quote"
        rightElement={
          <Space align="end" direction="horizontal">
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => fetchData()}
              shape="circle"
            >
              <HiRefresh size={18} color="#1890ff" />
            </Button>
            <Button type="primary" onClick={handleNewLTLQuote}>
              New LTL Quote
            </Button>
          </Space>
        }
      />
      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={handleTableChange}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
      <Drawer
        title={model?.uid && !isCopy ? `Edit Quote#${model?.uid}` : 'New Quote'}
        placement="right"
        width="90%"
        onClose={() => {
          fetchData();
          setDrawerVisible(false);
        }}
        destroyOnClose={true}
        open={drawerVisible}
        extra={
          <Button
            onClick={() => {
              fetchData();
              setDrawerVisible(false);
            }}
          >
            Close
          </Button>
        }
      >
        <QuoteForm
          model={model}
          isCopy={isCopy}
          onCreated={() => {
            fetchData();
          }}
          onClose={() => {
            fetchData();
            setDrawerVisible(false);
          }}
        />
      </Drawer>
    </div>
  );
};

export default Index;
