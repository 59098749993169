import React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
} from 'antd';
import moment from 'moment';
import { useApp } from '@/utils/useapp';
import { TInvoice, TLocalStatement } from '@/types';
import { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { UserSelect } from './UserSelect';
import { get } from 'lodash';
import AutoResizeTable from '@/components/AutoResizeTable';
import {
  INVOICE_TYPE_LTL,
  INVOICE_TYPE_ORDER,
} from '../../invoices/components/data';

interface Props {
  model?: TLocalStatement;
  onCreated: () => void;
}

export const DrawerForm: React.FC<Props> = ({ model, onCreated }) => {
  const app = useApp();
  const [invoices, setInvoices] = React.useState<TInvoice[]>([]);
  const [selectBillToName, setSelectBillToName] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<TInvoice[]>([]);
  const [isAttachedInvoices, setIsAttachedInvoices] = React.useState(false);

  const [form] = useForm();

  const id = model?.id;

  const searchInvoices = async () => {
    const values = await form.validateFields();

    setLoading(true);
    try {
      const resp = await app.service.get(
        'accounting/localStatements/invoices',
        {
          params: values,
        },
      );

      setInvoices(resp.data);
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`accounting/localStatements/${id}`);

      setInvoices(resp.data.invoices);
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const createLocalStatement = async () => {
    if (invoices.length === 0 || selected.length === 0) {
      return message.error('Please select at least one invoice.');
    }

    const values = await form.validateFields();

    setLoading(true);

    try {
      const resp = await app.service.post(
        'accounting/localStatements/generate',
        {
          data: {
            attach_original_invoices: isAttachedInvoices,
            ...values,
            period:
              values.period_type === 'as_of'
                ? `As of ${values.as_of_at}`
                : `${values.period_start_at} - ${values.period_end_at}`,
            filter: values,
            invoice_ids: selected.map((s) => s.id),
          },
          responseType: 'blob',
        },
      );

      const fileURL = URL.createObjectURL(resp);
      window.open(fileURL, '_blank');

      onCreated && onCreated();
    } catch (e: any) {
      const d = await e.response.json();
      message.error(d.message || d.error);
    }

    setLoading(false);
  };

  const toReturnDate = (order: any) => {
    if (!order) {
      return;
    }

    const containers = order.containers || [];

    if (containers.length === 0) {
      return;
    }
    // get the first return date
    const returnDate = containers
      .map((c) => c.actual_empty_returned_at)
      .filter((d) => d)
      .sort()[0];

    return returnDate;
  };

  const columns: ColumnsType<TInvoice> = React.useMemo(
    () => [
      {
        title: 'INV#',
        dataIndex: 'uid',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Amt. Conf',
        render: (record: TInvoice) =>
          record.amount_confirmed ? (
            <Tag color="processing">Yes</Tag>
          ) : (
            <Tag color="default">No</Tag>
          ),
      },
      {
        title: 'Sales Conf',
        dataIndex: 'is_sales_confirmed',
        sorter: (a: any, b: any) => a.is_sales_confirmed - b.is_sales_confirmed,
        render: (text: any, record: TInvoice) => {
          if (get(record, 'is_sales_confirmed', false)) {
            return <Tag color="processing">Yes</Tag>;
          }
          return <Tag color="default">No</Tag>;
        },
      },
      {
        title: 'Customer Conf',
        render: (record: TInvoice) =>
          record.confirmed_at ? (
            <Tag color="processing">Yes</Tag>
          ) : (
            <Tag color="default">No</Tag>
          ),
      },
      {
        title: 'Order#/Shipment#',
        dataIndex: ['invoiceable', 'uid'],
        render: (text: any, record: TInvoice) => {
          return (
            <Space>
              <span>{record.invoiceable?.uid}</span>
            </Space>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: ['invoiceable', 'state'],
        render: (text: any, record: TInvoice) => {
          return (
            <Space>
              <span>
                {record.invoiceable_type == INVOICE_TYPE_ORDER
                  ? record.invoiceable?.state || ''
                  : ''}
              </span>
              <span>
                {record.invoiceable_type == INVOICE_TYPE_LTL
                  ? record.invoiceable?.bussiness_status_name || ''
                  : ''}
              </span>
            </Space>
          );
        },
      },
      {
        title: 'Customer Ref',
        dataIndex: ['invoiceable', 'customer_reference_number'],
      },
      {
        title: 'ETA',
        dataIndex: ['invoiceable', 'port_of_discharge_eta'],
        sorter: (a, b) => {
          if (
            !a.invoiceable ||
            !b.invoiceable ||
            !a.invoiceable.port_of_discharge_eta ||
            !b.invoiceable.port_of_discharge_eta
          ) {
            return -1;
          }
          const dateA = new Date(a.invoiceable.port_of_discharge_eta).getTime();
          const dateB = new Date(b.invoiceable.port_of_discharge_eta).getTime();

          return dateA - dateB;
        },
      },
      {
        title: 'Return Date',
        render: (record) => {
          return toReturnDate(record.invoiceable);
        },
        sorter: (a, b) => {
          const d1 = toReturnDate(a.invoiceable);
          const d2 = toReturnDate(b.invoiceable);
          if (!d1 || !d2) {
            return -1;
          }

          const dateA = new Date(d1).getTime();
          const dateB = new Date(d2).getTime();

          return dateA - dateB;
        },
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
        sorter: (a, b) => a.amount_total - b.amount_total,
      },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
      },
      {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
      },
      {
        title: 'Order Profit',
        dataIndex: ['order', 'profit_amount_total'],
      },
      {
        title: 'Sent At',
        dataIndex: 'sent_at',
        key: 'sent_at',
        width: 100,
      },
      {
        title: 'Create Date',
        dataIndex: 'created_at',
        sorter: (a, b) => {
          if (!a.created_at || !b.created_at) {
            return -1;
          }
          return (
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
        },
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiced_at',
        sorter: (a, b) => {
          if (!a.invoiced_at || !b.invoiced_at) {
            return -1;
          }
          return (
            new Date(a.invoiced_at).getTime() -
            new Date(b.invoiced_at).getTime()
          );
        },
      },
      {
        title: 'Due Date',
        dataIndex: 'due_at',
        sorter: (a, b) => {
          if (!a.due_at || !b.due_at) {
            return -1;
          }
          return new Date(a.due_at).getTime() - new Date(b.due_at).getTime();
        },
      },
    ],
    [],
  );

  const footer = React.useCallback(() => {
    let amount_total = 0,
      amount_due = 0,
      amount_paid = 0;

    selected.forEach((row) => {
      amount_due += parseFloat((row.amount_due as any) || 0);
      amount_total += parseFloat((row.amount_total as any) || 0);
      amount_paid += parseFloat((row.amount_paid as any) || 0);
    });
    return (
      selected &&
      selected.length > 0 && (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={9}>
              Total Selected {selected.length}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {amount_total.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {amount_due.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {amount_paid.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5} colSpan={10}></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )
    );
  }, [selected]);

  React.useEffect(() => {
    id && fetchInvoices();
  }, [id]);

  React.useEffect(() => {
    form.resetFields();
    if (id) {
      form.setFieldsValue(model.filter || {});
      fetchInvoices();
    }
  }, [id]);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          field: 'invoiced_at',
          period_type: 'as_of',
          unpaid: '1',
          is_sales_confirmed: '1',
          as_of_at: moment().format('YYYY-MM-DD'),
        }}
      >
        <Form.Item name="id" noStyle>
          <Input hidden />
        </Form.Item>
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item label="Bill TO" shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="bill_to_id"
                  noStyle
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <CompanySyncSelect
                    style={{ width: '100%' }}
                    selected={getFieldValue('bill_to_id')}
                    onSelect={(billTo: any) => {
                      setSelectBillToName(billTo?.name || null);
                      setFieldsValue({
                        bill_to_id: billTo?.id || 0,
                        user_ids: billTo?.id ? [] : getFieldValue('user_ids'),
                      });
                    }}
                    editable={false}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Customer" required shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => {
                return (
                  <Form.Item
                    name="user_ids"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: 'Customer is required.',
                      },
                    ]}
                  >
                    <UserSelect
                      selected={getFieldValue('user_ids')}
                      billTo={{
                        id: getFieldValue('bill_to_id'),
                        name: selectBillToName,
                      }}
                      onSelect={(userIds: Array<number> | null) =>
                        form.setFieldsValue({ user_ids: userIds })
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item label="Date Type" name="field" required>
              <Select>
                <Select.Option value="created_at">Create Date</Select.Option>
                <Select.Option value="invoiced_at">Inovice Date</Select.Option>
                <Select.Option value="due_at">Due Date</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item label="Period Type" name="period_type" required>
              <Select>
                <Select.Option value="as_of">As of</Select.Option>
                <Select.Option value="period">Period</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  {getFieldValue('period_type') === 'as_of' && (
                    <Form.Item
                      label="Date"
                      name="as_of_at"
                      rules={[{ required: true, message: 'Date is required' }]}
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.as_of_at !== curValues.as_of_at
                        }
                      >
                        {({ getFieldValue, setFieldsValue }) => {
                          const value = getFieldValue('as_of_at');
                          return (
                            <DatePicker
                              style={{ width: '100%' }}
                              value={value ? moment(value) : null}
                              onChange={(v) =>
                                setFieldsValue({
                                  as_of_at: v?.format('YYYY-MM-DD'),
                                })
                              }
                            />
                          );
                        }}
                      </Form.Item>
                    </Form.Item>
                  )}
                  {getFieldValue('period_type') === 'period' && (
                    <>
                      <Form.Item name="period_start_at" noStyle>
                        <Input hidden />
                      </Form.Item>
                      <Form.Item name="period_end_at" noStyle>
                        <Input hidden />
                      </Form.Item>
                      <Form.Item label="Date" shouldUpdate>
                        {({ getFieldValue, setFieldsValue }) => {
                          const startDate = getFieldValue('period_start_at');
                          const endDate = getFieldValue('period_end_at');
                          return (
                            <DatePicker.RangePicker
                              value={
                                startDate && endDate
                                  ? [moment(startDate), moment(endDate)]
                                  : undefined
                              }
                              onChange={(value) =>
                                setFieldsValue({
                                  period_start_at: value
                                    ? value[0]?.format('YYYY-MM-DD')
                                    : null,
                                  period_end_at: value
                                    ? value[1]?.format('YYYY-MM-DD')
                                    : null,
                                })
                              }
                            />
                          );
                        }}
                      </Form.Item>
                    </>
                  )}
                </>
              )}
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item label="Unpaid" name="unpaid">
              <Select>
                <Select.Option value="1">Yes</Select.Option>
                <Select.Option value="0">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item label="Sales Confirm" name="is_sales_confirmed">
              <Select allowClear>
                <Select.Option value="1">Yes</Select.Option>
                <Select.Option value="0">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item label="Customer Confirm" name="confirmed_at">
              <Select allowClear>
                <Select.Option value="1">Yes</Select.Option>
                <Select.Option value="0">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item label="Amt Confirmed" name="amount_confirmed">
              <Select allowClear>
                <Select.Option value="1">Yes</Select.Option>
                <Select.Option value="0">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            {!id && (
              <Form.Item label={' '}>
                <Button type="primary" onClick={searchInvoices}>
                  Search Invoices
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>

      <AutoResizeTable
        className="mt-md"
        size="small"
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={invoices}
        pagination={false}
        scroll={{ y: 400, x: '100%' }}
        rowSelection={{
          onChange: (_: any, selectedRows: TInvoice[]) =>
            setSelected(selectedRows),
        }}
        summary={footer}
      />

      {!id && (
        <div className="text-right mt-md">
          <Space>
            <Switch
              checkedChildren="Attach original invoices"
              unCheckedChildren="Do not attach original invoices"
              checked={isAttachedInvoices}
              onChange={setIsAttachedInvoices}
            />
            <Button
              type="primary"
              disabled={invoices.length === 0 || selected.length === 0}
              onClick={createLocalStatement}
              loading={loading}
            >
              Create Local Statement
            </Button>
          </Space>
        </div>
      )}
    </>
  );
};
