import { useApp } from '@/utils/useapp';
import {
  Drawer,
  Space,
  Button,
  Row,
  Col,
  Popconfirm,
  Divider,
  message,
  Dropdown,
  Tag,
  Switch,
} from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import React, { FC, useMemo, useState } from 'react';
import InvoiceForm from './InvoiceForm';
import { BillsTab } from './BillsTab';
import _, { get } from 'lodash';
import { SellRateFormCard } from './SellRateFormCard';
import { DispatchTableDrawer } from '@/pages/dispatchs/components/Table/DispatchTableDrawer';
import {
  TERMINAL_FEES,
  STATE_ESTIMATE,
  STATUS_MAP,
  INVOICE_TYPE_ORDER,
  INVOICE_TYPE_LTL,
  INVOICE_TYPE_FTL,
} from '../data';
import { SalesConfirmButton } from './SalesConfirmButton';
import { CustomerConfirm } from './CustomerConfirm';
import { InvoiceConfirmButton } from './InvoiceConfirmButton';
import moment from 'moment';
import { DISPUTE_TYPE_INVOICE, DisputeMemo } from '@/components/DisputeMemo';
import { TInvoice } from '@/types';
import SendInvoiceDropdown from '@/components/SendInvoiceDropdown';
import * as LTLTable from '@/pages/truckload/ltl/shipments/ShipmentTable/ShipmentTableInstance';
import * as FTLTable from '@/pages/truckload/ftl/shipments/ShipmentTable/ShipmentTableInstance';

interface InvoiceDrawerProp {
  id: number;
  invoiceableId?: number;
  invoiceableType?: string;
  containerNumber?: string;
  visible: boolean;
  onClose: () => void;
  onSaved?: () => void;
  onDeleted?: () => void;
}

const OrderInvoiceForm: FC<{
  form: any;
  invoiceableId: number | undefined;
  containers: any[];
  containerNumbers: any[];
  fetchContainers: () => void;
  invoice: TInvoice | undefined;
  setBills: (bills: any) => void;

  onSaved?: () => void;
  handleDateRelatedChange: (field: string, value: any) => void;
  onChangingContainerNumbers: (numbers: any) => void;
  hasTerminalFees: boolean;
  bills: any[];
  containerNumber: string | undefined;
}> = ({
  form,
  invoiceableId,
  containers,
  containerNumbers,
  fetchContainers,
  invoice,
  setBills,
  onSaved,
  handleDateRelatedChange,
  onChangingContainerNumbers,
  hasTerminalFees,
  bills,
  containerNumber,
}) => {
  return (
    <>
      <div className="mb-md">
        <DispatchTableDrawer
          orderId={invoiceableId || form.getFieldValue('invoiceable_id')}
          scroll={{
            x: 500,
            y: 150,
          }}
          containers={containers}
          containerNumbers={containerNumbers}
          refreshContainers={fetchContainers}
        />
      </div>
      <div
        style={{
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={11}>
            <div>
              <BillsTab
                invoice={invoice}
                containerNumber={containerNumber}
                invoiceableId={
                  invoiceableId || form.getFieldValue('invoiceable_id')
                }
                setBills={(bills: any) => setBills(bills)}
              />
            </div>
            <Divider />
            <div>
              <SellRateFormCard
                containers={containers}
                containerNumber={containerNumber}
                orderId={invoiceableId || form.getFieldValue('invoiceable_id')}
              />
            </div>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>

          <Col className="gutter-row" span={12}>
            <InvoiceForm
              form={form}
              bills={bills}
              containers={containers}
              containerNumber={containerNumber}
              hasTerminalFees={hasTerminalFees}
              handleDateRelatedChange={handleDateRelatedChange}
              onSaved={onSaved}
              onChangingContainerNumbers={onChangingContainerNumbers}
              invoiceType={INVOICE_TYPE_ORDER}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const TruckLoadInvoiceForm: FC<{
  form: any;
  onSaved?: () => void;
  handleDateRelatedChange: (field: string, value: any) => void;
  onChangingContainerNumbers: (numbers: any) => void;
  hasTerminalFees: boolean;
  bills: any[];
  invoiceType: string;
}> = ({
  form,
  onSaved,
  handleDateRelatedChange,
  onChangingContainerNumbers,
  hasTerminalFees,
  bills,
  children,
  invoiceType,
}) => {
  return (
    <>
      {children}
      <Divider />
      <InvoiceForm
        form={form}
        bills={bills}
        hasTerminalFees={hasTerminalFees}
        handleDateRelatedChange={handleDateRelatedChange}
        onSaved={onSaved}
        onChangingContainerNumbers={onChangingContainerNumbers}
        invoiceType={invoiceType}
      />
    </>
  );
};

export const InvoiceDrawer: React.FC<InvoiceDrawerProp> = ({
  id,
  invoiceableType,
  invoiceableId,
  containerNumber,
  visible,
  onClose,
  onSaved,
  onDeleted,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [invoice, setInvoice] = React.useState<TInvoice>();
  const [bills, setBills] = React.useState([]);
  // const [order, setOrder] = useState();

  const [containerNumbers, setContainersNumbers] = React.useState<
    Array<string>
  >(containerNumber ? [containerNumber] : []);

  const [form] = useForm();

  const isEstimate = useWatch('state', form) == STATE_ESTIMATE;

  const [containers, setContainers] = React.useState<Array<any>>([]);

  const InvoiceType = useMemo(() => {
    return invoiceableType || invoice?.invoiceable_type;
  }, [invoice, invoiceableType]);

  const hasTerminalFees = useMemo(() => {
    if (
      bills.find((b: any) =>
        b.charges.find((c: any) => TERMINAL_FEES.includes(c.code)),
      )
    ) {
      return true;
    }

    return false;
  }, [bills]);

  const fetchOrder = async () => {
    const LINK_MAP = {
      [INVOICE_TYPE_FTL]: 'ftl/shipments',
      [INVOICE_TYPE_LTL]: 'tl/ltl/shipments',
      [INVOICE_TYPE_ORDER]: 'orders',
    };

    // if (InvoiceType != INVOICE_TYPE_ORDER) {
    //   return;
    // }
    const oid = invoiceableId || form.getFieldValue('invoiceable_id');
    if (!oid) {
      return;
    }

    setLoading(true);
    try {
      const _order = await app.service.get(`${LINK_MAP[InvoiceType]}/${oid}`);
      // setOrder(_order.data);

      if (!form.getFieldValue('user_id')) {
        form.setFieldValue('user_id', _order.data.user_id);
      }

      if (!form.getFieldValue('billing_to')) {
        form.setFieldValue(
          'billing_to',
          _order.data.user?.bill_to?.billing_address,
        );
      }

      if (!form.getFieldValue('terms')) {
        form.setFieldValue('terms', _order.data.user?.company?.terms);
      }
    } catch (err: any) {
      message.error(err?.data?.error ?? 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchContainers = async () => {
    if (InvoiceType != INVOICE_TYPE_ORDER) {
      return;
    }

    const oid = invoiceableId || form.getFieldValue('invoiceable_id');
    if (!oid) {
      return;
    }

    const resp = await app.service.get(`orders/${oid}/containers`);

    setContainers(resp.data);
  };

  const fetchData = async () => {
    if (!id) {
      return;
    }

    setLoading(true);

    try {
      const resp = await app.service.get(`orderInvoices/${id}`);
      setInvoice(resp.data);
      setContainersNumbers(
        get(resp.data, 'charges', [])?.map((c) => c?.containerNumber),
      );
      form.setFieldsValue({
        ...resp.data,
        terms: resp.data?.terms ? resp.data.terms : 0,
      });
      // fetchContainers();
      fetchOrder();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleSaved = () => {
    fetchData();
    onSaved && onSaved();
  };

  const handleSave = async () => {
    const values = await form.validateFields();

    setLoading(true);

    try {
      const resp = !values.id
        ? await app.service.post('orderInvoices', {
            data: {
              ...values,
              invoiceable_id: invoiceableId,
              invoiceable_type: InvoiceType,
            },
          })
        : await app.service.put(`orderInvoices/${values.id}`, { data: values });

      form.setFieldsValue(resp.data);

      message.success('Saved');

      handleSaved();
      // onSaved && onSaved();
    } catch (e: any) {
      message.error(
        get(e, 'data.message') || get(e, 'message') || 'System Error',
      );
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    if (!id) {
      return false;
    }
    setLoading(true);

    try {
      await app.service.delete(`orderInvoices/${id}`);

      message.success('Deleted');

      onDeleted && onDeleted();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const showAsPDF = async () => {
    try {
      const resp = await app.service.get(`orderInvoices/${id}/pdf`, {
        responseType: 'blob',
      });
      const file = new Blob([resp], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const w = window.open(fileURL, '_blank');
      if (!w) {
        throw new Error('Please allow popups for this website');
      }
      w.document.title = 'IV#' + id;
    } catch (err: any) {
      message.error(err.data.message);
    }
  };
  const downloadPDF = async () => {
    try {
      await app.service.download(`orderInvoices/${id}/pdf`, {
        method: 'get',
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  // const handleEmailSent = () => {
  //   setShowEmailForm(false);
  // };

  // const handleOnCloseEmail = () => {
  //   setShowEmailForm(false);
  // };

  const onChangingContainerNumbers = (numbers: any) => {
    setContainersNumbers(numbers);
  };

  const handleDateRelatedChange = (field: string, value: any) => {
    let invoicedAtValue = form.getFieldValue('invoiced_at');
    let termsValue = form.getFieldValue('terms');

    if (field === 'terms') {
      termsValue = value;
    }
    if (field === 'invoiced_at') {
      invoicedAtValue = value;
    }

    let values = {
      [field]: value || null,
    };

    // if termsValue is /^\d+\+\d$/
    if (termsValue && /^\d+\+\d+$/.test(termsValue)) {
      const [first, second] = termsValue.split('+');
      values = {
        ...values,
        due_at: invoicedAtValue || null,
        penalty_at: invoicedAtValue
          ? moment(invoicedAtValue)
              .add(Number(second) >= Number(first) ? 0 : 1, 'months')
              .format(`YYYY-MM-${second}`)
          : null,
      };
    } else {
      values = {
        ...values,
        due_at: invoicedAtValue
          ? moment(invoicedAtValue)
              .add(termsValue || 0, 'days')
              .format('YYYY-MM-DD')
          : null,
        penalty_at: invoicedAtValue
          ? moment(invoicedAtValue)
              .add(termsValue || 0, 'days')
              .format('YYYY-MM-DD')
          : null,
      };
    }

    form.setFieldsValue(values);
  };

  React.useEffect(() => {
    if (id > 0) {
      fetchData();
    } else {
      fetchContainers();
      fetchOrder();
      form.setFieldValue(
        'note',
        app.store.auth.user?.company?.invoice_note || '',
      );
    }
  }, []);

  return (
    <>
      {visible && (
        <Drawer
          title={
            <>
              {invoice?.uid}{' '}
              {invoice?.dispute_status ? (
                <Tag color="error">
                  {
                    STATUS_MAP[
                      (invoice.dispute_status as unknown) as keyof typeof STATUS_MAP
                    ]
                  }
                </Tag>
              ) : (
                ''
              )}
              {!!invoice?.sent_at && <Tag color="processing">Invoice Sent</Tag>}
              {!!invoice?.is_sales_confirmed && (
                <Tag color="processing">Sales Confirm</Tag>
              )}
              {!!invoice?.confirmed_at && (
                <Tag color="processing">Customer Confirm</Tag>
              )}
            </>
          }
          placement="right"
          width={'95%'}
          zIndex={1000}
          onClose={onClose}
          destroyOnClose={true}
          open={visible}
          extra={
            <Space>
              <Button onClick={onClose}>Close</Button>
              <Button type="primary" loading={loading} onClick={handleSave}>
                Save
              </Button>
              {!!id && (
                <>
                  <Popconfirm
                    placement="left"
                    title="Sure to delete?"
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={handleDelete}
                  >
                    <Button loading={loading}>Delete</Button>
                  </Popconfirm>

                  <InvoiceConfirmButton
                    form={form}
                    state={form.getFieldValue('state')}
                    orderInvoiceId={id}
                    handleDateRelatedChange={handleDateRelatedChange}
                    onSetLoading={(loading: boolean) => {
                      setLoading(loading);
                    }}
                    onSaved={handleSaved}
                  />

                  <DisputeMemo
                    onSaved={handleSaved}
                    model={invoice}
                    icon={<Button type="primary">Dispute Memo</Button>}
                    type={DISPUTE_TYPE_INVOICE}
                  />

                  {!isEstimate && (
                    <>
                      <SalesConfirmButton
                        onSetLoading={(loading: boolean) => {
                          setLoading(loading);
                        }}
                        is_sales_confirmed={form.getFieldValue(
                          'is_sales_confirmed',
                        )}
                        orderInvoiceId={id}
                        onSaved={handleSaved}
                      />

                      <CustomerConfirm
                        onSetLoading={(loading: boolean) => setLoading(loading)}
                        cancel_customer_confirmed_reason={form.getFieldValue(
                          'cancel_customer_confirmed_reason',
                        )}
                        confirmed_at={form.getFieldValue('confirmed_at')}
                        orderInvoiceId={id}
                        onSaved={handleSaved}
                      />

                      <SendInvoiceDropdown
                        invoiceableId={invoiceableId}
                        invoiceableType={invoiceableType}
                        invoices={[invoice]}
                        onSaved={fetchData}
                      />
                    </>
                  )}

                  <Button loading={loading} onClick={downloadPDF}>
                    Download PDF
                  </Button>
                  <Button loading={loading} onClick={showAsPDF}>
                    View PDF
                  </Button>
                </>
              )}
            </Space>
          }
        >
          {InvoiceType == INVOICE_TYPE_ORDER && (
            <OrderInvoiceForm
              form={form}
              invoiceableId={invoiceableId || invoice?.invoiceable_id}
              containers={containers}
              containerNumbers={containerNumbers}
              fetchContainers={fetchContainers}
              invoice={invoice}
              setBills={setBills}
              onSaved={onSaved}
              handleDateRelatedChange={handleDateRelatedChange}
              onChangingContainerNumbers={onChangingContainerNumbers}
              hasTerminalFees={hasTerminalFees}
              bills={bills}
              containerNumber={containerNumber}
            />
          )}

          {InvoiceType != INVOICE_TYPE_ORDER && (
            <TruckLoadInvoiceForm
              form={form}
              onSaved={onSaved}
              handleDateRelatedChange={handleDateRelatedChange}
              onChangingContainerNumbers={onChangingContainerNumbers}
              hasTerminalFees={hasTerminalFees}
              bills={bills}
              invoiceType={InvoiceType}
            >
              {InvoiceType == INVOICE_TYPE_LTL && (
                <LTLTable.default
                  ids={[invoiceableId || form.getFieldValue('invoiceable_id')]}
                />
              )}
              {InvoiceType == INVOICE_TYPE_FTL && (
                <FTLTable.default
                  ids={[invoiceableId || form.getFieldValue('invoiceable_id')]}
                />
              )}
            </TruckLoadInvoiceForm>
          )}
        </Drawer>
      )}
    </>
  );
};
