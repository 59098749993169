import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { get, uniq } from 'lodash';
import actionUtil, {
  TLtlShipmentAction,
  TSendEmailModal,
  DOC_TARGET_MAP,
} from '../../Interfaces/ActionInterface';
import useAction from '../useAction';

const SendBOLEmailModal: React.FC<TSendEmailModal & TLtlShipmentAction> = ({
  task,
  admin,
  targetModel,
  target,
  open,
  onDone,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();
  const [docs, setDocs] = useState<any[]>([]);
  const { sendEmail } = useAction({ target, targetModel });

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(
          {
            ...data,
            task_id: task.id,
            action: actionUtil.SEND_BOL,
          },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await sendEmail(config, setLoading, onDone);

      emailForm.resetFields();
    } catch (err: any) {
      err.data && message.error(err.data.message || err.data.error);
    }
  };

  const fetchDocuments = async () => {
    if (!targetModel?.id || !target || !DOC_TARGET_MAP[target]) {
      return;
    }

    const docTarget = DOC_TARGET_MAP[target];

    try {
      const docs = await app.service.get(
        `documents/${docTarget}/${targetModel.id}`,
      );

      const resData = [...docs.data];

      setDocs(
        resData.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const initForm = () => {
    const sendToList = Object.assign(
      [],
      [
        get(targetModel, 'user.email'),
        ...get(targetModel, 'user.additional_notification_emails', []),
      ],
    );

    const customerName = get(targetModel, 'user.name', '');
    const salesEmail = get(targetModel, 'sales.email', '');
    const userSalesEmail = get(targetModel, 'user.sales.email', '');
    const userSSEmail = get(targetModel, 'user.sales_support.email', '');
    const uid = get(targetModel, 'uid', '');
    const customerRef = get(targetModel, 'customer_reference_number', '');

    let subject = `BOL Document for ${uid}`;
    customerRef && (subject += `-${customerRef}`);

    let bodyShipmentId = get(targetModel, 'uid', '');
    customerRef && (bodyShipmentId += `-${customerRef}`);

    emailForm.setFieldsValue({
      sendToList: sendToList?.length > 0 ? sendToList : [''],
      ccList: uniq([
        'tl@drayeasy.com',
        admin?.email,
        salesEmail,
        userSalesEmail,
        userSSEmail,
      ]).filter((i) => i),
      subject,
      body: `
Dear ${customerName},

Please find the attached BOL for shipment ${bodyShipmentId} at your earliest convenience and ensure that the warehouse is informed and prepared. Kindly review the attachment to confirm its accuracy. If you have any questions or require further assistance, feel free to reach out to us.

Warehouse Release Reminder
To avoid delays and unnecessary costs, please ensure that your warehouse is prepared to handle and release the goods promptly upon arrival. Should there be any issues causing a pick up attempt fee due to failure in releasing the goods on time, you will be responsible for those costs.

Thank you for your cooperation and understanding!`,
    });
  };

  useEffect(() => {
    open && initForm();
  }, [open, targetModel]);

  React.useEffect(() => {
    open && target && targetModel?.id && fetchDocuments();
  }, [open, targetModel, target]);

  return (
    <>
      <Modal
        title="LTL - Send BOL to customer"
        width={'75%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={emailForm} layout="vertical">
              <EmailForm
                form={emailForm}
                docs={docs}
                has={{
                  from: false,
                  attachment: true,
                  showSelectAttachments: true,
                }}
              />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default SendBOLEmailModal;
