export const TITLES = [
  'Solution Expert',
  'Solution Specialty',
  'OP',
  'OA',
  'OTR',
  'CPS',
  'Engineer',
  'Tracking',
  'Trainee',
  'Accounting',
  'Controller',
];
