import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect } from 'react';
import { CitySelect } from '@/components/CitySelect';
import { ContactList } from '@/components/ContactList';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';

export const TYPES = [
  'Ocean Ports',
  'Equipment Depots',
  'Railroad Terminals',
  'Customs Container Station',
  'Bonded Warehouse',
  'Inspection Facility',
  'Customs Admin Site',
  'Exam Site',
];

export const FACILITY_TYPES = [
  'BNSF Rail',
  'CN Rail',
  'CSX Rail',
  'NS Rail',
  'UP Rail',
  'Ocean Port',
  'Other',
];

export const DrawerForm: React.FC<any> = ({
  form,
  isNew = false,
  updateData,
}) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <div>
      <Form form={form}>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Firms"
              name="firms"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Code" />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: 'type',
                      value: value,
                    },
                  })
                }>
                {TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Facility Type"
              name="facility_type"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: 'facility_type',
                      value: value,
                    },
                  })
                }>
                {FACILITY_TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="intermodal_region_id"
              label="Intermodal Region"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}>
              <IntermodalRegionSelect />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="city"
                  label="City"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}>
                  <CitySelect
                    selected={getFieldValue('city')}
                    onSelect={(id) =>
                      handleChange({ target: { name: 'city', value: id } })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label="Street"
              name="street"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Zipcode"
              name="zipcode"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="D/O Address"
              name="delivery_order_address"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}>
              <Input.TextArea rows={4} placeholder="Enter D/O Address" />
            </Form.Item>

            <Form.Item
              label="Memo"
              name="memo"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea rows={2} placeholder="Enter Memo" />
            </Form.Item>

            <Form.Item
              label="Track Advise"
              name="track_advise"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea rows={2} placeholder="Enter Track Advise" />
            </Form.Item>

            <Form.Item
              label="Tracking Link"
              name="tracking_link"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Tracking Link" />
            </Form.Item>

            <Form.Item
              label="Drayage Link"
              name="drayage_link"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Drayage Link" />
            </Form.Item>
            <Form.Item
              label="Active"
              name="active"
              valuePropName="checked"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Switch />
            </Form.Item>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  label="Latitude"
                  name="latitude"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  required>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Longitude"
                  name="longitude"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  required>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="contacts"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}>
                  <ContactList
                    data={getFieldValue('contacts') || []}
                    updateData={(list) =>
                      handleChange({
                        target: { name: 'contacts', value: list },
                      })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
