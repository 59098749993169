import { FaShip } from 'react-icons/fa';
import { GiRailway } from 'react-icons/gi';
import logos from './logoData';
import { MdDirectionsRailway } from 'react-icons/md';
import { Tooltip } from 'antd';

const toLogo = (name: string, style = {}) => {
  const defaultStyle = {
    width: '20px',
    height: '20px',
  };
  return (
    {
      'OCEAN PORT': (
        <FaShip style={{ ...defaultStyle, ...style, color: '#9ECBFF' }} />
      ),
      RAMP: (
        <GiRailway style={{ ...defaultStyle, ...style, color: '#F97583' }} />
      ),
      'RAMP - BNSF': (
        <img style={{ ...defaultStyle, ...style }} src={logos['BNSF']} />
      ),
      'RAMP - UP': (
        <img style={{ ...defaultStyle, ...style }} src={logos['UP']} />
      ),
      'RAMP - NS': (
        <img style={{ ...defaultStyle, ...style }} src={logos['NS']} />
      ),
      'RAMP - CN': (
        <img style={{ ...defaultStyle, ...style }} src={logos['CN']} />
      ),
      'RAMP - CP': (
        <img style={{ ...defaultStyle, ...style }} src={logos['CP']} />
      ),
      'RAMP - CSZ': (
        <img style={{ ...defaultStyle, ...style }} src={logos['CSZ']} />
      ),
      'RAMP - KCS': (
        <img style={{ ...defaultStyle, ...style }} src={logos['KCS']} />
      ),
      'RAMP - CSX': (
        <img style={{ ...defaultStyle, ...style }} src={logos['CSX']} />
      ),
      'RAMP - DIT': (
        <MdDirectionsRailway style={{ ...defaultStyle, ...style }} />
      ),
      AMAZON: (
        <img style={{ ...defaultStyle, ...style }} src={logos['Amazon']} />
      ),
      WALMART: (
        <img style={{ ...defaultStyle, ...style }} src={logos['Walmart']} />
      ),
      WAYFAIR: (
        <img style={{ ...defaultStyle, ...style }} src={logos['Wayfair']} />
      ),
      MENARDS: (
        <img style={{ ...defaultStyle, ...style }} src={logos['Menards']} />
      ),
      GOODCANG: (
        <img style={{ ...defaultStyle, ...style }} src={logos['Goodcang']} />
      ),
    }[name] || null
  );
};

const Logo = ({ name }: { name: string }) => {
  const logo = toLogo(name.toUpperCase());
  return (
    <>
      {logo ? (
        <Tooltip title={name}>
          <span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                // backgroundColor: '#f0f0f0',
              }}>
              {logo}
            </div>
          </span>
        </Tooltip>
      ) : (
        ''
      )}
    </>
  );
};

export default Logo;
