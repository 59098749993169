import { Button, Drawer, message, Space } from 'antd';
import ShipmentForm from './ShipmentForm';
import { ShipmentBusinessStatus, ShipmentFromType } from '../constants';
import { FC, useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import { CARRIERS } from '@/utils/freight';
import { useForm } from 'antd/lib/form/Form';
import { ShipmentParams } from './QuoteForm';
import { omit } from 'lodash';

export interface ShipmentDrawerProps {
  visible: boolean;
  model?: any;
  params?: ShipmentParams | null;
  onSaved?: (id: number) => void;
  onClose: () => void;
}

const ShipmentDrawer: FC<ShipmentDrawerProps> = ({
  visible,
  model,
  params,
  onSaved,
  onClose,
}) => {
  const manualMode = !params;
  const id = model?.id;
  const rate = params?.rate;
  const [loading, setLoading] = useState(false);
  const app = useApp();
  const [form] = useForm();

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`tl/ltl/shipments/${id}`);
      // console.log(resp.data);
      form.setFieldsValue({
        ...resp.data,
        pickup_accessorials: resp.data.pickup_accessorials ?? [],
        destination_accessorials: resp.data.destination_accessorials ?? [],
        pickup_open_time: moment(resp.data.pickup_open_time, 'HH:mm'),
        pickup_close_time: moment(resp.data.pickup_close_time, 'HH:mm'),
        destination_open_time: moment(resp.data.destination_open_time, 'HH:mm'),
        destination_close_time: moment(
          resp.data.destination_close_time,
          'HH:mm',
        ),
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleBook = async () => {
    setLoading(true);
    try {
      await app.service.patch(`tl/ltl/shipments/${id}/confirm-book`);
      fetchData();
      message.success('Shipment has been booked successfully');
    } catch (err: any) {
      message.error(err?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handelSave = async () => {
    const values: any = await form.validateFields();
    setLoading(true);

    let resp = null;
    try {
      if (id) {
        resp = await app.service.patch(`tl/ltl/shipments/${id}`, {
          data: {
            ...values,
            pickup_open_time: moment(values.pickup_open_time).format('HH:mm'),
            pickup_close_time: moment(values.pickup_close_time).format('HH:mm'),
            destination_open_time: moment(values.destination_open_time).format(
              'HH:mm',
            ),
            destination_close_time: moment(
              values.destination_close_time,
            ).format('HH:mm'),
          },
        });
      } else {
        const addtion = manualMode
          ? {
              from_type: ShipmentFromType.MANUAL,
              vendor_carrier_name: CARRIERS.find(
                (item) => item.scac === values.vendor_carrier_code,
              )?.name,
            }
          : {
              from_type: ShipmentFromType.QUOTE,
              quote_id: rate?.quote_id,
              rate_id: rate?.id,
              vendor_rate_id: rate?.vendor_rate_id,
            };
        resp = await app.service.post('tl/ltl/shipments', {
          data: {
            ...(manualMode ? values : omit(values, ['quote_rate'])),
            ...addtion,
            pickup_open_time: moment(values.pickup_open_time).format('HH:mm'),
            pickup_close_time: moment(values.pickup_close_time).format('HH:mm'),
            destination_open_time: moment(values.destination_open_time).format(
              'HH:mm',
            ),
            destination_close_time: moment(
              values.destination_close_time,
            ).format('HH:mm'),
          },
        });
      }

      onSaved && onSaved(resp?.data?.id);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }

    if (visible && id) {
      fetchData();
    }
  }, [id, visible]);

  return (
    <Drawer
      title={model?.uid ? `Shipment ${model?.uid}` : 'New Shipment'}
      placement="right"
      width="90%"
      onClose={onClose}
      destroyOnClose={true}
      open={visible}
      extra={
        manualMode ? (
          <Space>
            <Button type="primary" loading={loading} onClick={handelSave}>
              Save
            </Button>
            {!model?.vendor_shipment_id &&
              model?.business_status === ShipmentBusinessStatus.CONFIRMING &&
              (model?.from_type === ShipmentFromType.QUOTE ||
                model?.from_type === ShipmentFromType.CLIENT_QUOTE) && (
                <Button
                  loading={loading}
                  type="primary"
                  onClick={() => model && handleBook()}
                >
                  Book
                </Button>
              )}
            <Button onClick={onClose}>Close</Button>
          </Space>
        ) : (
          <Space>
            <Button type="primary" loading={loading} onClick={handelSave}>
              Save
            </Button>
            <Button onClick={onClose}>Close</Button>
          </Space>
        )
      }
    >
      <ShipmentForm
        loading={loading}
        form={form}
        params={params}
        model={model}
      />
    </Drawer>
  );
};

export default ShipmentDrawer;
