import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { get, uniq } from 'lodash';
import useAction from '../useAction';
import actionUtil, {
  TLtlShipmentAction,
  TSendEmailModal,
} from '../../Interfaces/ActionInterface';

const SendPodEmailModal: React.FC<TSendEmailModal & TLtlShipmentAction> = ({
  task,
  admin,
  targetModel,
  target,
  open,
  onDone,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();
  const [docs, setDocs] = useState<any[]>([]);
  const { sendEmail } = useAction({ target, targetModel });

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(
          {
            ...data,
            task_id: task.id,
            action: actionUtil.SEND_POD,
          },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await sendEmail(config, setLoading, onDone);

      emailForm.resetFields();
    } catch (err: any) {
      err.data && message.error(err.data.message || err.data.error);
    } finally {
      setLoading(false);
    }
  };

  const initForm = () => {
    //TODO confirm
    const sendToList = get(targetModel, 'customer.emails', []);
    const salesEmail = get(targetModel, 'sales.email', '');
    const salesSupportEmail = get(targetModel, 'sales.support_email', '');

    emailForm.setFieldsValue({
      sendToList: sendToList?.length > 0 ? sendToList : [''],
      ccList: uniq([admin?.email, salesEmail, salesSupportEmail]).filter(
        (i) => i,
      ),
      subject: 'POD ',
      body: `
Dear customer,

Please`,
    });
  };

  useEffect(() => {
    open && initForm();
  }, [open, targetModel]);

  return (
    <>
      <Modal
        title="LTL - Send POD to customer"
        width={'75%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={emailForm} layout="vertical">
              <EmailForm
                form={emailForm}
                // docs={docs}
                has={{
                  from: false,
                  attachment: true,
                  showSelectAttachments: false,
                }}
              />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default SendPodEmailModal;
