// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/home/runner/work/drayeasy-admin/drayeasy-admin/node_modules/.pnpm/@umijs+runtime@3.5.27_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.tsx').default,
    "routes": [
      {
        "path": "/accounting/account-blocks",
        "exact": true,
        "component": require('@/pages/accounting/account-blocks/index.tsx').default
      },
      {
        "path": "/accounting/aging-reports/CompanyLink",
        "exact": true,
        "component": require('@/pages/accounting/aging-reports/CompanyLink.tsx').default
      },
      {
        "path": "/accounting/aging-reports/NameLink",
        "exact": true,
        "component": require('@/pages/accounting/aging-reports/NameLink.tsx').default
      },
      {
        "path": "/accounting/aging-reports/UserLink",
        "exact": true,
        "component": require('@/pages/accounting/aging-reports/UserLink.tsx').default
      },
      {
        "path": "/accounting/aging-reports",
        "exact": true,
        "component": require('@/pages/accounting/aging-reports/index.tsx').default
      },
      {
        "path": "/accounting/balance-sheets",
        "exact": true,
        "component": require('@/pages/accounting/balance-sheets/index.tsx').default
      },
      {
        "path": "/accounting/bank-accounts",
        "exact": true,
        "component": require('@/pages/accounting/bank-accounts/index.tsx').default
      },
      {
        "path": "/accounting/bills",
        "exact": true,
        "component": require('@/pages/accounting/bills/index.tsx').default
      },
      {
        "path": "/accounting/bills/payments",
        "exact": true,
        "component": require('@/pages/accounting/bills/payments.tsx').default
      },
      {
        "path": "/accounting/containers",
        "exact": true,
        "component": require('@/pages/accounting/containers/index.tsx').default
      },
      {
        "path": "/accounting/dashboard",
        "exact": true,
        "component": require('@/pages/accounting/dashboard/index.tsx').default
      },
      {
        "path": "/accounting/drayage/bills",
        "exact": true,
        "component": require('@/pages/accounting/drayage/bills/index.tsx').default
      },
      {
        "path": "/accounting/drayage/invoices",
        "exact": true,
        "component": require('@/pages/accounting/drayage/invoices/index.tsx').default
      },
      {
        "path": "/accounting/income-statements",
        "exact": true,
        "component": require('@/pages/accounting/income-statements/index.tsx').default
      },
      {
        "path": "/accounting/invoices",
        "exact": true,
        "component": require('@/pages/accounting/invoices/index.tsx').default
      },
      {
        "path": "/accounting/invoices/payments",
        "exact": true,
        "component": require('@/pages/accounting/invoices/payments.tsx').default
      },
      {
        "path": "/accounting/localstatements",
        "exact": true,
        "component": require('@/pages/accounting/localstatements/index.tsx').default
      },
      {
        "path": "/accounting/prepaid-orders",
        "exact": true,
        "component": require('@/pages/accounting/prepaid-orders/index.tsx').default
      },
      {
        "path": "/accounting/profit-report/customer-profit-report/CompanyLink",
        "exact": true,
        "component": require('@/pages/accounting/profit-report/customer-profit-report/CompanyLink.tsx').default
      },
      {
        "path": "/accounting/profit-report/customer-profit-report/NameLink",
        "exact": true,
        "component": require('@/pages/accounting/profit-report/customer-profit-report/NameLink.tsx').default
      },
      {
        "path": "/accounting/profit-report/customer-profit-report/UserLink",
        "exact": true,
        "component": require('@/pages/accounting/profit-report/customer-profit-report/UserLink.tsx').default
      },
      {
        "path": "/accounting/profit-report/customer-profit-report",
        "exact": true,
        "component": require('@/pages/accounting/profit-report/customer-profit-report/index.tsx').default
      },
      {
        "path": "/accounting/profit-report/ir-profit-report",
        "exact": true,
        "component": require('@/pages/accounting/profit-report/ir-profit-report/index.tsx').default
      },
      {
        "path": "/accounting/reports",
        "exact": true,
        "component": require('@/pages/accounting/reports/index.tsx').default
      },
      {
        "path": "/accounting/user-accounts",
        "exact": true,
        "component": require('@/pages/accounting/user-accounts/index.tsx').default
      },
      {
        "path": "/analysis/assignment",
        "exact": true,
        "component": require('@/pages/analysis/assignment/index.tsx').default
      },
      {
        "path": "/analysis/log-activity",
        "exact": true,
        "component": require('@/pages/analysis/log-activity/index.tsx').default
      },
      {
        "path": "/analysis/profit",
        "exact": true,
        "component": require('@/pages/analysis/profit/index.tsx').default
      },
      {
        "path": "/containers",
        "exact": true,
        "component": require('@/pages/containers/index.tsx').default
      },
      {
        "path": "/csDashboard",
        "exact": true,
        "component": require('@/pages/csDashboard/index.tsx').default
      },
      {
        "path": "/dispatchDashboard",
        "exact": true,
        "component": require('@/pages/dispatchDashboard/index.tsx').default
      },
      {
        "path": "/dispatchs",
        "exact": true,
        "component": require('@/pages/dispatchs/index.tsx').default
      },
      {
        "path": "/engine/indexTool",
        "exact": true,
        "component": require('@/pages/engine/indexTool/index.tsx').default
      },
      {
        "path": "/engine/rate",
        "exact": true,
        "component": require('@/pages/engine/rate/index.tsx').default
      },
      {
        "path": "/engine/routes",
        "exact": true,
        "component": require('@/pages/engine/routes/index.tsx').default
      },
      {
        "path": "/engine/setting",
        "exact": true,
        "component": require('@/pages/engine/setting/index.tsx').default
      },
      {
        "path": "/engine/verify",
        "exact": true,
        "component": require('@/pages/engine/verify/index.tsx').default
      },
      {
        "path": "/entity/SpecialRequirements",
        "exact": true,
        "component": require('@/pages/entity/SpecialRequirements/index.tsx').default
      },
      {
        "path": "/entity/billingCodes",
        "exact": true,
        "component": require('@/pages/entity/billingCodes/index.tsx').default
      },
      {
        "path": "/entity/cargoTypes",
        "exact": true,
        "component": require('@/pages/entity/cargoTypes/index.tsx').default
      },
      {
        "path": "/entity/chassisType",
        "exact": true,
        "component": require('@/pages/entity/chassisType/index.tsx').default
      },
      {
        "path": "/entity/cities",
        "exact": true,
        "component": require('@/pages/entity/cities/index.tsx').default
      },
      {
        "path": "/entity/containerTypes",
        "exact": true,
        "component": require('@/pages/entity/containerTypes/index.tsx').default
      },
      {
        "path": "/entity/dropZoneRates",
        "exact": true,
        "component": require('@/pages/entity/dropZoneRates/index.tsx').default
      },
      {
        "path": "/entity/facilityTypes",
        "exact": true,
        "component": require('@/pages/entity/facilityTypes/index.tsx').default
      },
      {
        "path": "/entity/generalLedgerCodes",
        "exact": true,
        "component": require('@/pages/entity/generalLedgerCodes/index.tsx').default
      },
      {
        "path": "/entity/googleSheets",
        "exact": true,
        "component": require('@/pages/entity/googleSheets/index.tsx').default
      },
      {
        "path": "/entity/intermodalRegions",
        "exact": true,
        "component": require('@/pages/entity/intermodalRegions/index.tsx').default
      },
      {
        "path": "/entity/oceanCarriers",
        "exact": true,
        "component": require('@/pages/entity/oceanCarriers/index.tsx').default
      },
      {
        "path": "/entity/pickupNumberAgents",
        "exact": true,
        "component": require('@/pages/entity/pickupNumberAgents/index.tsx').default
      },
      {
        "path": "/entity/ports",
        "exact": true,
        "component": require('@/pages/entity/ports/index.tsx').default
      },
      {
        "path": "/entity/projects",
        "exact": true,
        "component": require('@/pages/entity/projects/index.tsx').default
      },
      {
        "path": "/entity/socReturnLocation",
        "exact": true,
        "component": require('@/pages/entity/socReturnLocation/index.tsx').default
      },
      {
        "path": "/entity/terminals",
        "exact": true,
        "component": require('@/pages/entity/terminals/index.tsx').default
      },
      {
        "path": "/entity/vendorContracts",
        "exact": true,
        "component": require('@/pages/entity/vendorContracts/index.tsx').default
      },
      {
        "path": "/entity/vendorCredits",
        "exact": true,
        "component": require('@/pages/entity/vendorCredits/index.tsx').default
      },
      {
        "path": "/entity/vendorDivisions",
        "exact": true,
        "component": require('@/pages/entity/vendorDivisions/index.tsx').default
      },
      {
        "path": "/entity/vendorFormW9s",
        "exact": true,
        "component": require('@/pages/entity/vendorFormW9s/index.tsx').default
      },
      {
        "path": "/entity/vendorInsurances",
        "exact": true,
        "component": require('@/pages/entity/vendorInsurances/index.tsx').default
      },
      {
        "path": "/entity/vendors/:id?",
        "exact": true,
        "component": require('@/pages/entity/vendors/[id$].tsx').default
      },
      {
        "path": "/entity/vendorsV2/:id?",
        "exact": true,
        "component": require('@/pages/entity/vendorsV2/[id$].tsx').default
      },
      {
        "path": "/entity/warehouses",
        "exact": true,
        "component": require('@/pages/entity/warehouses/index.tsx').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.tsx').default
      },
      {
        "path": "/opDashboard",
        "exact": true,
        "component": require('@/pages/opDashboard/index.tsx').default
      },
      {
        "path": "/orders",
        "exact": true,
        "component": require('@/pages/orders/index.tsx').default
      },
      {
        "path": "/preOrders",
        "exact": true,
        "component": require('@/pages/preOrders/index.tsx').default
      },
      {
        "path": "/questionnaires",
        "exact": true,
        "component": require('@/pages/questionnaires/index.tsx').default
      },
      {
        "path": "/rates/bidRequests",
        "exact": true,
        "component": require('@/pages/rates/bidRequests/index.tsx').default
      },
      {
        "path": "/rates/buyRates",
        "exact": true,
        "component": require('@/pages/rates/buyRates/index.tsx').default
      },
      {
        "path": "/rates/quotes",
        "exact": true,
        "component": require('@/pages/rates/quotes/index.tsx').default
      },
      {
        "path": "/rates/requestRates",
        "exact": true,
        "component": require('@/pages/rates/requestRates/index.tsx').default
      },
      {
        "path": "/rates/sellRates",
        "exact": true,
        "component": require('@/pages/rates/sellRates/index.tsx').default
      },
      {
        "path": "/rates/spotRate/:id?",
        "exact": true,
        "component": require('@/pages/rates/spotRate/[id$].tsx').default
      },
      {
        "path": "/setting/2fa",
        "exact": true,
        "component": require('@/pages/setting/2fa/index.tsx').default
      },
      {
        "path": "/setting/admin",
        "exact": true,
        "component": require('@/pages/setting/admin/index.tsx').default
      },
      {
        "path": "/setting/companies",
        "exact": true,
        "component": require('@/pages/setting/companies/index.tsx').default
      },
      {
        "path": "/setting/configurations",
        "exact": true,
        "component": require('@/pages/setting/configurations/index.tsx').default
      },
      {
        "path": "/setting/consignees",
        "exact": true,
        "component": require('@/pages/setting/consignees/index.tsx').default
      },
      {
        "path": "/setting/emailTemplate",
        "exact": true,
        "component": require('@/pages/setting/emailTemplate/index.tsx').default
      },
      {
        "path": "/setting/holidays",
        "exact": true,
        "component": require('@/pages/setting/holidays/index.tsx').default
      },
      {
        "path": "/setting/phpinfo",
        "exact": true,
        "component": require('@/pages/setting/phpinfo/index.tsx').default
      },
      {
        "path": "/setting/plans",
        "exact": true,
        "component": require('@/pages/setting/plans/index.tsx').default
      },
      {
        "path": "/setting/smartTracking",
        "exact": true,
        "component": require('@/pages/setting/smartTracking/index.tsx').default
      },
      {
        "path": "/setting/users",
        "exact": true,
        "component": require('@/pages/setting/users/index.tsx').default
      },
      {
        "path": "/statistics/authlogs",
        "exact": true,
        "component": require('@/pages/statistics/authlogs/index.tsx').default
      },
      {
        "path": "/statistics/demands",
        "exact": true,
        "component": require('@/pages/statistics/demands/index.tsx').default
      },
      {
        "path": "/statistics/intermodalRegions",
        "exact": true,
        "component": require('@/pages/statistics/intermodalRegions/index.tsx').default
      },
      {
        "path": "/statistics/rateHeatMap",
        "exact": true,
        "component": require('@/pages/statistics/rateHeatMap/index.tsx').default
      },
      {
        "path": "/statistics/risk",
        "exact": true,
        "component": require('@/pages/statistics/risk/index.tsx').default
      },
      {
        "path": "/statistics/searchRateLogs",
        "exact": true,
        "component": require('@/pages/statistics/searchRateLogs/index.tsx').default
      },
      {
        "path": "/todo",
        "exact": true,
        "component": require('@/pages/todo.tsx').default
      },
      {
        "path": "/truckload/ftl/bills",
        "exact": true,
        "component": require('@/pages/truckload/ftl/bills/index.tsx').default
      },
      {
        "path": "/truckload/ftl/carriers",
        "exact": true,
        "component": require('@/pages/truckload/ftl/carriers/index.tsx').default
      },
      {
        "path": "/truckload/ftl/dashboard",
        "exact": true,
        "component": require('@/pages/truckload/ftl/dashboard/index.tsx').default
      },
      {
        "path": "/truckload/ftl/invoices",
        "exact": true,
        "component": require('@/pages/truckload/ftl/invoices/index.tsx').default
      },
      {
        "path": "/truckload/ftl/quotes",
        "exact": true,
        "component": require('@/pages/truckload/ftl/quotes/index.tsx').default
      },
      {
        "path": "/truckload/ftl/shipments/ShipmentTable/ShipmentTableInstance",
        "exact": true,
        "component": require('@/pages/truckload/ftl/shipments/ShipmentTable/ShipmentTableInstance.tsx').default
      },
      {
        "path": "/truckload/ftl/shipments/ShipmentTable",
        "exact": true,
        "component": require('@/pages/truckload/ftl/shipments/ShipmentTable/index.tsx').default
      },
      {
        "path": "/truckload/ftl/shipments",
        "exact": true,
        "component": require('@/pages/truckload/ftl/shipments/index.tsx').default
      },
      {
        "path": "/truckload/ftl/tlvendor",
        "exact": true,
        "component": require('@/pages/truckload/ftl/tlvendor/index.tsx').default
      },
      {
        "path": "/truckload/ltl/bills",
        "exact": true,
        "component": require('@/pages/truckload/ltl/bills/index.tsx').default
      },
      {
        "path": "/truckload/ltl/dashboard",
        "exact": true,
        "component": require('@/pages/truckload/ltl/dashboard/index.tsx').default
      },
      {
        "path": "/truckload/ltl/invoices",
        "exact": true,
        "component": require('@/pages/truckload/ltl/invoices/index.tsx').default
      },
      {
        "path": "/truckload/ltl/quotes",
        "exact": true,
        "component": require('@/pages/truckload/ltl/quotes/index.tsx').default
      },
      {
        "path": "/truckload/ltl/shipments/ShipmentTable/ShipmentTableInstance",
        "exact": true,
        "component": require('@/pages/truckload/ltl/shipments/ShipmentTable/ShipmentTableInstance.tsx').default
      },
      {
        "path": "/truckload/ltl/shipments/ShipmentTable",
        "exact": true,
        "component": require('@/pages/truckload/ltl/shipments/ShipmentTable/index.tsx').default
      },
      {
        "path": "/truckload/ltl/shipments",
        "exact": true,
        "component": require('@/pages/truckload/ltl/shipments/index.tsx').default
      },
      {
        "path": "/truckload/ltl/tlvendor",
        "exact": true,
        "component": require('@/pages/truckload/ltl/tlvendor/index.tsx').default
      },
      {
        "path": "/user/login",
        "exact": true,
        "component": require('@/pages/user/login.tsx').default
      },
      {
        "path": "/user/role",
        "exact": true,
        "component": require('@/pages/user/role.tsx').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
