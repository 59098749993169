import { Header } from '@/components/CommonHeader';
import { Button, Card, Col, message, Modal, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Filter from './components/filter';
import styles from 'res/css/ui.scss';
import { useState } from 'react';
import { useApp } from '@/utils/useapp';
import ProfitTable from './components/table';
import moment from 'moment';
import { TBill, TInvoice, TProfits } from '@/types';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export interface TOrder {
  id: number;
  mbl_number: string;
  user: {
    name: string;
    company: any;
    _company: string;
  };
  state: string;
  operator: {
    name: string;
  };
  sales: {
    name: string;
  };
  port_of_discharge_eta: string;
  containers_count: number;
  profit_category: string;
  ar_amount_total: string;
  ap_amount_total: string;
  profit_amount_total: string;
  commission_settled_at: string;
  exception_at: string;
  created_at: string;
  bills: TBill[];
  invoices: TInvoice[];
}

interface IData {
  orders: TOrder[];
  profits: TProfits;
}

const confirm = (text: string, onOk: () => any) => {
  Modal.confirm({
    title: 'Confirm',
    content: `Are you sure to ${text}?`,
    okText: 'yes',
    cancelText: 'cancel',
    onOk,
  });
};

const Index = () => {
  const app = useApp();
  const [filter] = useForm();
  const [data, setData] = useState<IData | null>(null);
  const [selected, setSelected] = useState<TOrder['id'][]>([] as number[]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    const _filter = await filter.validateFields();
    setLoading(true);
    try {
      const res = await app.service.get('analysis/profits', {
        params: { ..._filter },
      });
      setData(res);
    } catch (error: any) {
      message.error((error?.data?.error || error?.data?.message) ?? 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSettledChange = async (id: TOrder['id'][], checked: boolean) => {
    try {
      const changedSettledData = id.map((i) => {
        return {
          id: i,
          commission_settled_at: checked
            ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            : null,
        };
      });
      await app.service.put('orders/bulk', { data: changedSettledData });
      await handleSearch();
    } catch (error: any) {
      message.error(error?.data?.error ?? 'error');
    }
  };

  const handleExceptionChange = async (
    id: TOrder['id'][],
    checked: boolean,
  ) => {
    try {
      const d = id.map((i) => {
        return {
          id: i,
          exception_at: checked
            ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            : null,
        };
      });
      await app.service.put('orders/bulk', { data: d });
      await handleSearch();
    } catch (error: any) {
      message.error(error?.data?.error ?? 'error');
    }
  };

  const handleExport = () => {
    const csvRows: any[] = [];
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    data?.orders.forEach((row) => {
      const r = {
        'Order#': row.id,
        'MBL#': row.mbl_number,
        Customer: row.user?.name ?? '',
        Status: row.state,
        Operator: row?.operator?.name ?? '',
        Sales: row?.sales?.name ?? '',
        'POD ETA': row.port_of_discharge_eta,
        'Container Count': row.containers_count,
        'Profit Category': row.profit_category,
        'Total AR': row.ar_amount_total,
        'Total AP': row.ap_amount_total,
        'Total Profit': row.profit_amount_total,
        'Settled Time': row.commission_settled_at,
        'Exception Time': row.exception_at,
        'Create Time': row.created_at,
      };
      csvRows.push(r);
    });

    const ws = XLSX.utils.json_to_sheet(csvRows);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const d = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(d, 'data' + fileExtension);
  };

  return (
    <div className={styles.main}>
      <Header
        title="Profits"
        rightElement={
          <Space>
            <Button
              type="primary"
              onClick={() =>
                confirm('Settle', () => handleSettledChange(selected, true))
              }>
              Settle
            </Button>
            <Button
              onClick={() =>
                confirm('Cancel Settle', () =>
                  handleSettledChange(selected, false),
                )
              }>
              Cancel Settle
            </Button>
            <Button
              type="primary"
              onClick={() =>
                confirm('Exception', () =>
                  handleExceptionChange(selected, true),
                )
              }>
              Exception
            </Button>
            <Button
              onClick={() =>
                confirm('Cancel Exception', () =>
                  handleExceptionChange(selected, false),
                )
              }>
              Cancel Exception
            </Button>
          </Space>
        }></Header>

      <Filter
        loading={loading}
        form={filter}
        onSearch={handleSearch}
        onExport={handleExport}
      />

      {data && (
        <>
          <Row
            gutter={16}
            style={{ margin: '4px 18px' }}
            justify="space-between">
            {[
              { title: 'OP Profit: ', prop: 'onlyOperatorProfit' },
              { title: 'Sales Profit: ', prop: 'onlySalesProfit' },
              { title: 'OP & Sales Profit: ', prop: 'operatorAndSalesProfit' },
              { title: 'Other Profit: ', prop: 'otherProfit' },
            ].map((item) => {
              return (
                <Col key={item.prop} flex="1 1 0">
                  <Card bodyStyle={{ padding: '8px' }}>
                    <div className="mb-sm">{item.title}</div>
                    <div style={{ fontWeight: 'bold' }}>
                      {`$${
                        data.profits[item.prop as unknown as keyof TProfits]
                      }`}
                    </div>
                  </Card>
                </Col>
              );
            })}
            <Col flex="1 1 0" />
            <Col flex="1 1 0" />
          </Row>

          <ProfitTable
            style={{ marginTop: '30px' }}
            loading={loading}
            dataSource={data.orders}
            handleSettledChange={handleSettledChange}
            handlExceptionChange={handleExceptionChange}
            rowSelection={{
              onSelect: (a, b, selectedRows) => {
                setSelected(selectedRows.map((r) => r.id));
              },
              onSelectAll: (a, selectedRows) => {
                setSelected(selectedRows.map((r) => r.id));
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default Index;
