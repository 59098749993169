const logoData = {
  '': '',
  Amazon:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAllBMVEVHcEz////////////////////////9/f7///////////////////////////////8AAAADBQX29vb/owD/+vG2trbZ2dmZmZn/26n/8d3v7+//9eb/yHpISUn/1Zr/48GmpqY+Pz8jIyP/rxb/u0oxMjJsbW3j4+PAwcH/6Mj/wGHMzMx7fHxfYGDFxcX/tC0NDg6Li4slqlgLAAAAD3RSTlMAooK1rWYj+jfodsRYfMGCSs7OAAABRElEQVQokW2T2XqDIBCFSZsE0w1EUEFi3Jfsff+XK6ttUubG4fzMcZxvBABEa4j+BVxHQMXbH4UQ8nvaqLqFpFOmgqWLTwReXSayPTaxr3z1Cvh7Z4tijO/MSTvgEmb0vsFxjGtf6qH2rFMi9po+w0vTxJN6XkMQkXEkUIgwRCJl2XBpgvB07m27AZjW5huHc6hSdYnv1SjSABRa+1biKQCZ1q4qyXSSPsJKaxVCY28sHqF5Vc9Og24X+1LfkBX97KdHyKyKh6rBOINPQ5guevRMIFYziCinFvKSmuESuySQINolyZEbSMvW4iXoTA8dd7Z525a8cBdowfP2WEp1tGsyH5Okk/lBRS6Vp+T5rNdkba/nCrtoZY6Q9lmByLXND6W83W6dLOfCKlCt9Wbn26BFsbxZmX7old++vP/7H+DX5xaAHw4uN1n/ebb2AAAAAElFTkSuQmCC',
  Walmart:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAhUlEQVR4AWPABf7uMjQA4v9QbIBLHT4DHJAMcCDHAAUkAxQIOXU/EBegy8EMwCJeANVjAOI0wBRCBRXQDUBz1X4k9Q0wv55HEnwPxAlQDQEgDGUngORg6qB6HNADbT3MJejOhtkMVeNAKPAUCIlTzQCKvUBxIFIcjRQnJEqTMuWZieLsDADrniSwcyZ/OwAAAABJRU5ErkJggg==',
  Wayfair:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7klEQVR4AYWRAaZCURCGzz7eEh7vTbhtoBZ0Uigq6dJekjpCpSAQAQIC0g4GIGD6R3eow9yGD/B9jv8EvdO1IBBBAgIYUKhudu4QYCAggQjoXZYci2RyDmkgOgHZbtpcznuerMRgz87Z7Voyag5kUIykXHS9QAp1cv93rNRGNMB18pcI24jsy26EP35CB8tlN6LDmqz3uPzQLf3zpOh/DQwbU1l2lnwo92QyAQZyX/+JRTx51V0JZOUVMdlwIrlscDDRjTiyoYFUF3Fk46iBmMuGDlsNJg4TDZATYEA6FPAiHz8RQXqXQ3VZ5AiiyU8pJ222/Gv2rwAAAABJRU5ErkJggg==',
  Menards:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAABDlBMVEUAmj0Amz0AnT4AoD8AmDwAiDUAezAAaiYAaioAcSwAgTMAlz4ARRsAAAAxJi5BOj8VABEAHAsAFAc1LTMAOxcAQBmQkJD////g4OC4uLiKioqenp6/vr/X19cAlT92dnbMzMxWVlZmZmYcGxwAGAAAIA2Ghob19fVHREcANQzq6uo2MTUARBUAWSMsLCwAThsALxMxpTgAmT5TsDSOyyy73yLU2hebvCKGoyxjmDVXlDeDwi3k4RH/2Aj/wBH/oBj/cR7tVSPLUCdscjQziTo+qjfI3xr44Qn/zgz/rxX+fx37ViH6OyHvJCO2QCx+bTThIybMNiqdXDFmejeKhjDUXSbkPyWLZDJYgTh1gzQ7Y9BtAAABVklEQVR4AWKgKWBkZGRiAlA5FjgMAzEQDPOUmZkh/39dz76AuqKRxrCeqyQgVMcPwihO0szMBFEax1Hut7KATrcHfS8YGBzCyKv3xkymkhlOn7nQgtxedh1YqlyRwfpfbmCrckfAXmmLV1eFw1QDRwv7k1//PFfz0wtXC7d7IwuRa/PsMZvaPF+19N5ydlWd/kjd4ddr5dZI1C0R+VRpz4qca6sr7ORnK0sW5h8c9zes3LaFgMtyT5Xbcr7stZsDJEnyfichNoETBCrdyNh+5nuSZASU4a9SajiAIIaimSS39DA1jG3b7L+Qtb3v+vkAIMueJEIIo4OWmBAO8oIoyYqq4YvbAHCEplldNEzLdlzPDwL6VMO7QihEcZJmeVFWdQCpcxo4gvRdwXbyotkXELzEhEO8vC+UbdcTdEzS5VqoDuNUzQu4KVyw9n6wJwFegHD7gf+xBZfTLToxShFCAAAAAElFTkSuQmCC',
  Goodcang:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAUVBMVEVHcEwHOH0EN3/hRgvbRhQHOH0CN4HlRwDbRhQHOH0AN4MEN4LbRhQHOH0HOH3bRhTbRhTbRhQHOH0HOH3bRhScQTwHOH3cRhMHOH3bRhQHOH3PZFpqAAAAG3RSTlMAhHBph/++vP9YuUJMLunu2ikY1NIJzMqXsca5kFeXAAAAlklEQVR4Ad3QhQHEIBQD0FTyqbvB/ntWkPNboKnCw3HTRHGS/rOYorI/mpOFyrLkl5V0mFXfVrNBU6gWXdZ/2nAaRlET0J76YZyBxeKcre9IDva7nnjymzWsEW/bVlo8NX3ayBKQZqQoh33WBRPmsFkCogrzbpQwgqjQQ2faHijJ0eU6vm6yyTJ7kPFizOITJ+szCW6UA6lLCEr6REd2AAAAAElFTkSuQmCC',
  BNSF: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAKlBMVEX/ZgD/zKr/////693/rXf/wpn/1rv/cBH/o2b/9e7/j0T/hTP/4Mz/uIjK2oCjAAAASklEQVR4AWMgEggpKSmZAGlWpRAXJTUgw0mVgUUpnYGBQ0ktbVIUUGCTjoujkjYDA/ek6UCZAwwMNUoiLklqBQwMh4B6czcQZw8AHRsMR358IREAAAAASUVORK5CYII=',
  CN: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAtklEQVR4Ae3UAQbDMBTG8QGQIwQD5CgTBqDn6EF6hoEC9AC5SW4QGAAFvD3z4WOSBVuUPvzR98Kvonq5zfehGWiggQcCn9cwaVujpEU6H7QHCi1E96uW3iAGiyYdzYQJ10KxFwYFTVps5OmNRVuQIF8B908Qg54IjPyM3N9BzBJmZQiIecY8DwGxK9ilX4JbA3SaoLUGZhzYe6p9IIR67IXOFwYdob3FL7cQ6HqL5u3nbaCBJwVfeUelJDoAtZUAAAAASUVORK5CYII=',
  CP: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAASFBMVEVHcEzk8iLk8iLk8iLk8iLk8iLk8iLk8iLk8iLk8iLk8iLk8yLk8iLr+SIBAB8cHB+psyHL2CFLTx+6xSGaoyFbYCDF0SF7giBc16LEAAAADHRSTlMAMhbDh2A/7vngwhbLeaGpAAAA2ElEQVQokYWTWRKEIAxEQVBcsojb3P+mAwIqjMX0F1Wvkk5CIkSS1MoAmFFLUap1IMm0GZoGyDQ0N+vgR12FXXR6YwAhc+FnMfqedeYMly1SX7MpINMcqHG9F2ylBEEKXcCdyca3FmPODmKOgaBKS2ZKBTnTPi+V70DoxRPBQkzrBeFOi7jyM6lPq1LU7MJ8j04RjrGVg8kjtnbZ9w+mVuTVOy0zbuS1YxxCNMVjda7HHGSDZTF4hNuzffmypPPLRPMOm/9rUl8wl7mymvWlDucw+nNQj3P4Ao4gHhbCwI4mAAAAAElFTkSuQmCC',
  CSZ: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAmVBMVEX////U3OV5krNbeqNmhayMob24xNLw8vatu8+Qp8Gcr8fCyde+xtcAMHsAQoQARoYARIIAP38AOXoES4R+lrQANn0AO4AAMXcACW/e5e2GnbwASIYAQn8ASYQAR4NOcp0lVIsARH8bTYwAQYbK09/h6O8AQH0lW499k7Y5YI1vi6t/l7IXUY1df6lOdqEAKnYAHHBHaJZLaZO6adsCAAAAx0lEQVR4Ae3LxQHEMAwAQR+FyXY4hjBz/72Fy7j5SSuBx9/r/fn+BBFIP1mR1Id2t69umBZE2HYgRA5CLkKe5/nB2UIncl0ECWUYU85jnbEkIU4KTrqZ5YX2+yYJxuUxizhJEvS7WqFnNrhwgnHyKcC7TEh1rwInq8GtocdvCzrI4HMv6mYPQB4OcT8ekVbgQ5hlvu7KDdN1XcOMouhomDoJY8yaPvfrUQ4uJMRKyKUsIZyDu9ZxtMhiuKxLs34+fD0ti3i2vx28cxPD6hqpCAAAAABJRU5ErkJggg==',
  UP: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAz1BMVEX////f4+/o6/THzeQAAJAmRKKgqdDX3Ov4+v3w8vjO0+eNmccAJpgAHJYAIZcAEZQACJIALpsbO59zgr13h7+BjsJPY69pebiGksSapc5VaLAyS6S2vNnCyOGosdQAFpWzu9pEWapjdbf8//8AMZx1hb95Zpzr+/+6MELKgY2zAADRs8DKiZS1HDSSha+jH0bOLzPgjI3HAADoxMbsy87glJXJFR/JLzbchYZ+AD2bncPHISWOYpDNAACMTXyXj7alAAB2RYGhao+DImOSACI4d1s5AAABCUlEQVR4AbTPQ4IEQRAF0Cjbttq23X3/K42RlT3b+ZvES0TAf4eA75BPRtEMy/G8IEqygqtIy6qm029RZNUQ6m9qpmU7ruf5QRg6iskjJtBGFCeJkCZJGguiIjPoTUvOYknybSfNPYvNlBRFkpaCwmESr0ytIi+V2p8VLVIJkTRYVgxiSS1qKNASHfiWaDXd0qU8o4Y5raq0qtHyWyd0ptAEiq12p0v2+v0eORi+T0eIcWOYTGE2X8xguYL1BrYI7vZvWCHYo37xMMHwuPvF0xOekZt7HNlfTHG8cL9YXTG8AtpLvv9tZbKReBSBbN2INzzCdEbeRzxgEag8HT2CNBEr+DsUvI4OAAA3GSUgeitHjwAAAABJRU5ErkJggg==',
  NS: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcBAMAAACAI8KnAAAAMFBMVEVHcEwfJCggJCggJCggIychJSkhJSkhJCkgJCggJCghJSkgJSkgIighJSkhJSkgJCisyIv0AAAAEHRSTlMAC5lSN/L/2CltyH8YreJCJ+y9zAAAAH1JREFUeAFjoAEQZGAQQOIyKTspwDmmaelpaQ1QDqPnsjQg2ADl8mxPA4FkCE/yehiIB1PNsQ/IRKiWvwfl5oB4nM/SoCAXxGVelgYDE4BcYQg3D6goAihZBpHJF36WlsXA4AdVuICBOyyJgeEXQp/cCoa/76BAAOg6hoECAIRYO63eJSKNAAAAAElFTkSuQmCC',
  KCS: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAeFBMVEX//v7++vr0u771v8L0uLvztbjwnqLobHLnZ23pcnjpdXvqeoDxpqryqq7tjZLsiI3fN0DZERvbGSPbHynjTlXxp6vul5vrgYfhQEnwoKTzs7b40NLkV1/64OLeLzjfNj/cJS/jTFT3y83yrrLcJC7mX2b87u/519hhNz4qAAAAwUlEQVR4ATWLA5IAQRAEez221/7/C8+XoSIAVHXd1N+0XQXfvu0HhAnGmDL+nXS9wFJpY41xnFXAvaJVE0Ifg1CJ9ZCJltCWsZSaTRplYETHeVTLsm7TviEPDG/HGXKkF0kx3R78kJ7lZnjAwyL+F4+0W5JWLQYx4Ag/WIbgfc7tffPvSzvzsxfnyeph4pCH6X3TIqY9KWWGDIzfzikaKWeSOO+h4v6WfXDrui6En/DFySWmlBBCc4Efytn9UNov8wn85hAOUtfxNgAAAABJRU5ErkJggg==',
  CSX: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAmVBMVEX////U3OV5krNbeqNmhayMob24xNLw8vatu8+Qp8Gcr8fCyde+xtcAMHsAQoQARoYARIIAP38AOXoES4R+lrQANn0AO4AAMXcACW/e5e2GnbwASIYAQn8ASYQAR4NOcp0lVIsARH8bTYwAQYbK09/h6O8AQH0lW499k7Y5YI1vi6t/l7IXUY1df6lOdqEAKnYAHHBHaJZLaZO6adsCAAAAx0lEQVR4Ae3LxQHEMAwAQR+FyXY4hjBz/72Fy7j5SSuBx9/r/fn+BBFIP1mR1Id2t69umBZE2HYgRA5CLkKe5/nB2UIncl0ECWUYU85jnbEkIU4KTrqZ5YX2+yYJxuUxizhJEvS7WqFnNrhwgnHyKcC7TEh1rwInq8GtocdvCzrI4HMv6mYPQB4OcT8ekVbgQ5hlvu7KDdN1XcOMouhomDoJY8yaPvfrUQ4uJMRKyKUsIZyDu9ZxtMhiuKxLs34+fD0ti3i2vx28cxPD6hqpCAAAAABJRU5ErkJggg==',
};

export default logoData;
