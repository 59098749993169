import React, { ReactElement } from 'react';
import { Form, FormInstance, Space, Table, Tooltip } from 'antd';
import styles from 'res/css/ui.scss';
import { kebabCase, map } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance;
  dataSource: any;
  onArchive: () => void;
}

type TableItem = {
  key: string;
  title: string | ReactElement;
  bank: string | number | ReactElement;
  beginning: string | number | ReactElement;
  resolved: string | number | ReactElement;
  sum: string | number | ReactElement;
  unresolved: string | number | ReactElement;
  hold: string | number | ReactElement;
  children?: any;
};

type TableDataProps = TableItem[];

type DefaultExpandKeys = React.Key[];

const strongValues = (value: string | number, tips?: string): ReactElement => {
  return (
    <Space>
      <strong>{value}</strong>
      {!!tips && (
        <Tooltip title={tips}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Space>
  );
};

const weakValue = (value: string | number): ReactElement => {
  return <span className={styles.table_td_weak}>{value}</span>;
};

export const DrawerSheet: React.FC<Props> = ({
  form,
  dataSource,
  onArchive,
}) => {
  const [tableData, setTableData] = React.useState<TableDataProps>();
  const [expandedRowKeys, setExpandedRowKeys] =
    React.useState<DefaultExpandKeys>();

  const solveDataKeys = (dataKeys: any) => {
    return map(dataKeys, (row: any, key: string) => {
      return {
        key: key,
        title: key,
        bank: row.bank,
        beginning: row.beginning,
        resolved: row.paid || row.received,
        sum: row.sum,
        unresolved: row.unpaid || row.unreceived,
        hold: row.receivable || row.payable,
        children: row.items?.map((item: any, k: any) => {
          return {
            key: `${key}-${k}`,
            title: weakValue(`${item.uid} ${item.code}`),
            bank: weakValue(item.bank),
            beginning: weakValue(item.beginning),
            resolved: weakValue(item.paid || item.received),
            sum: weakValue(item.sum),
            unresolved: weakValue(item.unpaid || item.unreceived),
            hold: weakValue(item.receivable || item.payable),
          };
        }),
      };
    });
  };

  React.useEffect(() => {
    if (dataSource) {
      const dataList: TableDataProps = [];
      const defaultKeys: DefaultExpandKeys = [
        'revenues',
        'revenue_total',
        'costs',
        'cost_total',
        'net_income',
        'net_income_total',
      ];
      //revenues
      const revenues: TableItem = {
        key: 'revenues',
        title: strongValues('Revenue'),
        bank: strongValues(
          'Bank Account',
          'Deposited Payments to all banks if has no filter bank or to specific bank if has filter bank.',
        ),
        beginning: strongValues(
          'Beginning Balance',
          'Beginning Balance = Prior Period Ending Balance',
        ),
        resolved: strongValues('Received', 'Deposited Payments to all banks.'),
        sum: strongValues(
          'Issued This Period',
          'Total amount of all Invoices Issued This Period.',
        ),
        unresolved: strongValues(
          'Ending Balance',
          'Ending Balance = Beginning Balance + Issued This Period - Received',
        ),
        hold: strongValues(
          'Accounts Receivable',
          'Accounts Receivable = Issued This Period - Received',
        ),
        children: [],
      };
      revenues.children.push(...solveDataKeys(dataSource.revenues));
      revenues.children.push({
        key: 'revenue_total',
        title: '',
        bank: strongValues(dataSource['revenues_bank_total']),
        beginning: strongValues(dataSource['revenues_beginning_total']),
        resolved: strongValues(dataSource['revenues_received_total']),
        sum: strongValues(dataSource['revenues_total']),
        unresolved: strongValues(dataSource['revenues_unreceived_total']),
        hold: strongValues(dataSource['revenues_receivable_total']),
      });
      dataList.push(revenues);
      //costs
      const costs: TableItem = {
        key: 'costs',
        title: strongValues('Cost'),
        bank: strongValues(
          'Bank Account',
          'Cleared Payments to all banks if has no filter bank or to specific bank if has filter bank.',
        ),
        beginning: strongValues(
          'Beginning Balance',
          'Beginning Balance = Prior Period Ending Balance',
        ),
        resolved: strongValues('Paid', 'Cleared Payments to all banks.'),
        sum: strongValues(
          'Issued This Period',
          'Total amount of all Bills Issued This Period.',
        ),
        unresolved: strongValues(
          'Ending Balance',
          'Ending Balance = Beginning Balance + Issued This Period - Received',
        ),
        hold: strongValues(
          'Accounts Payable',
          'Accounts Payable = Issued This Period - Paid',
        ),
        children: [],
      };
      costs.children.push(...solveDataKeys(dataSource.costs));
      costs.children.push({
        key: 'cost_total',
        title: '',
        bank: strongValues(dataSource['costs_bank_total']),
        beginning: strongValues(dataSource['costs_beginning_total']),
        resolved: strongValues(dataSource['costs_paid_total']),
        sum: strongValues(dataSource['costs_total']),
        unresolved: strongValues(dataSource['costs_unpaid_total']),
        hold: strongValues(dataSource['costs_payable_total']),
      });
      dataList.push(costs);

      const netIncome: TableItem = {
        key: 'net_income',
        title: strongValues('Net Icome'),
        bank: strongValues('Bank Account'),
        beginning: strongValues('Beginning Total'),
        resolved: strongValues('Banked G.'),
        sum: strongValues('Issued Total'),
        unresolved: strongValues('Pending'),
        hold: strongValues('Net Working Capital'),
        children: [],
      };
      netIncome.children.push(...solveDataKeys(dataSource.income));
      netIncome.children.push({
        key: 'net_income_total',
        title: '',
        bank: strongValues(dataSource['bank_income']),
        beginning: strongValues(dataSource['beginning_come']),
        resolved: strongValues(dataSource['banked_income']),
        sum: strongValues(dataSource['total_income']),
        unresolved: strongValues(dataSource['pending_income']),
        hold: strongValues(dataSource['net_working_capital']),
      });
      dataList.push(netIncome);

      setTableData(dataList);
      setExpandedRowKeys(defaultKeys);
    }
  }, []);
  return (
    <Form layout="vertical" form={form} onFinish={onArchive}>
      {tableData && tableData.length > 0 ? (
        <Table
          defaultExpandedRowKeys={expandedRowKeys ? expandedRowKeys : []}
          pagination={false}
          showHeader={false}
          rowKey={'key'}
          dataSource={tableData ? tableData : []}
          scroll={{
            x: 'auto',
          }}>
          <Table.Column dataIndex={'title'} align="left" />
          <Table.Column dataIndex={'beginning'} align="right"></Table.Column>
          <Table.Column dataIndex={'sum'} align="right"></Table.Column>
          <Table.Column dataIndex={'resolved'} align="right"></Table.Column>
          <Table.Column dataIndex={'hold'} align="right"></Table.Column>
          <Table.Column dataIndex={'bank'} align="right"></Table.Column>
          <Table.Column dataIndex={'unresolved'} align="right"></Table.Column>
        </Table>
      ) : (
        <></>
      )}
    </Form>
  );
};
