import { UserSyncSelect } from '@/components/UserSyncSelect';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  FormProps,
  Radio,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import styles from 'res/css/ui.scss';
import StatusSelect from './status-select';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { useWatch } from 'antd/lib/form/Form';

interface FilterProps extends FormProps {
  form: FormInstance<any>;
  onSearch: () => void;
  loading: boolean;
  onExport: () => void;
}

const Index = ({ form, onSearch, loading, onExport }: FilterProps) => {
  const by = useWatch('by', form);
  return (
    <Form
      form={form}
      layout="vertical"
      className={styles.filter}
      initialValues={{ by: 'admin' }}>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col md={6}>
          <Form.Item name="by" label="&nbsp;">
            <Radio.Group
              options={[
                {
                  label: 'By Admin',
                  value: 'admin',
                },
                {
                  label: 'By Company',
                  value: 'company',
                },
                {
                  label: 'By Customer',
                  value: 'customer',
                },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
        {by === 'admin' && (
          <Col md={2}>
            <Form.Item
              name="operator_id"
              label="Admin"
              rules={[{ required: true, message: 'required' }]}>
              <UserSyncSelect type="admin" />
            </Form.Item>
          </Col>
        )}

        {by === 'customer' && (
          <Col md={2}>
            <Form.Item
              name="user_id"
              label="Customer"
              rules={[{ required: true, message: 'required' }]}>
              <UserSyncSelect />
            </Form.Item>
          </Col>
        )}
        {by === 'company' && (
          <Col md={2}>
            <Form.Item
              name="company_id"
              label="Company"
              rules={[{ required: true, message: 'required' }]}>
              <CompanySyncSelect />
            </Form.Item>
          </Col>
        )}
        <Col md={5}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => {
              const start = getFieldValue('created_at_start_date');
              const end = getFieldValue('created_at_end_date');
              return (
                <>
                  <Form.Item hidden name="created_at_start_date" />
                  <Form.Item hidden name="created_at_end_date" />
                  <Form.Item label="Time">
                    <DatePicker.RangePicker
                      value={[
                        start ? moment(start) : null,
                        end ? moment(end) : null,
                      ]}
                      style={{ width: '100%' }}
                      onChange={(date, dataStrings) => {
                        setFieldsValue({
                          created_at_start_date: dataStrings[0],
                          created_at_end_date: dataStrings[1],
                        });
                      }}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item name="status" label="Status">
            <StatusSelect />
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item name="settled" label="Settled">
            <Select>
              <Select.Option value="">{''}</Select.Option>
              <Select.Option value={true}>{'yes'}</Select.Option>
              <Select.Option value={false}>{'no'}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item name="exception" label="Exception">
            <Select>
              <Select.Option value="">{''}</Select.Option>
              <Select.Option value={true}>{'yes'}</Select.Option>
              <Select.Option value={false}>{'no'}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={5}>
          <Form.Item label=" ">
            <Row gutter={8}>
              <Col>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  loading={loading}
                  onClick={onSearch}>
                  Search
                </Button>
              </Col>
              <Col>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => form.resetFields()}>
                  Clear
                </Button>
              </Col>
              <Col>
                <Button style={{ width: '100%' }} onClick={() => onExport()}>
                  Export
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Index;
