import React, { useState, useMemo, useEffect } from 'react';

import { Button, Popover, Checkbox, Divider, Table, Row, Space } from 'antd';
import { TodoTable } from './TodoTable';
import { TAction } from '../Interfaces/ActionInterface';
import taskTypeUtil from '../Interfaces/TaskType';
import whoseTaskUtil from '../Interfaces/WhoseTask';
import { TaskInterface } from '../Interfaces/TaskInterface';
import { UnorderedListOutlined } from '@ant-design/icons';
import useAction from '../Actions/useAction';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

const CheckboxGroup = Checkbox.Group;

export const TodoList: React.FC<TAction> = ({
  target,
  targetModel,
  onDone,
}) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(
    taskTypeUtil.getCheckedTaskTypeList(target),
  );

  const [whoseTaskcheckedList, setWhoseTaskcheckedList] = useState<
    CheckboxValueType[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [respData, setRespData] = useState();
  const { fetchData } = useAction({
    target: target,
    targetModel: targetModel,
  });

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      return;
    }

    setLoading(true);
    const resp = await fetchData(setLoading);
    setRespData(resp);
    setLoading(false);
  };

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const afterUpdated = () => {
    handleOpenChange(true);
    onDone && onDone();
  };

  const color: string = useMemo(() => {
    if (
      !targetModel ||
      !targetModel.task_icon_colors ||
      !targetModel?.task_icon_colors[taskTypeUtil.TASK_TYPE_TODOLIST]
    ) {
      return 'gray';
    }

    return targetModel?.task_icon_colors[taskTypeUtil.TASK_TYPE_TODOLIST];
  }, [targetModel]);

  const data: Array<TaskInterface> = useMemo(() => {
    if (checkedList?.length == 0) {
      return [];
    }

    const checkedData = respData?.filter((task) =>
      checkedList?.includes(task.task_type),
    );

    if (whoseTaskcheckedList?.length > 0) {
      return checkedData?.filter((t) =>
        whoseTaskcheckedList?.includes(t.whose_task),
      );
    }

    return checkedData;
  }, [respData, checkedList, whoseTaskcheckedList]);

  return (
    <>
      <Popover
        zIndex={1000}
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        onOpenChange={handleOpenChange}
        placement="left"
        content={
          <div style={{ maxWidth: 1300, minHeight: 800 }}>
            <CheckboxGroup
              options={(target && taskTypeUtil?.getOptions(target)) || []}
              value={checkedList}
              onChange={onChange}
            />
            <br />
            <CheckboxGroup
              options={whoseTaskUtil.getOptions()}
              value={whoseTaskcheckedList}
              onChange={(values: CheckboxValueType[]) => {
                setWhoseTaskcheckedList(values);
              }}
            />
            <Divider />
            <TodoTable
              data={data || []}
              loading={loading}
              target={target}
              targetModel={targetModel}
              onDone={afterUpdated}
            />
          </div>
        }
      >
        <Button type="link" size="small">
          <span className={`text-${color}`}>
            <UnorderedListOutlined />
          </span>
        </Button>
      </Popover>
    </>
  );
};
