import { Button, Modal, Space, message, Form, Input, Row } from 'antd';
import React, { useMemo } from 'react';

import { useApp } from '@/utils/useapp';

import { get, has } from 'lodash';
import { useForm } from 'antd/lib/form/Form';
import { SelectDocumentType } from './SelectDocumentType';

import { DOCUMENT_FILE_TYPE_OTHER, DOCUMENT_FILE_TYPE_BOL } from './data';
interface IAfterUploadedModal {
  ltlShipment: any;
  open: boolean;
  files: any;
  onClose: () => void;
  onSaved: () => void;
}

export const AfterUploadedModal: React.FC<IAfterUploadedModal> = ({
  ltlShipment,
  open,
  files,
  onClose,
  onSaved,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();

  const handleSkip = () => {
    setLoading(false);
    form.resetFields();
    onClose();
  };

  const handleSave = async () => {
    const data = await form.validateFields();
    data.documentIds = files.map((f: any) => f.id);

    setLoading(true);
    try {
      await app.service.put('documents/bulkUpdate', {
        data,
      });

      onSaved();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title=""
      destroyOnClose
      cancelText="Close"
      footer={
        <Space>
          <Button type="primary" disabled={loading} onClick={handleSave}>
            Save
          </Button>
          {/* <Button disabled={loading} onClick={handleSkip}>
            Skip
          </Button> */}
        </Space>
      }
      open={open}
      onCancel={handleSkip}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="customer_visible" hidden>
          <Input />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item name="document_type" label="Document type">
              <SelectDocumentType
                value={getFieldValue('document_type')}
                onChange={(val) => {
                  setFieldsValue(val);
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => (
            <>
              {getFieldValue('document_type') == DOCUMENT_FILE_TYPE_OTHER && (
                <Form.Item name="comment" label="Comment">
                  <Input.TextArea rows={6} />
                </Form.Item>
              )}
            </>
          )}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => (
            <>
              <Form.Item name="filename" label="File Name">
                <Input
                  disabled={
                    getFieldValue('document_type') == DOCUMENT_FILE_TYPE_BOL
                  }
                  placeholder="If you want keep the original filename, leave this field empty."
                />
              </Form.Item>
            </>
          )}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            if (getFieldValue('document_type') != DOCUMENT_FILE_TYPE_BOL) {
              return <></>;
            }

            let filename = `BOL-${ltlShipment.uid}`;

            get(ltlShipment, 'customer_reference_number') &&
              (filename += '-' + get(ltlShipment, 'customer_reference_number'));

            return (
              <Row className="text-danger">
                The system will automatically name the BOL file.
              </Row>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};
