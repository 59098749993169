import { ORDER_STATES } from '@/pages/orders/components/data';
import { Select, SelectProps, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { capitalize } from 'lodash';

const STATUS = ORDER_STATES;

const Index = (props: SelectProps) => {
  const app = useApp();

  const [statesCount, setStatesCount] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchoStatesCount = React.useCallback(async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('orders/statesCount');
      setStatesCount(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchoStatesCount();
  }, []);

  return (
    <Select {...props}>
      {Object.keys(statesCount).map((state: string) => {
        return (
          <Select.Option key={state} value={state}>
            {capitalize(state)}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default Index;
