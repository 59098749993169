export enum ShipmentCustomerStatus {
  DRAFT = 0,
  CONFIRMING = 10,
  PENDING_PICKUP = 20,
  IN_TRANSIT = 30,
  DELIVERED = 40,
  CANCELED = 50,
  COMPLETED = 60,
}

export enum ShipmentBusinessStatus {
  DRAFT = 0,
  CONFIRMING = 10,
  PENDING_PICKUP = 20,
  IN_TRANSIT = 30,
  DELIVERED = 40,
  CANCELED = 50,
  OP_CLOSE = 60,
}
export enum QuoteBusinessStatus {
  DRAFT = 0,
  SUBMITED = 10,
  CONFIRMED = 20,
  BOOKED = 30,
  EXPIRED = 40,
}

export enum LTLAddressType {
  BUSSINESS = 'bussiness',
  RESIDENTIAL = 'residential',
  TRADESHOW = 'tradeshow',
  LIMITED_ACCESS = 'limitedAccess',
}

export const LTLAddressTypeLabelMap = {
  [LTLAddressType.BUSSINESS]: 'Business',
  [LTLAddressType.RESIDENTIAL]: 'Residential',
  [LTLAddressType.TRADESHOW]: 'Trade Show',
  [LTLAddressType.LIMITED_ACCESS]: 'Limited Access',
};

export enum ShipmentStatus {
  PROCESSING = 0,
  WAIT_REQUOTE = 1,
  WAIT_CONFIM = 2,
  FAILED = 3,
  SUCCESS = 4,
  SYNCED = 5,
  SYNC_FAILED = 6,
}

export enum ShipmentFromType {
  MANUAL = 'manual',
  QUOTE = 'quote',
  CLIENT_QUOTE = 'client_quote',
}

export enum ArStatus {
  NONE = 0,
  IN_DISPUTE = 3,
  ISSUED = 5,
  DEPOSITED = 20,
}

export const ArStatusLabelMap = {
  [ArStatus.NONE]: 'None',
  [ArStatus.IN_DISPUTE]: 'In Dispute',
  [ArStatus.ISSUED]: 'Issued',
  [ArStatus.DEPOSITED]: 'Deposited',
};

export enum ApStatus {
  NONE = 0,
  IN_DISPUTE = 3,
  ISSUED = 5,
  CLEARED = 20,
}

export const ApStatusLabelMap = {
  [ApStatus.NONE]: 'None',
  [ApStatus.IN_DISPUTE]: 'In Dispute',
  [ApStatus.ISSUED]: 'Issued',
  [ApStatus.CLEARED]: 'Cleared',
};

export enum RebillStatus {
  RECEIVED = 10,
  IN_DISPUTE = 30,
  REJECTED = 40,
  ACCEPTED = 50,
}

export const RebillStatusLabelMap = {
  [RebillStatus.RECEIVED]: 'Received',
  [RebillStatus.IN_DISPUTE]: 'In Dispute',
  [RebillStatus.REJECTED]: 'Rejected',
  [RebillStatus.ACCEPTED]: 'Accepted',
};

export const generateOptions = (enumObj: any) =>
  Object.keys(enumObj).map((key) => ({
    label: enumObj[key],
    value: Number(key),
  }));
