import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  message,
} from 'antd';

import { INVOICE_STATES } from './constants';
import moment from 'moment';
import React, { useState } from 'react';
import { useApp } from '@/utils/useapp';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { STATUS_MAP } from './data';
// import { LocalStatementSyncSelect } from '@/components/LocalStatementSyncSelect';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({ filter, onSearch }) => {
  const app = useApp();
  const [showAllFilters, setShowAllFilters] = React.useState(false);

  const handleReset = () => {
    filter.resetFields();
    onSearch();
  };

  React.useEffect(() => {
    if (app.store.search.rateId) {
      filter.setFieldsValue({ id: app.store.search.rateId });
    }
  }, [app.store.search.rateId]);

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Form
          layout="vertical"
          form={filter}
          onFinish={onSearch}
          initialValues={{ date_field: 'invoiced_at' }}
          style={{ width: '100%' }}
        >
          <Row gutter={16}>
            <Col md={2}>
              <Form.Item name="query" label="Search">
                <Input placeholder="IV#/MBL#/CNTR#/LTL#/FTL#" />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item label="Company" name="company_id">
                    <CompanySyncSelect
                      selected={getFieldValue('company_id')}
                      onSelect={(company_id: any) => {
                        setFieldsValue({
                          company_id,
                        });
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item label="Bill To" name="bill_to_id">
                    <CompanySyncSelect
                      selected={getFieldValue('bill_to_id')}
                      onSelect={(bill_to_id: any) => {
                        setFieldsValue({
                          bill_to_id,
                        });
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item name="user_id" label="Customer" shouldUpdate>
                <UserSyncSelect />
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item name="state" label="Status">
                <Select
                  allowClear
                  options={INVOICE_STATES.map((s) => ({ value: s, text: s }))}
                />
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item name="dispute" label="Dispute">
                <Select allowClear>
                  {Object.keys(STATUS_MAP).map((key: any) => (
                    <Select.Option key={key} value={key}>
                      {STATUS_MAP[(key as unknown) as keyof typeof STATUS_MAP]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item name="sent" label="Sent">
                <Select allowClear>
                  <Select.Option value="">All</Select.Option>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={2}>
              <Form.Item name="statement_id" label="Statement#">
                <Input placeholder="Statement#" />
              </Form.Item>
            </Col>
            <Col md={5}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={handleReset}>Clear All</Button>
                  <Button onClick={() => setShowAllFilters(!showAllFilters)}>
                    {showAllFilters ? 'Hide Filters' : 'Show All Filter'}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={{
              height: showAllFilters ? 'auto' : 0,
              width: showAllFilters ? '100%' : 0,
            }}
            gutter={8}
          >
            <Col
              style={{
                visibility: showAllFilters ? 'visible' : 'hidden',
              }}
              md={7}
            >
              <Form.Item name="start_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="date_field" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const startDate = getFieldValue('start_date');
                  const endDate = getFieldValue('end_date');
                  return (
                    <Input.Group compact>
                      <Select
                        value={getFieldValue('date_field')}
                        onChange={(value) =>
                          setFieldsValue({ date_field: value })
                        }
                        style={{ width: '30%' }}
                      >
                        <Select.Option value="invoiced_at">
                          Inovice Date
                        </Select.Option>
                        <Select.Option value="created_at">
                          Create Date
                        </Select.Option>
                        <Select.Option value="due_at">Due Date</Select.Option>
                        <Select.Option value="deposited_at">
                          Deposited Date
                        </Select.Option>
                        <Select.Option value="penalty_at">
                          Penalty Date
                        </Select.Option>
                      </Select>
                      <DatePicker.RangePicker
                        allowEmpty={[false, true]}
                        style={{ width: '70%' }}
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : undefined
                        }
                        onChange={(value) =>
                          setFieldsValue({
                            start_date: value
                              ? value[0]?.format('YYYY-MM-DD')
                              : null,
                            end_date: value
                              ? value[1]?.format('YYYY-MM-DD')
                              : null,
                          })
                        }
                      />
                    </Input.Group>
                  );
                }}
              </Form.Item>
            </Col>
            <Col
              style={{
                visibility: showAllFilters ? 'visible' : 'hidden',
              }}
              span={3}
            >
              <Form.Item label="Related Person" name="releated_person_id">
                <UserSyncSelect type="admin" allowClear />
              </Form.Item>
            </Col>
            <Col
              style={{
                visibility: showAllFilters ? 'visible' : 'hidden',
              }}
              span={3}
            >
              <Form.Item label="Has Due Amount" name="has_due_amount">
                <Select allowClear>
                  <Select.Option value="true">Has Due Amount</Select.Option>
                  <Select.Option value="false">No Due Amount</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              style={{
                visibility: showAllFilters ? 'visible' : 'hidden',
              }}
              span={3}
            >
              <Form.Item label="Type" name="type">
                <Select allowClear>
                  <Select.Option value="customer_not_confirmed">
                    Customer not confirmed
                  </Select.Option>
                  <Select.Option value="sales_not_confirmed">
                    Sales Not Confirmed
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
