import { TAccount, TCollection } from '@/types';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  DatePicker,
  // Image,
  Input,
  message,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DollarOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { find, map } from 'lodash';
import PrepayOrderDetail from './components/PrepayOrderDetail';
import TopUpPreChargeButton from './components/PreChargeButton';
import TopUpRejectButton from './components/RejectButton';
import { buildUrl } from '@/utils/request';
import AutoResizeTable from '@/components/AutoResizeTable';
import styles from 'res/css/ui.scss';
import { Header } from '@/components/CommonHeader';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import moment from 'moment';
import { AntDRangePicker } from '@/components/AntDRangePicker';
import Link from 'antd/lib/typography/Link';
import usePagination from '@/components/usePagination';

/* 银行 */
export type TBank = {
  id: number;
  name: string;
  payment: string;
  payment_name: string;
  currency: string;
  description: string;
  status: boolean;
  exchange_rate_cost: number;
  created_at: string;
};

export type TPrepaidOrderStatus = 0 | 1 | -1;
export type TPrepaidOrder = {
  id: number;
  order_number: string;
  bill_number: string;
  account: any;
  amount: number;
  currency: string;
  prepaid_amount: number;
  prepaid_currency: string;
  exchange_rate: number;
  exchange_rate_cost: number;
  bank: any;
  status: TPrepaidOrderStatus;
  documents: any[];
  data: any;
  created_at: string;
  updated_at: string;
};

const { Paragraph } = Typography;

const statusOptions = {
  pending: 'pending',
  rejected: 'rejected',
  settled: 'settled',
};

const TopUp: FC = (props) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TCollection<TPrepaidOrder>>();
  const [accounts, setAccounts] = useState<Array<TAccount>>([]);
  const [banks, setBanks] = useState<Array<TBank>>([]);
  const [filter, setFilter] = useState<{ [key: string]: any }>({});
  const [openNewOrder, setOpenNewOrder] = useState(false);

  const pagination = usePagination(data);

  const handlePreview = async (file: any) => {
    const resp = await app.service.get(`documents/${file.id}/url`);
    const url = resp;
    const w = window.open(url, '_blank');
  };

  const getColumns = useCallback((smallSize = false) => {
    const columns: TableColumnsType<TPrepaidOrder> = [
      {
        title: 'Name',
        width: 150,
        dataIndex: 'account_name',
        render: (v, record) => (
          <>
            {record.account.user.name}
            <div className="text-gray">({record.account.user.name})</div>
          </>
        ),
        fixed: !smallSize ? 'left' : undefined,
      },
      {
        title: 'Order Number',
        dataIndex: 'order_number',
        width: 180,
        fixed: !smallSize ? 'left' : undefined,
      },
      {
        title: 'Bill Number',
        width: 180,
        dataIndex: 'bill_number',
        render: (v, record) => {
          const empty = v == null || v === '';
          return (
            <Paragraph
              editable={{
                icon: empty ? <PlusCircleOutlined /> : undefined,
                triggerType: [empty ? 'icon' : 'text'],
                onChange: (value) => {
                  updateBillNumber(record, value);
                },
              }}>
              {v}
            </Paragraph>
          );
        },
      },
      {
        title: 'Prepaid Amount',
        dataIndex: 'prepaid_amount',
        width: 120,
      },
      {
        title: 'Prepaid Currecny',
        dataIndex: 'prepaid_currency',
        width: 120,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 80,
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        width: 80,
      },
      {
        title: 'Exchange rate',
        dataIndex: 'exchange_rate',
        width: 150,
      },
      {
        title: 'Exchange Rate Cost',
        dataIndex: 'exchange_rate_cost',
        width: 150,
      },
      {
        title: 'Bank Name',
        dataIndex: 'bank_name',
        width: 100,
        render: (v: Array<string>, record) => record.bank.name,
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        width: 100,
      },
      {
        title: 'Status',
        dataIndex: 'status_name',
        width: 80,
      },
      {
        title: 'ScreenShot',
        dataIndex: 'uploads',
        width: 150,
        render: (v, record) => {
          return (
            <>
              {record.documents.map((doc: any) => (
                <Link key={doc.id} onClick={() => handlePreview(doc)}>
                  {doc.file_name}
                </Link>
              ))}
            </>
          );
        },
      },
      {
        title: 'Remark',
        dataIndex: ['data', 'remark'],
        width: 200,
        render: (v, record) => {
          const empty = typeof v === 'undefined' || v === '';
          return (
            <Paragraph
              style={{
                padding: 0,
                margin: 0,
              }}
              editable={{
                icon: empty ? <PlusCircleOutlined /> : undefined,
                triggerType: [empty ? 'icon' : 'text'],
                onChange: (value) => {
                  updateRemark(record, value);
                },
              }}>
              {v}
            </Paragraph>
          );
        },
      },
      {
        title: 'Audit time',
        width: 100,
        render: (record: TPrepaidOrder) => {
          const key =
            record.status == 1
              ? 'approve'
              : record.status == -1
                ? 'rejected'
                : null;
          return key ? record.data[key].datetime : '';
        },
      },
      {
        title: 'Auditor',
        width: 80,
        render: (record: TPrepaidOrder) => {
          const key =
            record.status == 1
              ? 'approve'
              : record.status == -1
                ? 'rejected'
                : null;
          return key ? record.data[key].nickname : '';
        },
      },
      {
        title: 'Last Update Time',
        width: 120,
        dataIndex: 'updated_at',
        render: (v, record) => {
          return record.created_at !== v ? v : '';
        },
      },
      {
        title: 'Actions',
        width: 180,
        fixed: 'right',
        render: (record: TPrepaidOrder) => {
          return (
            <span className="space">
              {record.status === 0 && (
                <>
                  <TopUpPreChargeButton
                    order={record}
                    onSubmit={() => orderChange(record, 1)}
                  />
                  <TopUpRejectButton
                    order={record}
                    onSubmit={() => orderChange(record, -1)}
                  />
                </>
              )}
            </span>
          );
        },
      },
    ];
    return columns;
  }, []);

  const getData = useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);
      try {
        const result = await app.service.get('prepaidOrders', {
          params: {
            ...filter,
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });
        setData(result);
      } catch (e: any) {
        message.error(e.toString() || 'Failed to retrieve data.');
      }
      setLoading(false);
    },
    [filter],
  );

  const getBanks = useCallback(async () => {
    try {
      const resp = await app.service.get('prepaidOrders/bankAccounts');
      setBanks(resp.data);
    } catch (e: any) {
      message.error(e.toString() || 'Failed to retrieve bank account data');
    }
  }, []);

  const download = useCallback(() => {
    const downloadUrl = buildUrl('prepaidOrders/export', {
      account: filter.account || '',
      start_date: filter.start_date || '',
      end_date: filter.end_date || '',
    });
    globalThis.window.open(downloadUrl);
  }, [filter]);

  const orderChange = useCallback(
    async (
      order: TPrepaidOrder,
      status: TPrepaidOrder['status'],
      values?: { [key: string]: any },
    ) => {
      try {
        const result = await app.service.put(
          `prepaidOrders/${order.id}/status`,
          {
            data: { status, values },
          },
        );
        if (result) {
          getData(pagination);
        }
      } catch (e: any) {
        message.error(e.toString() || '更新充值状态失败');
      }
    },
    [pagination],
  );

  // 编辑水单号
  const updateBillNumber = useCallback(
    async (order: TPrepaidOrder, billNumber: string) => {
      try {
        if (billNumber == order.bill_number) {
          return;
        }

        const result = await app.service.put(`prepaidOrders/${order.id}`, {
          data: { bill_number: billNumber },
        });
        if (result) {
          setData((data: any) => {
            if (!data) {
              return;
            }
            const newData = { ...data };

            const item = find(newData.data, { id: order.id });
            if (!item) {
              return;
            }
            item.bill_number = billNumber;
            return { ...newData };
          });
        }
      } catch (e: any) {
        message.error(e.toString() || 'failed');
      }
    },
    [],
  );

  const updateRemark = useCallback(
    async (order: TPrepaidOrder, remark: string) => {
      try {
        const result = await app.service.put(`prepaidOrders/${order.id}`, {
          data: { remark },
        });
        if (result) {
          setData((data: any) => {
            if (!data) {
              return;
            }
            const newData = { ...data };
            const item = find(newData.data, { id: order.id });
            if (!item) {
              return;
            }
            item.data.remark = remark;

            return { ...newData };
          });
        }
      } catch (e: any) {
        message.error(e.toString() || 'failed');
      }
    },
    [],
  );

  useEffect(() => {
    getData();
    getBanks();
  }, []);

  // 如果 filter 变更且需要 reload
  useEffect(() => {
    if (filter.reload) {
      getData();
    }
  }, [filter]);

  return (
    <div className={styles.main}>
      <Header
        title="Prepay Orders"
        rightElement={
          <div>
            <Button
              type="primary"
              icon={<DollarOutlined />}
              onClick={() => setOpenNewOrder(true)}>
              Prepay
            </Button>
          </div>
        }></Header>
      <div className={styles.filter}>
        <Row style={{ flex: 1 }} gutter={[16, 8]}>
          <Col span={4}>
            <Input.TextArea
              rows={3}
              allowClear
              autoSize={{ minRows: 3, maxRows: 4 }}
              placeholder="Enter order numbers or bill numbers, please separate multiple numbers with line breaks..."
              value={filter.search_keyword}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  reload: false,
                  search_keyword: e.target.value,
                })
              }
            />
          </Col>
          <Col span={20}>
            <div className="space">
              <span className="text-right" style={{ width: 100 }}>
                Bank Account
              </span>
              <span>
                <Select
                  style={{ width: 150 }}
                  allowClear
                  placeholder="Select Bank..."
                  value={filter.bank}
                  onChange={(value) =>
                    setFilter({ ...filter, reload: false, bank: value })
                  }
                  options={banks.map((v) => ({ label: v.name, value: v.id }))}
                />
              </span>
              <span className="text-right" style={{ width: 100 }}>
                Payment Date
              </span>
              <span>
                <AntDRangePicker
                  style={{ width: 230 }}
                  value={
                    filter.start_date && filter.end_date
                      ? [moment(filter.start_date), moment(filter.end_date)]
                      : undefined
                  }
                  onChange={(value) =>
                    setFilter({
                      ...filter,
                      reload: false,
                      start_date: value ? value[0]?.format('YYYY-MM-DD') : null,
                      end_date: value ? value[1]?.format('YYYY-MM-DD') : null,
                    })
                  }
                />
              </span>
            </div>
            <div className="space mt-md">
              <span className="text-right" style={{ width: 100 }}>
                User
              </span>
              <span>
                <div style={{ width: 150 }}>
                  <UserSyncSelect
                    style={{ width: 150 }}
                    onSelect={(value) =>
                      setFilter({ ...filter, reload: false, account: value })
                    }
                    type="user"
                  />
                </div>
              </span>
              <span className="text-right" style={{ width: 100 }}>
                Status
              </span>
              <span>
                <Select
                  style={{ width: 150 }}
                  allowClear
                  placeholder="Status.."
                  value={filter.status}
                  onChange={(value) =>
                    setFilter({ ...filter, reload: false, status: value })
                  }
                  options={map(statusOptions, (label, value) => ({
                    label,
                    value,
                  }))}
                />
              </span>
              <span className="space ml-xl">
                <Button type="primary" onClick={() => getData()}>
                  Search
                </Button>
                <Button
                  type="default"
                  onClick={() => setFilter({ reload: true })}>
                  Reset
                </Button>
              </span>
            </div>
          </Col>
        </Row>
      </div>

      <AutoResizeTable
        bordered
        size="small"
        rowKey={(record) => `${record.id}-${record.status}`}
        columns={getColumns()}
        pagination={pagination}
        onChange={(pagination) => {
          getData(pagination);
        }}
        loading={loading}
        dataSource={data?.data || []}
        scroll={{
          x: 2400,
        }}
      />

      {openNewOrder && (
        <PrepayOrderDetail
          accounts={accounts}
          banks={banks}
          onClose={(reload) => {
            setOpenNewOrder(false);
            if (reload) {
              getData();
            }
          }}
        />
      )}
    </div>
  );
};

export default TopUp;
