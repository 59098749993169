import AutoResizeTable from '@/components/AutoResizeTable';
import { charCompare } from '@/utils/charCompare';
import { Button, Switch, Tooltip, TableProps, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TOrder } from '..';
import { AccountingModal } from '@/pages/orders/components/AccountingModal';
import { VList } from 'virtuallist-antd';
import { useMemo } from 'react';

interface Props extends TableProps<TOrder> {
  handleSettledChange: (id: number[], checked: boolean) => any;
  handlExceptionChange: (id: number[], checked: boolean) => any;
  [k: string]: any;
}

const Index = ({
  dataSource,
  rowSelection,
  handleSettledChange,
  handlExceptionChange,
  ...rest
}: Props) => {
  const columns: ColumnsType<TOrder> = [
    {
      title: 'Order#',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 100,
    },
    {
      title: 'MBL#',
      dataIndex: 'mbl_number',
      width: 180,
    },
    {
      title: 'Customer',
      dataIndex: 'user',
      render: (_, record) => (
        <Tooltip
          placement="topLeft"
          title={
            <div>
              {(record?.user?.company?.name || record?.user?._company) ?? ''}
            </div>
          }
          arrowPointAtCenter>
          {record?.user?.name ?? ''}
        </Tooltip>
      ),
      sorter: (a, b) => charCompare(a?.user?.name ?? '', b?.user?.name ?? ''),
    },
    {
      title: 'Status',
      dataIndex: 'state',
    },
    {
      title: 'Status Changed At',
      dataIndex: 'state_changed_at',
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      render: (_, record) => record?.operator?.name ?? '',
      sorter: (a, b) =>
        charCompare(a?.operator?.name ?? '', b?.operator?.name ?? ''),
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      render: (_, record) => record?.sales?.name ?? '',
      sorter: (a, b) => charCompare(a?.sales?.name ?? '', b?.sales?.name ?? ''),
    },
    {
      title: 'POD ETA',
      dataIndex: 'port_of_discharge_eta',
      sorter: (a, b) =>
        new Date(a.port_of_discharge_eta).getTime() -
        new Date(b.port_of_discharge_eta).getTime(),
    },
    {
      title: 'Container Count',
      dataIndex: 'containers_count',
    },
    {
      title: 'Profit Category',
      dataIndex: 'profit_category',
    },
    {
      title: 'Total AR',
      dataIndex: 'ar_amount_total',
      sorter: (a, b) => Number(a.ar_amount_total) - Number(b.ar_amount_total),
    },
    {
      title: 'Total AP',
      dataIndex: 'ap_amount_total',
      sorter: (a, b) => Number(a.ap_amount_total) - Number(b.ap_amount_total),
    },
    {
      title: 'Total Profit',
      dataIndex: 'profit_amount_total',
      sorter: (a, b) =>
        Number(a.profit_amount_total) - Number(b.profit_amount_total),
    },
    {
      title: 'Settled',
      dataIndex: 'commission_settled_at',
      width: 80,
      sorter: (a, b) =>
        new Date(a.commission_settled_at).getTime() -
        new Date(b.commission_settled_at).getTime(),
      render: (text, { id }) => (
        <Tooltip title={text}>
          <Switch
            checked={text !== null}
            onChange={(checked) => handleSettledChange([id], checked)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Exception',
      dataIndex: 'exception_at',
      width: 80,
      sorter: (a, b) =>
        new Date(a.exception_at).getTime() - new Date(b.exception_at).getTime(),
      render: (text, { id }) => (
        <Tooltip title={text}>
          <Switch
            checked={text !== null}
            onChange={(checked) => handlExceptionChange([id], checked)}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Create Time',
      dataIndex: 'created_at',
      sorter: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
      render: (_, record) => (
        <Button type="link" size="small">
          <div className="text-gray">
            <AccountingModal orderId={record.id} />
          </div>
        </Button>
      ),
    },
  ];

  const vc1 = useMemo(() => {
    return VList({
      height: 500,
      vid: '1',
      resetTopWhenDataChange: false,
    });
  }, []);

  return (
    <AutoResizeTable
      {...rest}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      size="small"
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      components={vc1}
    />
  );
};

export default Index;
