import { UserSyncSelect } from '@/components/UserSyncSelect';
import { TAccount } from '@/types';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import { find } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

interface IPrepayOrderDetailProps {
  accounts: Array<TAccount>;
  banks: Array<any>;
  onClose: (reload?: boolean) => void;
}

const PrepayOrderDetail: FC<IPrepayOrderDetailProps> = ({ banks, onClose }) => {
  const app = useApp();

  const refForm = useRef<FormInstance>(null);

  const [submitting, setSubmitting] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [bank, setBank] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const [fileList, setFileList] = useState<any[]>([]);

  const [convertResult, setConvertResult] = useState<{
    rate: number;
    cost: number;
    amount: number;
  }>();

  const close = useCallback(() => {
    if (!wasChanged) {
      return onClose();
    }
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure you want to cancel the edited content?',
      onOk: () => onClose(),
      maskClosable: true,
    });
  }, [wasChanged]);

  const bankInfo = useMemo(() => {
    const info = find(banks, { id: bank });
    return info || null;
  }, [bank]);

  const handleUpload = (info: any) => {
    let fileList: any[] = [...info.fileList];

    fileList = fileList.slice(-1);

    setFileList(fileList);
    refForm.current?.setFieldValue('document', info.file);
  };

  const onFinish = useCallback(async (values: any) => {
    const formData = new FormData();
    formData.append('documents[0]', values['document']);
    formData.append('user_id', values['user_id']);
    formData.append('amount', values['amount']);
    formData.append('bank', values['bank']);

    try {
      const result = await app.service.post('prepaidOrders', {
        data: formData,
      });
      if (result) {
        onClose(true);
        return;
      }
    } catch (e: any) {
      message.error(e.toString() || 'Failed');
    }
    setSubmitting(false);
  }, []);

  useEffect(() => {
    if (bankInfo && bankInfo.currency !== 'USD') {
      if (!amount || amount <= 0) {
        setConvertResult(undefined);
        return;
      }
      (async () => {
        try {
          const exchangeRate = await app.service.get(
            `prepaidOrders/exchange-rate?from=${bankInfo.currency}&to=USD`,
          );
          const cost = parseFloat(
            `${bankInfo.custom_properties?.exchange_rate_cost || 0}`,
          );
          const result = amount / (exchangeRate + cost);
          setConvertResult({
            rate: exchangeRate,
            cost,
            amount: result,
          });
        } catch (e: any) {
          message.error(
            e.toString() || 'Failed to obtain real-time exchange rate.',
          );
        }
      })();
    } else {
      setConvertResult(undefined);
      return;
    }
  }, [bankInfo, amount]);

  return (
    <Drawer width={700} visible={true} closable={false} onClose={close}>
      <h2>Prepay</h2>
      <Divider />
      <Form
        ref={refForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        onChange={() => setWasChanged(true)}
        onFinish={onFinish}
        initialValues={{ payment: 'offline' }}
        autoComplete="off"
      >
        <Form.Item label="Customer" name="user_id">
          <UserSyncSelect type="user" />
        </Form.Item>
        <Form.Item
          name="bank"
          label="Payment method"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select Bank Account"
            options={banks.map((v) => ({ label: v.name, value: v.id }))}
            onChange={(v) => setBank(v)}
          />
        </Form.Item>
        <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
          <InputNumber
            min={0}
            style={{ width: 150 }}
            prefix={bankInfo?.currency}
            onChange={(value: any) => setAmount(value)}
          />
        </Form.Item>
        {bankInfo && bankInfo.currency !== 'USD' && (
          <Row>
            <Col span={10}>
              <Form.Item
                label="Currency"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Input
                  readOnly
                  style={{ width: 150 }}
                  prefix="USD"
                  value={
                    convertResult
                      ? (((convertResult.amount * 100) << 0) / 100).toFixed(2)
                      : ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Exchange Rate: "
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <span className="ant-form-text">
                  {' '}
                  {convertResult ? convertResult.rate.toFixed(4) : ''}
                </span>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Exchange Loss: "
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <span className="ant-form-text">
                  {' '}
                  {convertResult ? convertResult.cost.toFixed(2) : ''}
                </span>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item
          name="document"
          label="Screenshot"
          rules={[{ required: true }]}
          valuePropName="document"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e.fileList;
          }}
        >
          <Dragger
            beforeUpload={() => false}
            name="image"
            listType="picture"
            fileList={fileList}
            onChange={handleUpload}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
        </Form.Item>
        <Divider />
        <Row>
          <Col offset={4} className="space">
            <Button type="default" onClick={close}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={submitting}
              onClick={() => refForm.current?.submit()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default PrepayOrderDetail;
