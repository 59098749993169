import { useApp } from '@/utils/useapp';
import { Button, Modal, Table, TableColumnsType } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import classnames from 'classnames';
import { ButtonType } from 'antd/lib/button';

interface TTransactionLog {
  op: string;
  prev_prepaid_amount: number;
  prev_balance_amount: number;
  prev_order_amount: number;
  prev_hold_amount: number;
  prev_settled_amount: number;
  current_prepaid_amount: number;
  current_balance_amount: number;
  current_order_amount: number;
  current_hold_amount: number;
  current_settled_amount: number;
}

const renderDiff = (v1: number, v2: number) => {
  const diff = v1 - v2;
  return diff ? (
    <>
      {v1.toFixed(2)}
      <span
        className={classnames(
          'ml-sm',
          diff > 0 ? 'text-success' : 'text-danger',
        )}>
        ({diff > 0 ? '+' : '-'}
        {Math.abs(diff).toFixed(2)})
      </span>
    </>
  ) : (
    v1.toFixed(2)
  );
};

export const TransactionLog: React.FC<{
  order_id?: number;
  account_id?: number;
  container_id?: number;
  btnType?: ButtonType;
}> = ({ order_id, account_id, container_id, btnType = 'link' }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  // const [search, setSearch] = useState<string | undefined>();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      app.service
        .get('userAccounts/transactionLogs', {
          params: { order_id, account_id, container_id },
        })
        .then((resp) => {
          setData(resp.data);
        });
    }
  }, [isModalVisible]);

  const filterdData = useMemo(() => {
    return data;
  }, [data]);

  const columns = useMemo(() => {
    const columns: TableColumnsType<TTransactionLog> = [
      {
        title: 'Time',
        dataIndex: 'created_at',
        width: 150,
      },
      {
        title: 'Operation',
        dataIndex: 'op',
      },
      {
        title: 'Order',
        dataIndex: 'order_uid',
      },
      {
        title: 'Container',
        dataIndex: 'cntr_number',
      },
      {
        title: 'LTL',
        dataIndex: 'ltl_shipment_id',
      },
      {
        title: 'FTL',
        dataIndex: 'ftl_shipment_id',
      },
      {
        title: 'Balance',
        width: 150,
        render: (record: TTransactionLog) =>
          renderDiff(
            +record.current_balance_amount,
            +record.prev_balance_amount,
          ),
      },
      {
        title: 'Order Amount',
        dataIndex: 'current_order_amount',
        width: 150,
        render: (v, record) => renderDiff(+v, +record.prev_order_amount),
      },
      {
        title: 'Settled',
        dataIndex: 'current_settled_amount',
        width: 150,
        render: (v, record) => renderDiff(+v, +record.prev_settled_amount),
      },
    ];
    return columns;
  }, []);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value: inputValue } = e.target;
  //   setSearch(inputValue);
  // };

  return (
    <>
      <Button type={btnType} onClick={showModal}>
        Logs
      </Button>
      <Modal
        cancelText="Close"
        footer={false}
        width={1300}
        title="Transation Logs"
        open={isModalVisible}
        onCancel={handleCancel}>
        {/* <Input onChange={handleChange} placeholder="Search" />
        <br /> */}
        <Table
          scroll={{
            y: 700,
          }}
          sticky
          pagination={false}
          rowKey="id"
          size="small"
          columns={columns}
          dataSource={filterdData}
        />
      </Modal>
    </>
  );
};
