const CUSTOMER = 'user_id';
const OPERATOR = 'operator_id';
const SALES = 'sales_id';
const BUSINESS_STATUS = 'business_status';
const VENDOR = 'vendor_id';
const CARRIER = 'carrier';

export default {
  CUSTOMER,
  OPERATOR,
  SALES,
  BUSINESS_STATUS,
  VENDOR,
  CARRIER,
} as const;
