import { useApp } from '@/utils/useapp';
import {
  Space,
  Button,
  message,
  Spin,
  Input,
  Tooltip,
  MenuProps,
  Dropdown,
  Row,
  Col,
} from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import styles from 'res/css/ui.scss';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import ShipmentDrawer from '../components/ShipmentDrawer';
import { HiRefresh } from 'react-icons/hi';
import { ShipmentBusinessStatus, ShipmentCustomerStatus } from '../constants';
import DraggableTabs from '@/components/DraggbleTabs';
import { FaPlus } from 'react-icons/fa';
import { TDispatchTab } from '@/pages/dispatchs';
import { EditableText } from '@/components/Editable';
import { useHistory, useLocation } from 'umi';
import { convertFilterValuesToQueryParams } from '@/pages/dispatchs/components/Filter';
import { Filter } from '../components/Filter';
import update from 'immutability-helper';
import { ManageColumns } from '@/pages/dispatchs/components/ManageColumns';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { ShipmentInterface } from './Interfaces/ShipmentInterface';
import { CACHE_LTL_COLOR_TAG_KEY } from '@/stores/cache';
import ColorTagSelect from '@/components/ColorTagSelect';
import ShipmentsTable from './ShipmentTable';

export const CUSTOMER_STATUS_OPTIONS = [
  { value: ShipmentCustomerStatus.CONFIRMING, label: 'Confirming' },
  { value: ShipmentCustomerStatus.PENDING_PICKUP, label: 'Pending Pickup' },
  {
    value: ShipmentCustomerStatus.IN_TRANSIT,
    label: 'In Transit',
  },
  {
    value: ShipmentCustomerStatus.DELIVERED,
    label: 'Delivered',
  },
  {
    value: ShipmentCustomerStatus.COMPLETED,
    label: 'Completed',
  },
  {
    value: ShipmentCustomerStatus.CANCELED,
    label: 'Canceled',
  },
];

export const BUSINESS_STATUS_OPTIONS = [
  { value: ShipmentBusinessStatus.CONFIRMING, label: 'Confirming' },
  { value: ShipmentBusinessStatus.PENDING_PICKUP, label: 'Pending Pickup' },
  {
    value: ShipmentBusinessStatus.IN_TRANSIT,
    label: 'In Transit',
  },
  {
    value: ShipmentBusinessStatus.DELIVERED,
    label: 'Delivered',
  },
  {
    value: ShipmentBusinessStatus.OP_CLOSE,
    label: 'OP Closed',
  },
  {
    value: ShipmentBusinessStatus.CANCELED,
    label: 'Canceled',
  },
];

const Shipments: React.FC = () => {
  const app = useApp();
  useEffect(() => {
    app.store.cache.fetch(CACHE_LTL_COLOR_TAG_KEY, true);
  }, []);
  const [data, setData] = useState<TCollection<ShipmentInterface>>();
  const [shipformVisible, setShipformVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<ShipmentInterface>();
  const [openId, setOpenId] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeKey, setActiveKey] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [tabs, setTabs] = useState<TDispatchTab[]>([]);
  const [order, setOrder] = useState<string[]>([]);
  const [editTab, setEditTab] = useState<number>(0);
  const [tabCounts, setTabCounts] = useState<any>({});
  const [filterValues, setFilterValues] = useState<any[] | null>(null);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);

  const location = useLocation();
  const pagination = usePagination(data);

  const fetchData = useCallback(
    async (
      _pagination?: TablePaginationConfig,
      _filters?: any,
      isEmptyQuery?: boolean,
      sorter?:
        | SorterResult<ShipmentInterface>
        | SorterResult<ShipmentInterface>[],
    ) => {
      setLoading(true);

      _filters = _filters || filterValues || [];
      const sort = _filters?.find((f: any) => f.attribute === 'sort') || null;
      const params =
        searchQuery && !isEmptyQuery
          ? {
              query: searchQuery,
              ...convertFilterValuesToQueryParams([sort || {}]),
            }
          : convertFilterValuesToQueryParams(_filters);

      try {
        const resp = await app.service.get('tl/ltl/shipments', {
          params: {
            ...params,
            page: _pagination ? _pagination.current : pagination.current,
            per_page: _pagination ? _pagination.pageSize : pagination.pageSize,
            sort_by: Array.isArray(sorter)
              ? undefined
              : sorter?.columnKey || sorter?.field,
            sort_value: Array.isArray(sorter)
              ? undefined
              : sorter?.order === 'ascend'
              ? 'asc'
              : sorter?.order === 'descend'
              ? 'desc'
              : undefined,
          },
        });
        setData(resp);
      } catch (err: any) {
        message.error(err?.data?.message);
      } finally {
        setLoading(false);
      }
    },
    [filterValues, searchQuery, pagination],
  );

  const refreshLtlShipment = useCallback(
    async (id: number) => {
      if (!data || !id) {
        return;
      }
      const _shipment = await app.service.get(`tl/ltl/shipments/${id}`);

      const collection = data.data.map((item) => {
        if (item.id === _shipment.data.id) {
          return _shipment.data;
        }
        return item;
      });
      setData({ ...data, data: collection });
    },
    [data],
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ShipmentInterface> | SorterResult<ShipmentInterface>[],
  ) => {
    fetchData(pagination, null, false, sorter);
  };

  const handleShow = (m: any) => {
    setModel(m);
    setShipformVisible(true);
  };

  const handlePatchUpdate = useCallback(
    async (shipmentId: number, model: string, values: any) => {
      setLoading(true);
      try {
        await app.service.patch(`tl/ltl/${model}/${shipmentId}`, {
          data: values,
        });

        fetchData();
      } catch (err: any) {
        message.error(err?.data?.message);
      } finally {
        setLoading(false);
      }
    },
    [fetchData],
  );

  const handleNewLTLShipment = () => {
    console.log('new ltl shipment');
    setModel(undefined);
    setShipformVisible(true);
  };

  const checkedColumns = useMemo(() => {
    const tab = tabs.find((tab) => tab.id === activeKey);
    return tab?.columns || defaultColumns?.map((c: any) => c.key);
  }, [activeKey, tabs]);

  const history = useHistory();

  const handleTabChange = (key: string) => {
    setSearchQuery('');
    setActiveKey(+key);
    setSelectedRows([]);
    if (pagination) {
      pagination.current = 1;
    }
  };

  const handleTabMoved = (order: any[] = []) => {
    app.service.post('dispatchTabs/tabsOrder', {
      data: { order, mode: 3 },
    });
    setOrder(order);
  };

  const handleAddTab = async () => {
    setLoading(true);
    try {
      const reps = await app.service.post('dispatchTabs', {
        data: { name: `Tab ${tabs.length}`, mode: 3 },
      });
      setTabs([...tabs, { ...reps.data, filters: [] }]);
      setActiveKey(reps.data.id);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const _tabs = useMemo(() => {
    if (!order || order.length == 0) {
      return tabs;
    }
    const _order = order.map((i: string) => +i);
    const _tabs = [...tabs].sort((a, b) => {
      const orderA = _order.indexOf(a.id);
      const orderB = _order.indexOf(b.id);

      if (orderA !== -1 && orderB !== -1) {
        return orderA - orderB;
      }
      if (orderA !== -1) {
        return -1;
      }
      if (orderB !== -1) {
        return 1;
      }

      const ia = tabs.indexOf(a);
      const ib = tabs.indexOf(b);

      return ia - ib;
    });
    return _tabs;
  }, [order, tabs]);

  const handleDeleteTab = async (id: number) => {
    if (tabs.length == 1) {
      return;
    }

    setLoading(true);
    try {
      await app.service.delete(`dispatchTabs/${id}`);
      setTabs(tabs.filter((t) => t.id != id));
      message.success('Tab has been delete');
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const tabMenu = (id: number): MenuProps['items'] => {
    const items: MenuProps['items'] = [
      {
        label: 'Edit',
        key: 'edit' + id,
        onClick: () => {
          setEditTab(id);
        },
      },
      {
        label: 'Delete',
        key: 'delete' + id,
        onClick: () => {
          handleDeleteTab(id);
        },
      },
    ];

    return items;
  };

  const handleSaveUpdateTab = async (id: number, data: any) => {
    if (!data?.name) {
      return;
    }
    const resp = await app.service.put(`dispatchTabs/${id}`, {
      data: data,
    });
    setTabs(tabs.map((t) => (t.id == id ? resp.data : t)));
    setFilterValues(resp.data.filters);
  };

  const handleUpdateTab = async (
    name: string,
    value: string | any[],
    id?: number,
  ) => {
    id = id || editTab;
    const tab = tabs.find((tab) => tab.id === id);
    if (tab) {
      try {
        await handleSaveUpdateTab(id, { ...tab, [name]: value });
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setEditTab(0);
      }
    }
  };

  const fetchTabs = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('dispatchTabs', {
        params: {
          mode: 3,
        },
      });
      setTabs(resp.data.tabs);
      setOrder(resp.data.order);
      handleTabChange(resp.data.order[0] || resp.data.tabs[0]?.id || 0);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryString = location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);

      const shipmentId: any = params.get('shipmentId') || 0;
      if (!shipmentId) {
        fetchTabs();
        return;
      }
      const _filter = [];
      if (shipmentId) {
        _filter.push({
          attribute: 'id',
          text: 'shipmentId ID: ' + shipmentId,
          value: shipmentId,
        });
      }

      fetchData(pagination, _filter);
      setFilterValues(_filter);
      setOpenId(shipmentId);
    } else {
      fetchTabs();
    }
  }, [location.search]);

  useEffect(() => {
    if (activeKey) {
      const filters = tabs.find((tab) => tab.id === activeKey)?.filters || [];
      if (location.search) {
        const params = new URLSearchParams(location.search);
        const open: any = params.get('open');
        if (!open) {
          history.replace(location.pathname);
        }
        setFilterValues([]);
      } else if (!location.search) {
        history.replace(location.pathname);
        setFilterValues(filters);
        fetchData(pagination, filters);
      }
    }
  }, [activeKey]);

  const handleSearch = (value: any) => {
    fetchData(
      {
        current: 1,
        pageSize: pagination?.pageSize || 20,
      },
      filterValues,
      value ? false : true,
    );
  };

  const updateTab = useCallback(
    async (filters: any, pagination: any, isEmptyQuery?: boolean) => {
      setLoading(true);
      const tab = tabs.find((tab) => tab.id === activeKey);
      try {
        await handleSaveUpdateTab(activeKey, {
          ...tab,
          filters,
          mode: 3,
        });

        fetchData(pagination, filters, isEmptyQuery);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setLoading(false);
      }
    },
    [filterValues],
  );

  const handleRemoveAllFilters = () => {
    setFilterValues([]);
    updateTab([], pagination);
  };

  const handleRemoveFilter = useCallback(
    (index: number) => {
      updateTab(update(filterValues, { $splice: [[index, 1]] }), pagination);
    },
    [filterValues, pagination],
  );

  const columns: ColumnsType<any> = useMemo(() => {
    // const sort = filterValues?.find((f) => f.attribute === 'sort') || null;
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      // sortOrder: sortOrder(sort, c.key),
    }));

    const tab = tabs.find((t) => t.id === activeKey);
    const _defaultColumns = _columns?.map((c) => c.key);
    return _columns.filter((c) =>
      (tab?.columns || _defaultColumns)?.includes(c.key),
    );
  }, [filterValues, tabs, activeKey, pagination, defaultColumns]);

  return (
    <div className={styles.main}>
      <Header
        title="LTL Shipment"
        rightElement={
          <Space align="end" direction="horizontal">
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => fetchData()}
              shape="circle"
            >
              <HiRefresh size={18} color="#1890ff" />
            </Button>
            <Input.Search
              // style={{ width: '40%' }}
              placeholder="Search by LTL ID/Pro Number#/Reference#"
              allowClear
              disabled={loading}
              enterButton="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onSearch={(value) => handleSearch(value)}
            />
            <Button type="primary" onClick={handleNewLTLShipment}>
              New LTL Shipment
            </Button>
          </Space>
        }
      />
      <Spin spinning={loading}>
        <div className="pl-sm pr-sm">
          <DraggableTabs
            onChange={handleTabChange}
            onTabMoved={handleTabMoved}
            activeKey={'' + activeKey}
            tabBarExtraContent={
              <Space>
                <Tooltip title=" New Tab">
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    shape="circle"
                    onClick={handleAddTab}
                  >
                    <FaPlus size={18} color="#1890ff" />
                  </Button>
                </Tooltip>
              </Space>
            }
            items={_tabs.map((tab: TDispatchTab) => {
              return {
                label:
                  editTab !== tab.id ? (
                    <Dropdown
                      menu={{ items: tabMenu(tab.id) }}
                      trigger={['contextMenu']}
                    >
                      <div>
                        {tab.name}
                        {tabCounts[tab.id] == undefined ? (
                          ''
                        ) : (
                          <span
                            style={{
                              background:
                                activeKey == tab.id ? '#F0F9FF' : '#F0F0F0',
                              borderRadius: '100px',
                              color:
                                activeKey == tab.id ? '#2F6EC0' : '#8C8C8C',
                              padding: '0px 8px',
                              fontWeight: '400',
                            }}
                          >
                            {tabCounts[tab.id]}
                          </span>
                        )}
                      </div>
                    </Dropdown>
                  ) : (
                    <EditableText
                      value={tab.name}
                      onChange={handleUpdateTab}
                      onHide={() => setEditTab(0)}
                      name="name"
                      autoFocus
                    />
                  ),
                key: '' + tab.id,
              };
            })}
          ></DraggableTabs>
        </div>
        {_tabs.length > 0 && (
          <Row>
            <Col span={16}>
              <Filter
                show={isShowFilter}
                values={filterValues || []}
                onChange={(v: any) => {
                  setSearchQuery('');
                  updateTab(
                    v,
                    { current: 1, pageSize: pagination?.pageSize || 20 },
                    true,
                  );
                }}
                removeAll={handleRemoveAllFilters}
                removeOne={handleRemoveFilter}
                activeFilters={
                  tabs.find((tab) => tab.id === activeKey)?.filters || []
                }
                hideFilter={() => setIsShowFilter(false)}
                loading={loading}
              />
            </Col>
            <Col
              span={8}
              style={{
                marginTop: isShowFilter ? '48px' : '0',
                marginBottom: isShowFilter ? '0' : '20px',
              }}
            >
              <Space>
                <Button
                  onClick={() => {
                    setIsShowFilter((isShowFilter) => !isShowFilter);
                  }}
                >
                  {isShowFilter ? 'Hide Filters' : 'Show Filters'}
                </Button>
                <div>
                  <ManageColumns
                    items={defaultColumns?.map((c: any) => {
                      return {
                        key: c.key,
                        name: c.title instanceof Function ? c.label : c.title,
                      };
                    })}
                    checkedColumns={checkedColumns}
                    onChange={(list) =>
                      handleUpdateTab('columns', list, activeKey)
                    }
                  />
                </div>
                <ColorTagSelect
                  model={'App\\Domains\\TL\\Models\\LTLShipment'}
                />
              </Space>
            </Col>
          </Row>
        )}
        <div>
          <ShipmentsTable
            data={data}
            pagination={pagination}
            fetchData={fetchData}
            columns={columns}
            handleTableChange={handleTableChange}
            handleShow={handleShow}
            handlePatchUpdate={handlePatchUpdate}
            refreshLtlShipment={refreshLtlShipment}
            setDefaultColumns={setDefaultColumns}
            openId={openId}
            // loading={loading}
          />
        </div>
      </Spin>

      <ShipmentDrawer
        visible={shipformVisible}
        // setVisible={setShipformVisible}
        model={model}
        onSaved={(id: number) => {
          if (id) {
            refreshLtlShipment(id);
          } else {
            fetchData(pagination);
          }
          setShipformVisible(false);
        }}
        onClose={() => {
          // fetchData();
          setShipformVisible(false);
        }}
      />
    </div>
  );
};

export default Shipments;
