import { ReactNode } from 'react';
import {
  DashboardOutlined,
  BarChartOutlined,
  TeamOutlined,
  TrademarkOutlined,
  ProfileOutlined,
  CarOutlined,
  ContainerOutlined,
  LineChartOutlined,
  CreditCardOutlined,
  UnorderedListOutlined,
  RadarChartOutlined,
  TableOutlined,
} from '@ant-design/icons';

export type TMenuItem = {
  title: string;
  url?: string | RegExp;
  icon?: ReactNode;
  permissions?: Array<string> | string;
  items?: Array<TMenuItem>;
};

export const NAVBAR = ['Dray', 'LTL', 'FTL', 'Accounting'];

export default {
  Dray: [
    {
      title: 'Dashboard',
      url: '/',
      icon: <DashboardOutlined />,
    },
    {
      title: 'Rate',
      url: '/rates',
      icon: <TrademarkOutlined />,
      permissions: ['read.rate'],
      items: [
        {
          title: 'Buy Rate',
          url: '/rates/buyRates',
        },
        {
          title: 'Sell Rate',
          url: '/rates/sellRates',
        },
        // {
        //   title: 'Spot Rate',
        //   url: '/rates/spotRate',
        //   permissions: ['read.spotRate'],
        // },
        {
          title: 'Rate Request',
          url: '/rates/requestRates',
          permissions: ['read.requestedRate'],
        },
        {
          title: 'Bid Request',
          url: '/rates/bidRequests',
        },
        {
          title: 'Bulk Quote',
          url: '/rates/quotes',
        },
        {
          title: 'Route Analysis',
          url: '/engine/routes',
        },
      ],
    },
    {
      title: 'Order',
      url: '/orders',
      icon: <ProfileOutlined />,
      permissions: ['read.order'],
    },
    // {
    //   title: 'Pre-Order',
    //   url: '/preOrders',
    //   icon: <ProfileOutlined />,
    //   permissions: ['read.order'],
    // },
    {
      title: 'Dispatch',
      url: '/dispatchs',
      icon: <CarOutlined />,
      permissions: ['read.container'],
    },
    {
      title: 'Dashboard',
      icon: <TableOutlined />,
      permissions: ['read.container'],
      items: [
        {
          title: 'OP Dashboard',
          url: '/opDashboard',
          permissions: ['read.container'],
        },
        {
          title: 'Dispatch Dashboard',
          url: '/dispatchDashboard',
          permissions: ['read.container'],
        },
        {
          title: 'CS Dashboard',
          url: '/csDashboard',
          permissions: ['read.container'],
        },
      ],
    },
    {
      title: 'Tracking',
      url: '/containers',
      icon: <ContainerOutlined />,
      permissions: ['read.container'],
    },
    {
      title: 'Vendor',
      url: '/entity/vendors',
      icon: <TeamOutlined />,
      permissions: ['read.vendor'],
    },
    {
      title: 'VendorV2',
      url: '/entity/vendorsV2',
      icon: <TeamOutlined />,
      permissions: ['read.vendor'],
    },
    {
      title: 'Analysis',
      icon: <LineChartOutlined />,
      permissions: ['manage.analysis', 'manage.all'],
      items: [
        {
          title: 'Profit',
          url: '/analysis/profit',
          permissions: ['manage.analysis'],
        },
        {
          title: 'Log Activity',
          url: '/analysis/log-activity',
          permissions: ['manage.logActivity'],
        },
        {
          title: 'Assignment',
          url: '/analysis/assignment',
          permissions: ['manage.all'],
        },
      ],
    },
    {
      title: 'Accounting',
      icon: <CreditCardOutlined />,
      permissions: ['read.container'],
      items: [
        {
          title: 'Container',
          url: '/accounting/containers',
        },
        {
          title: 'Invoice',
          url: '/accounting/drayage/invoices',
          permissions: ['read.orderInvoice'],
        },
        {
          title: 'Bill',
          url: '/accounting/drayage/bills',
          permissions: ['read.orderBill'],
        },
        {
          title: 'Profit Report',
          url: '/accounting/profit-report/customer-profit-report',
        },
      ],
    },

    {
      title: 'Entity',
      icon: <UnorderedListOutlined />,
      permissions: [
        'read.warehouse',
        'read.city',
        'read.oceanCarrier',
        'read.intermodalRegion',
        'read.terminal',
        'read.port',
        'read.facilityType',
        'read.cargoType',
        'read.containerType',
        'read.billingCode',
        'manage.accounting',
      ],
      items: [
        {
          title: 'Drop Zone Rate',
          url: '/entity/dropZoneRates',
          permissions: ['read.vendor'],
        },
        {
          title: 'Warehouse',
          url: '/entity/warehouses',
          permissions: ['read.warehouse'],
        },
        {
          title: 'Special Requirements',
          url: '/entity/specialRequirements',
          permissions: ['manage.admin'],
        },
        {
          title: 'Soc Return Location',
          url: '/entity/socReturnLocation',
          permissions: ['read.warehouse'],
        },
        {
          title: 'City',
          url: '/entity/cities',
          permissions: ['read.city'],
        },
        {
          title: 'Ocean Carrier',
          url: '/entity/oceanCarriers',
          permissions: ['read.oceanCarrier'],
        },
        {
          title: 'Intermodal Region',
          url: '/entity/intermodalRegions',
          permissions: ['read.intermodalRegion'],
        },
        {
          title: 'Terminal',
          url: '/entity/terminals',
          permissions: ['read.terminal'],
        },
        {
          title: 'Port',
          url: '/entity/ports',
          permissions: ['read.port'],
        },
        {
          title: 'Facility Type',
          url: '/entity/facilityTypes',
          permissions: ['read.facilityType'],
        },
        {
          title: 'Cargo Type',
          url: '/entity/cargoTypes',
          permissions: ['read.cargoType'],
        },
        {
          title: 'Container Type',
          url: '/entity/containerTypes',
          permissions: ['read.containerType'],
        },
        {
          title: 'Billing Code',
          url: '/entity/billingCodes',
          permissions: ['read.billingCode'],
        },
        {
          title: 'GL Code',
          url: '/entity/generalLedgerCodes',
          permissions: ['manage.accounting'],
        },
        {
          title: 'GoogleSheet',
          url: '/entity/googleSheets',
          permissions: ['read.googleSheet'],
        },
        {
          title: 'Vendor Division',
          url: '/entity/vendorDivisions',
          permissions: ['read.vendor'],
        },
        {
          title: 'Chassis Type',
          url: '/entity/chassisType',
          permissions: ['read.vendor'],
        },
        {
          title: 'Vendor Credit',
          url: '/entity/vendorCredits',
          permissions: ['read.vendor'],
        },
        {
          title: 'Vendor Insurance',
          url: '/entity/vendorInsurances',
          permissions: ['read.vendor'],
        },
        {
          title: 'PU Agent',
          url: '/entity/pickupNumberAgents',
          permissions: ['read.order'],
        },
        {
          title: 'Vendor Form W9',
          url: '/entity/vendorFormW9s',
          permissions: ['read.vendor'],
        },
        {
          title: 'Vendor Contracts',
          url: '/entity/vendorContracts',
          permissions: ['read.vendor'],
        },
        // {
        //   title: 'Project',
        //   url: '/entity/projects',
        //   permissions: ['manage.core'],
        // },
      ],
    },
    {
      title: 'Statistics',
      icon: <BarChartOutlined />,
      permissions: ['read.statistics'],
      items: [
        {
          title: 'Search Rate Log',
          url: '/statistics/searchRateLogs',
          permissions: ['read.searchRateLog'],
        },
        {
          title: 'Intermodal Region',
          url: '/statistics/intermodalRegions',
        },
        {
          title: 'Rate Heat Map',
          url: '/statistics/rateHeatMap',
        },
        {
          title: 'Demand',
          url: '/statistics/demands',
        },
        {
          title: 'User Access Logs',
          url: '/statistics/authlogs',
        },
        {
          title: 'Risk',
          url: '/statistics/risk',
        },
      ],
    },
    {
      title: 'Rate Engine',
      icon: <RadarChartOutlined />,
      permissions: ['manage.core'],
      items: [
        {
          title: 'Rate',
          url: '/engine/rate',
          permissions: ['manage.core'],
        },
        {
          title: 'Verify',
          url: '/engine/verify',
          permissions: ['manage.core'],
        },
        {
          title: 'Index Tool',
          url: '/engine/indexTool',
          permissions: ['manage.core'],
        },
        {
          title: 'Setting',
          url: '/engine/setting',
          permissions: ['manage.core'],
        },
      ],
    },
  ],
  LTL: [
    {
      title: 'Dashboard',
      url: '/truckload/ltl/dashboard',
    },
    {
      title: 'Vendor',
      url: '/truckload/ltl/tlvendor',
      permissions: ['read.vendor'],
    },
    {
      title: 'Quote',
      url: '/truckload/ltl/quotes',
      permissions: ['read.vendor'],
    },
    {
      title: 'Shipment',
      url: '/truckload/ltl/shipments',
      permissions: ['read.vendor'],
    },
    {
      title: 'Invoice',
      url: '/truckload/ltl/invoices',
      permissions: ['read.orderInvoice'],
    },
    {
      title: 'Bill',
      url: '/truckload/ltl/bills',
      permissions: ['read.orderBill'],
    },
  ],
  FTL: [
    {
      title: 'Dashboard',
      url: '/truckload/ftl/dashboard',
    },
    {
      title: 'Quote',
      url: '/truckload/ftl/quotes',
      permissions: ['read.vendor'],
    },
    {
      title: 'Shipment',
      url: '/truckload/ftl/shipments',
      permissions: ['read.vendor'],
    },
    {
      title: 'Vendor',
      url: '/truckload/ftl/tlvendor',
      permissions: ['read.vendor'],
    },
    {
      title: 'Carrier',
      url: '/truckload/ftl/carriers',
      permissions: ['read.vendor'],
    },
    {
      title: 'Invoice',
      url: '/truckload/ftl/invoices',
      permissions: ['read.orderInvoice'],
    },
    {
      title: 'Bill',
      url: '/truckload/ftl/bills',
      permissions: ['read.orderBill'],
    },
  ],
  Accounting: [
    {
      title: 'Dashboard',
      url: '/accounting/dashboard',
    },
    {
      title: 'Invoice',
      url: '/accounting/invoices',
      permissions: ['read.orderInvoice'],
    },
    {
      title: 'Bill',
      url: '/accounting/bills',
      permissions: ['read.orderBill'],
    },
    {
      title: 'Local Statement',
      url: '/accounting/localstatements',
      permissions: ['read.container'],
    },
    {
      title: 'Controller',
      permissions: ['manage.accounting'],
      items: [
        {
          title: 'Invoice Payment',
          url: '/accounting/invoices/payments',
        },
        {
          title: 'Bill Payment',
          url: '/accounting/bills/payments',
        },
        {
          title: 'Report',
          url: '/accounting/reports',
          permissions: ['manage.controller'],
        },
        {
          title: 'Bank Account',
          url: '/accounting/bank-accounts',
          permissions: ['manage.controller'],
        },
        {
          title: 'Income Statement',
          url: '/accounting/income-statements',
          permissions: ['manage.controller'],
        },
        {
          title: 'Balance Sheet',
          url: '/accounting/balance-sheets',
          permissions: ['manage.controller'],
        },
        {
          title: 'Aging Report',
          url: '/accounting/aging-reports',
          permissions: ['manage.controller'],
        },
        {
          title: 'Accounting Block',
          url: '/accounting/account-blocks',
          permissions: ['manage.controller'],
        },
        {
          title: 'User Account',
          url: '/accounting/user-accounts',
          permissions: ['manage.accounting'],
        },
        {
          title: 'Prepay',
          url: '/accounting/prepaid-orders',
          permissions: ['manage.controller'],
        },
      ],
    },
  ],
};
