import React from 'react';
import { Form, FormInstance, Input, Row, Col, Switch, Select } from 'antd';

import { ContactList } from '@/components/ContactList';
import TextArea from 'antd/lib/input/TextArea';
import { Documents } from '@/components/Upload/Documents';
import { advancedTermsRule } from '@/utils/advancedTerms';
import { TIER_MAP } from '@/pages/entity/intermodalRegions/components/data';
import { UserSyncSelect } from '@/components/UserSyncSelect';

interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

export const CompanyForm: React.FC<Props> = ({ form, onSubmit }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const formatBillingAddress = () => {
    const name = form.getFieldValue('name');
    const address = form.getFieldValue('address');
    return `${name}\n${address}`;
  };

  const onValuesChange = (changedValues: any) => {
    if (changedValues.name || changedValues.address) {
      form.setFieldsValue({ billing_address: formatBillingAddress() });
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={onValuesChange}
      onFinish={onSubmit}
    >
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: 'Please Input Code' }]}
          >
            <Input id="user_form_code" placeholder="Enter code" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Company Name"
            name="name"
            rules={[{ required: true, message: 'Please Input Name' }]}
          >
            <Input id="user_form_name" placeholder="Enter name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Terms"
            name="terms"
            extra="Please input terms in the format of 15 or 30 or 25+28 or 28+5 ..."
            rules={[
              { required: true, message: 'Please Input terms' },
              advancedTermsRule,
            ]}
          >
            <Input placeholder="Enter terms" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Please Input Address' }]}
          >
            <TextArea rows={6} placeholder="Enter company" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Billing Address"
            name="billing_address"
            rules={[
              { required: true, message: 'Please Input Billing Address' },
              // add validator to check if billing address includes input name
              {
                validator: (rule, value) => {
                  const name = form.getFieldValue('name');
                  if (value.includes(name)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'Billing Address must include Company Name',
                  );
                },
                message: 'Billing Address must include Company Name',
              },
            ]}
          >
            <TextArea rows={6} placeholder="Enter company" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Has Contract" name="has_contract">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                label="LTL Margin Percent"
                name="tl_marign_percent"
                extra="Please input LTL marign percent."
                rules={[
                  {
                    required: true,
                    message: 'Please Input LTL marign percent',
                  },
                  advancedTermsRule,
                ]}
              >
                <Input
                  addonAfter={'%'}
                  placeholder="Enter LTL marign percent"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="FTL Margin Percent"
                name="ftl_margin_percent"
                extra="Please input FTL marign percent."
                rules={[
                  {
                    required: true,
                    message: 'Please Input FTL marign percent',
                  },
                  advancedTermsRule,
                ]}
              >
                <Input
                  addonAfter={'%'}
                  placeholder="Enter FTL marign percent"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="tier_rate" label="Drayage Tier Rate">
                <Select placeholder="Select tier" allowClear>
                  {Object.keys(TIER_MAP).map((key: any) => (
                    <Select.Option key={key} value={key}>
                      {TIER_MAP[(key as unknown) as keyof typeof TIER_MAP]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue('id') && (
                <Documents
                  title="Documents"
                  target="company"
                  targetId={getFieldValue('id')}
                />
              )
            }
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Agent" name="agent_id">
            <UserSyncSelect type="user" allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                name="contacts"
                key="form_contacts"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <ContactList
                  data={getFieldValue('contacts') || []}
                  updateData={(list) =>
                    handleChange({ target: { name: 'contacts', value: list } })
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
