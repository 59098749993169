import AutoResizeTable from '@/components/AutoResizeTable';
import {
  DisputeMemo,
  DISPUTE_TYPE_FTL_SHIPMENTS,
} from '@/components/DisputeMemo';
import { EditableSelect, EditableText } from '@/components/Editable';
import { LogActivities } from '@/components/LogActivities';
import { TARGET_TYPE_FTL_SHIPMENT } from '@/components/Tasks/Interfaces/ActionInterface';
import { TodoList } from '@/components/Tasks/Render/TodoList';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import {
  AlignLeftOutlined,
  InfoCircleOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons';
import { Space, message, Tooltip, DatePicker, Tag, Button } from 'antd';
import { Upload } from '../../components/Upload';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { get } from 'lodash';
import moment from 'moment';
import { BUSINESS_STATUS_OPTIONS, CUSTOMER_STATUS_OPTIONS } from '..';
import { AccountingModal } from '../../components/AccountingModal';
import ColorTags from '../../components/ColorTags';
import {
  ShipmentBusinessStatus,
  ApStatusLabelMap,
  ArStatusLabelMap,
  RebillStatusLabelMap,
  generateOptions,
  ShipmentStatus,
} from '../../constants';
import { useEffect, useMemo } from 'react';
import { ShipmentItemInterface } from '../Interfaces/ShipmentItemInterface';

import { FTLShipmentInterface } from '../Interfaces/ShipmentInterface';
import { TrackTable } from '../../components/TrackTable';

export interface ShipmentsTableProps {
  pagination?: false | TablePaginationConfig | undefined;
  columns?: ColumnsType<ShipmentItemInterface>;
  data: any;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  handleShow: (record: any) => void;
  handlePatchUpdate: (id: number, model: string, data: any) => void;
  fetchData: (pagination?: any, filterValues?: any[]) => void;
  refreshFtlShipment: (id: number) => void;
  openId?: number;
  setDefaultColumns?: (columns: ColumnsType<FTLShipmentInterface>) => void;
  loading?: boolean;
}

const ShipmentsTable = ({
  pagination = false,
  columns,
  data,
  handleTableChange,
  handleShow,
  handlePatchUpdate,
  fetchData,
  refreshFtlShipment,
  setDefaultColumns,
  openId = 0,
  loading,
}: ShipmentsTableProps) => {
  const defaultColumns: ColumnsType<FTLShipmentInterface> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'uid',
        key: 'id',
        fixed: 'left',
        width: 160,
        sorter: true,
        render: (text, record) => {
          return (
            <Space>
              <a onClick={() => handleShow(record)}>{record.uid}</a>
              <ColorTags
                tlId={record.id}
                tags={record.tags || []}
                onApply={() => fetchData && fetchData()}
              />
            </Space>
          );
        },
      },

      {
        title: 'Dispatch Status',
        dataIndex: 'business_status',
        key: 'business_status',
        width: 200,
        fixed: 'left',
        align: 'center',
        render: (text, record) => (
          <EditableSelect
            name="business_status"
            value={record.business_status || 0}
            options={BUSINESS_STATUS_OPTIONS}
            onChange={async (name: string, value: any) => {
              if (record.business_status == ShipmentBusinessStatus.CANCELED) {
                return message.error('Canceled shipment cannot change status.');
              }

              if (
                value == ShipmentBusinessStatus.CANCELED &&
                !window.confirm(
                  'There is no reversible action, and the credit will be returned to the customer account. Are you sure you want to change the status to canceled?',
                )
              ) {
                return;
              }

              await handlePatchUpdate(record.id, 'shipments', {
                [name]: value,
              });
            }}
          />
        ),
      },
      {
        title: 'Customer Status',
        dataIndex: 'customer_status',
        key: 'customer_status',
        width: 200,
        align: 'center',
        render: (text, record) => (
          <EditableSelect
            name="customer_status"
            value={record.customer_status || 0}
            options={CUSTOMER_STATUS_OPTIONS}
            onChange={async (name: string, value: any) => {
              await handlePatchUpdate(record.id, 'shipments', {
                [name]: value,
              });
            }}
          />
        ),
      },
      {
        title: 'OP',
        dataIndex: 'operator_id',
        key: 'operator_id',
        width: 150,
        // fixed: 'left',
        render: (text, record) => (
          <UserSyncSelect
            style={{ width: '100%' }}
            type="admin"
            value={record?.operator_id || ''}
            allowClear
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                operator_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Sales',
        dataIndex: 'sales_id',
        key: 'sales_id',
        width: 150,
        // fixed: 'left',
        render: (text, record) => (
          <UserSyncSelect
            style={{ width: '100%' }}
            type="admin"
            value={record?.sales_id || ''}
            allowClear
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                sales_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'AR Status',
        dataIndex: 'ar_status',
        key: 'ar_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="ar_status"
            value={record.ar_status}
            options={generateOptions(ArStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handlePatchUpdate(record.id, 'shipments', {
                  [name]: value,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'AP Status',
        dataIndex: 'ap_status',
        key: 'ap_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="ap_status"
            value={record.ap_status}
            options={generateOptions(ApStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handlePatchUpdate(record.id, 'shipments', {
                  [name]: value,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'Rebill Status',
        dataIndex: 'rebill_status',
        key: 'rebill_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="rebill_status"
            value={record.rebill_status}
            options={generateOptions(RebillStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handlePatchUpdate(record.id, 'shipments', {
                  [name]: value ? value : null,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'Vendor Shipment ID',
        dataIndex: 'vendor_shipment_id',
        key: 'vendor_shipment_id',
        width: 150,
      },
      {
        title: 'Customer Ref#',
        dataIndex: 'customer_reference_number',
        key: 'customer_reference_number',
        width: 160,
        ellipsis: {
          showTitle: false,
        },
        render: (customer_reference_number) => (
          <Tooltip placement="topLeft" title={customer_reference_number}>
            {customer_reference_number}
          </Tooltip>
        ),
      },
      {
        title: 'From Type',
        dataIndex: 'from_type',
        key: 'from_type',
        width: 120,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        width: 160,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip
            placement="topLeft"
            title={`${record.company_code} (${record.user_name})`}
          >
            {`${record.company_code}(${record.user_name})`}
          </Tooltip>
        ),
      },
      {
        title: 'Quote Rate',
        dataIndex: 'quote_rate',
        key: 'quote_rate',
        width: 100,
      },
      {
        title: 'Buy Rate',
        dataIndex: 'vendor_rate',
        key: 'vendor_rate',
        width: 100,
      },
      {
        title: 'Sell Rate',
        dataIndex: 'sell_rate',
        key: 'sell_rate',
        width: 100,
      },
      {
        title: 'Est Margin',
        dataIndex: 'margin_rate',
        key: 'margin_rate',
        width: 150,
        render: (text, record) => {
          return (
            record.margin_rate.toFixed(2) +
            '/' +
            record.margin_percent.toFixed(2) +
            '%'
          );
        },
      },
      {
        title: 'AR',
        dataIndex: 'invoice_amount',
        key: 'invoice_amount',
        width: 100,
      },
      {
        title: 'AP',
        dataIndex: 'bill_amount',
        key: 'bill_amount',
        width: 100,
      },
      {
        title: 'Act Profit',
        dataIndex: 'profit_amount_total',
        key: 'profit_amount_total',
        width: 100,
      },
      {
        title: 'Vendor/Carrier',
        dataIndex: 'vendor_carrier_name',
        key: 'vendor_carrier_name',
        width: 250,
        render: (text, record) => {
          return `${record.vendor_name}/${text}`;
        },
      },
      {
        title: 'Pro Number',
        dataIndex: 'pro_number',
        key: 'pro_number',
        width: 180,
        render: (text: string, record: any) => {
          return (
            <EditableText
              type="string"
              name="pro_number"
              value={record.pro_number}
              onChange={(name: string, value: any) => {
                handlePatchUpdate(record.id, 'shipments', {
                  pro_number: value,
                });
              }}
            />
          );
        },
      },
      {
        title: 'Est Pickup Date',
        dataIndex: 'estimated_pickup_date',
        key: 'estimated_pickup_date',
        width: 150,
        sorter: true,
        render: (text, record) => {
          return (
            <DatePicker
              style={{ width: '100%' }}
              value={
                record.estimated_pickup_date
                  ? moment(record.estimated_pickup_date)
                  : null
              }
              onChange={(date) =>
                handlePatchUpdate(record.id, 'shipments', {
                  estimated_pickup_date: date?.format('YYYY-MM-DD') ?? null,
                })
              }
            />
          );
        },
      },
      {
        title: 'Est Delivery Date',
        dataIndex: 'estimated_delivery_date',
        key: 'estimated_delivery_date',
        width: 150,
        sorter: true,
        render: (text, record) => {
          return (
            <DatePicker
              style={{ width: '100%' }}
              value={
                record.estimated_delivery_date
                  ? moment(record.estimated_delivery_date)
                  : null
              }
              onChange={(date) =>
                handlePatchUpdate(record.id, 'shipments', {
                  estimated_delivery_date: date?.format('YYYY-MM-DD') ?? null,
                })
              }
            />
          );
        },
      },
      {
        title: 'Act Pickup Date',
        dataIndex: 'actual_pickup_date',
        key: 'actual_pickup_date',
        width: 150,
        sorter: true,
        render: (text, record) => {
          return (
            <DatePicker
              style={{ width: '100%' }}
              value={
                record.actual_pickup_date
                  ? moment(record.actual_pickup_date)
                  : null
              }
              onChange={(date) =>
                handlePatchUpdate(record.id, 'shipments', {
                  actual_pickup_date: date?.format('YYYY-MM-DD') ?? null,
                })
              }
            />
          );
        },
      },
      {
        title: 'Act Delivery Date',
        dataIndex: 'actual_delivery_date',
        key: 'actual_delivery_date',
        width: 150,
        sorter: true,
        render: (text, record) => {
          return (
            <DatePicker
              style={{ width: '100%' }}
              value={
                record.actual_delivery_date
                  ? moment(record.actual_delivery_date)
                  : null
              }
              onChange={(date) =>
                handlePatchUpdate(record.id, 'shipments', {
                  actual_delivery_date: date?.format('YYYY-MM-DD') ?? null,
                })
              }
            />
          );
        },
      },
      {
        title: 'Pick up Location',
        key: 'pickup_address',
        width: 320,
        render: (text, record) => {
          return `${record.pickup_address1}
          ${record.pickup_address2} ${record.pickup_city},
          ${record.pickup_state} ${record.pickup_zipcode} ${record.pickup_country}`;
        },
      },
      {
        title: 'Delivery Location',
        key: 'destination_address',
        width: 320,
        render: (text, record) => {
          return `${record.destination_address1}
          ${record.destination_address2} ${record.destination_city},
          ${record.destination_state} ${record.destination_zipcode} ${record.destination_country}`;
        },
      },
      {
        title: 'API Stage',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render: (text, record) => {
          switch (record.status) {
            case ShipmentStatus.PROCESSING:
              return <Tag color="default">PROCESSING</Tag>;
            case ShipmentStatus.WAIT_REQUOTE:
              return <Tag color="yellow">WAIT_REQUOTE</Tag>;
            case ShipmentStatus.WAIT_CONFIM:
              return <Tag color="yellow">WAIT_CONFIM</Tag>;
            case ShipmentStatus.FAILED:
              return <Tag color="red">FAILED</Tag>;
            case ShipmentStatus.SUCCESS:
              return <Tag color="blue">SUCCESS</Tag>;
            case ShipmentStatus.SYNCED:
              return <Tag color="green">SYNCED</Tag>;
            case ShipmentStatus.SYNC_FAILED:
              return <Tag color="gray">SYNC FAILED</Tag>;
            default:
              return <Tag color="default">Unknown</Tag>;
          }
        },
      },
      {
        title: 'Order Created Date',
        dataIndex: 'create_date',
        key: 'create_date',
        align: 'center',
        width: 150,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 250,
        render: (text, record) => (
          <Space>
            <Upload shipment={record} />
            <TodoList
              target={TARGET_TYPE_FTL_SHIPMENT}
              targetModel={record}
              color={get(record, 'task_icon_colors.todoList', 'gray')}
              onDone={() => refreshFtlShipment(record.id)}
            />
            <DisputeMemo
              title="Memo"
              onSaved={() => fetchData && fetchData()}
              model={record}
              icon={
                <Tooltip placement="left" title={record.internal_memo || ''}>
                  {!record.internal_memo && (
                    <div className="ant-btn-sm text-gray">
                      <AlignLeftOutlined />
                    </div>
                  )}
                  {record.internal_memo && (
                    <div className="ant-btn-sm text-primary">
                      <AlignLeftOutlined />
                    </div>
                  )}
                </Tooltip>
              }
              type={DISPUTE_TYPE_FTL_SHIPMENTS}
              withStatus={false}
              open={openId == record.id}
            />

            <Button type="link" size="small">
              <div className="text-gray">
                <AccountingModal
                  ftlShipment={record}
                  showAccountingTips
                  onChanged={() => refreshFtlShipment(record.id)}
                />
              </div>
            </Button>

            <Button type="link" size="small">
              <LogActivities
                id={record.id}
                icon={
                  <div className="text-gray">
                    <InfoCircleOutlined />
                  </div>
                }
                type="ftlShipments"
              />
            </Button>
            <Button type="link" size="small">
              <TrackTable
                oredrId={record.id}
                icon={
                  record.tracking_histories.length === 0 ? (
                    <div className="text-gray">
                      <NodeIndexOutlined />
                    </div>
                  ) : (
                    <div className="text-primary">
                      <NodeIndexOutlined />
                    </div>
                  )
                }
                type="ftlShipments"
              />
            </Button>
          </Space>
        ),
      },
    ],
    [openId, fetchData, refreshFtlShipment, handlePatchUpdate],
  );

  useEffect(() => {
    setDefaultColumns && setDefaultColumns(defaultColumns);
  }, [defaultColumns]);

  return (
    <AutoResizeTable
      loading={loading}
      pagination={pagination}
      size="small"
      columns={columns || defaultColumns}
      onChange={handleTableChange}
      dataSource={data?.data || []}
      sticky
      bordered
      scroll={{
        x: 1500,
      }}
    />
  );
};

export default ShipmentsTable;
