import React, { useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get, meanBy, truncate } from 'lodash';
import { Popover, Space, Tooltip } from 'antd';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';
import VendorRating from './VendorRating';
import { RatingTag } from '@/components/RatingTag';
import { VendorVerified } from '@/pages/entity/vendors/components/VendorVerified';

export const TruckerColumn: React.FC<{
  container: any;
  refreshContainer: (id: number) => void;
}> = ({ container, refreshContainer }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      <div className="flex">
        <div
          className="text-gray mr-sm cursor-pointer"
          onClick={() => setOpen(!open)}>
          <Tooltip title={container.vendor?.internal_memo}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div>
          {container.vendor && (
            <Tooltip title={container.vendor?.name}>
              {container.vendor?.blacklisted && (
                <strong className="text-danger">[Blacklisted]</strong>
              )}
              {truncate(get(container, 'vendor.name', ''), {
                length: container.vendor?.blacklisted ? 17 : 27,
              })}
              <span className="ml-xs">
                <VendorVerified
                  vendor={container.vendor}
                  onSaved={() => refreshContainer(container.id)}
                />
              </span>
            </Tooltip>
          )}
        </div>
        <div>
          {
            <Popover
              trigger="click"
              destroyTooltipOnHide
              content={
                <VendorRating
                  vendor_id={container.vendor?.id || 0}
                  score={container.vendor?.score || 'N/A'}
                  container={container}
                  refreshContainer={() => refreshContainer(container.id)}
                />
              }>
              <RatingTag rating={container.vendor?.score || 0} />
            </Popover>
          }
        </div>
      </div>
      {open && container && container.vendor_id && (
        <VendorDrawer
          id={container.vendor_id}
          onSaved={(data: any) => refreshContainer(data.id)}
          onClose={() => setOpen(false)}
          visible={!!open}
        />
      )}
    </>
  );
};
