import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Badge,
  Button,
  Divider,
  Drawer,
  List,
  message,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { Header } from './components/Header';
import { Filter } from './components/Filter';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { get, truncate } from 'lodash';
// import { DrawerForm } from './components/DrawerForm';
import { BuyRateDrawer } from './components/buyRateDrawer';
import { DrawerFormBulkUpdate } from './components/DrawerFormBulkUpdate';
// import { DrawerForm as VendorDrawerForm } from '@/pages/entity/vendors/components/DrawerForm';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';
import {
  SellRateDrawer,
  TFormProps,
} from '@/pages/rates/sellRates/components/sellRateDrawer';
import ContainerRow from './components/ContainerRow';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { CompareRates } from './components/CompareRates';
import { RatingTag } from '@/components/RatingTag';
import AutoResizeTable from '@/components/AutoResizeTable';
import Import from './components/Import';
import usePagination from '@/components/usePagination';
import {
  RatePossibleChargeText,
  RenderCompareRate,
  WhsWaitingRateText,
  getWhsRateAndHours,
} from './components/_helper';
import moment from 'moment';
import { useVendorRateDrawer } from '@/pages/entity/vendors/components/useVendorRateDrawer';
import RateItemsQuickViewPopover from './components/RateItemsQuickViewPopover';
import { VendorCell } from '@/components/VendorCell';
import { LogActivities } from '@/components/LogActivities';
import VendorRating from '@/pages/dispatchs/components/VendorRating';
import Decimal from 'decimal.js';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Logo from '@/components/Logo';

export const COPY = -1;

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openSellRateDrawer, setOpenSellRateDrawer] = useState(false);
  const [openVendorDrawer, setOpenVendorDrawer] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const [container, setContainer] = useState<any>(null);
  //const [filter, setFilter] = useState<any>({});
  const { VendorRateFormDrawer, openVendorRateFormDrawer } =
    useVendorRateDrawer();
  const [visibleBulkUpdate, setVisibleBulkUpdate] = useState(false);

  const [data, setData] = useState<any[]>();
  const [response, setResponse] = useState<any>();

  const pagination = usePagination(response);

  const [rate, setRate] = useState<any>(null);
  const [form] = useForm();
  const [copyRecord, setCopyRecord] = useState(null);
  const [initialSellRateFormValues, setInitialSellRateFormValues] =
    useState<TFormProps>();
  const [filter] = useForm();

  const filterSortBy = useWatch('sort_by', filter);
  const filterSortValue = useWatch('sort_value', filter);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setRate(null);
    setCopyRecord(null);
    form.resetFields();
  };

  const handleNew = () => {
    form.setFieldsValue({
      cargo_type_id: 1,
      visible: true,
    });
    showDrawer();
  };

  const handleSaveBulkUpdate = async () => {
    fetchData();
    handelCloseBulkUpdate();
  };
  const handleBulkUpdate = () => {
    if (selectedRowKeys.length <= 0) {
      message.error('Please select data to update');
      return false;
    }
    setVisibleBulkUpdate(true);
  };

  const handelCloseBulkUpdate = () => {
    setSelectedRowKeys([]);
    setVisibleBulkUpdate(false);
  };

  const handleFilterChange = (values: any) => {
    //setFilter(values);
    filter.setFieldsValue(values);
  };

  const handleCreateSellRate = () => {
    setInitialSellRateFormValues({
      cargo_type_id: 1,
      visible: true,
      intermodal_region_id: filter.getFieldValue('intermodal_region_id') ?? 0,
      to_city: filter.getFieldValue('to_city'),
    });
    setOpenSellRateDrawer(true);
  };

  const handleSavedSellRateDrawer = (response?: any) => {
    setInitialSellRateFormValues({});
    setOpenSellRateDrawer(false);
  };

  const handleCloseSellRateDrawer = () => {
    setInitialSellRateFormValues({});
    setOpenSellRateDrawer(false);
  };

  const fetchContainer = async (containerId: any) => {
    if (!containerId) {
      return;
    }

    try {
      const resp = await app.service.get(`containers/${containerId}`);
      console.log(resp);
      setContainer(resp.data);
      console.log(resp);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const fetchData = async (
    pagination?: TablePaginationConfig,
    sortBy?: string,
    sortValue?: any,
    extraFilter: any = {},
  ) => {
    const values = filter.getFieldsValue();
    const _filter = Object.assign({}, { ...values });
    _filter.to_city_id = get(_filter, 'to_city.id', 0);

    _filter.vendor_id = get(_filter, 'vendor.id', 0);

    _filter.sort_by = sortBy;

    _filter.sort_value = sortValue;

    if (get(_filter, 'past_month')) {
      const month = get(_filter, 'past_month');
      const end_date = moment().endOf('day');
      const start_date = moment()
        .subtract(month, month > 1 ? 'months' : 'month')
        .startOf('month');

      _filter.created_at_start_date = start_date.format();
      _filter.created_at_end_date = end_date.format();
    }

    delete _filter.to_city;
    delete _filter.vendor;

    filter.setFieldsValue({ ...values, ..._filter });
    setLoading(true);
    try {
      const result = await app.service.get('rates', {
        params: {
          ..._filter,
          ...extraFilter,
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });

      setResponse(result);
      setData(result.data);

      if (app.store.search.rateId) {
        app.store.search.setRateId(null);
      }

      if (app.store.search.intermodalRegionId) {
        app.store.search.setIntermodalRegionId(null);
      }

      if (app.store.search.toCity) {
        app.store.search.setToCity(null);
      }
    } catch (error: any) {
      message.error(error.data?.message || error.data.error || error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  React.useEffect(() => {
    if (
      app.store.search.rateId ||
      app.store.search.intermodalRegionId ||
      app.store.search.toCity
    ) {
      fetchData();
    }
  }, [
    app.store.search.rateId,
    app.store.search.intermodalRegionId,
    app.store.search.toCity,
  ]);

  const setCityData = async (id: string) => {
    const response = await app.service.get(`cities/${id}`);
    filter.setFieldsValue({
      to_city: response.data,
    });
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const ir = urlSearchParams.get('ir');
    const toCity = urlSearchParams.get('toCity');
    const vendorId = urlSearchParams.get('vendorId');
    const containerId = urlSearchParams.get('cntrId');

    const data: any = {};

    if (toCity && ir) {
      data.intermodal_region_id = +ir;
      data.to_city_id = +toCity;

      setCityData(toCity);

      filter.setFieldsValue({
        intermodal_region_id: +ir,
        // to_city: { id: +toCity },
      });
    }

    if (vendorId) {
      data.vendor_id = +vendorId;

      filter.setFieldsValue({
        vendor: { id: +vendorId },
      });
    }

    fetchData(undefined, undefined, undefined, data);

    if (containerId) {
      fetchContainer(containerId);
    }
  }, []);

  const isNew = useMemo(() => {
    if (rate == COPY) {
      return true;
    }
    return !rate;
  }, [rate]);

  const handleOnSaved = () => {
    fetchData();
    setRate(null);
    onClose();
  };
  // const handleSave = async () => {
  //   const data = await form.validateFields();

  //   setLoading(true);
  //   const formValue = Object.assign({}, data);

  //   formValue.to_city_id = get(formValue, 'to_city.id', 0);
  //   formValue.vendor_id = get(formValue, 'vendor.id', 0);

  //   delete formValue.vendor;
  //   delete formValue.to_city;

  //   const request =
  //     isNew || rate === COPY
  //       ? app.service.post('rates', { data: formValue })
  //       : app.service.put(`rates/${rate}`, { data: formValue });

  //   request
  //     .then(() => {
  //       fetchData();
  //       setRate(null);
  //       onClose();
  //     })
  //     .catch((err) => {
  //       message.error(err.data?.message || err.data?.error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const handleRemove = (id: number) => {
    app.service
      .delete(`rates/${id}`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setRate(id);
    // form.setFieldsValue({ ...item });
  };

  const handleCopy = (record: any) => {
    // form.setFieldsValue({ ...record });
    setCopyRecord(record);
    setRate(COPY);
    setVisible(true);
  };

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleReplace = (item: any) => {
    const _data = [...data].map((d) => {
      return d.id == item.id ? item : d;
    });
    setData(_data);
  };

  const handleSetValid = useCallback(
    async (id: string, valid: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`rates/${id}/setValid`, {
          data: { valid },
        });
        const _data = data.find((r) => r.id == id);
        _data.valid = valid;
        handleReplace(_data);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleSetVisibility = useCallback(
    async (id: string, visible: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`rates/${id}/setVisibility`, {
          data: { visible },
        });
        const _data = data.find((r) => r.id == id);
        _data.visible = visible;
        handleReplace(_data);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleSetAdminFavored = useCallback(
    async (id: string, admin_favored: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`rates/${id}/setAdminFavored`, {
          data: { admin_favored },
        });
        const _data = data.find((r) => r.id == id);
        _data.admin_favored = admin_favored;
        handleReplace(_data);
      } catch (error) {
        message.error('Error updating rate');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleSetLocked = useCallback(
    async (id: string, locked: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`rates/${id}/setLocked`, {
          data: { locked },
        });
        const _data = data.find((r) => r.id == id);
        _data.locked = locked;
        handleReplace(_data);
      } catch (error) {
        message.error('Error updating rate');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const showVendorDetail = (id: number) => {
    if (!id) {
      return;
    }

    setVendorId(id);
    setOpenVendorDrawer(true);
  };

  const handleCloseVendorDrawer = () => {
    setOpenVendorDrawer(false);
    setVendorId(null);
  };

  const columns: ColumnsType<any> = React.useMemo(() => {
    const toSortOrder = (field: string): string | null => {
      if (field === filterSortBy) {
        if (filterSortValue === 'desc') {
          return 'descend';
        }
        if (filterSortValue === 'asc') {
          return 'ascend';
        }
      }
      return null;
    };

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        sorter: true,
        sortOrder: toSortOrder('id'),
        render: (text, record) => {
          return (
            <>
              <Space direction="horizontal">
                <span>{record.uid}</span>
                <Logo name={record?.from_facility_type} />
              </Space>
            </>
          );
        },
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',
        sortDirections: ['descend', 'ascend', null],
        sortOrder: toSortOrder('vendor'),
        width: 300,
        sorter: true,
        render: (text, record) => (
          <VendorCell
            onSaved={() => fetchData()}
            vendor={record.vendor}
            showVendorDetail={showVendorDetail}
          />
        ),
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
        width: 80,
        render: (text, record) => {
          const vendor = get(record, 'vendor');
          return (
            <Popover
              trigger="click"
              destroyTooltipOnHide
              content={
                <VendorRating
                  vendor_id={vendor?.id || 0}
                  score={vendor?.score || 'N/A'}
                  container={null}
                />
              }>
              {vendor && <RatingTag rating={vendor?.score} />}
            </Popover>
            // <RatingTag
            //   rating={rating}
            //   onClick={() => {
            //     get(record, 'vendor.id') &&
            //       openVendorRateFormDrawer(get(record, 'vendor.id'), true);
            //   }}
            // />
          );
        },
      },
      {
        title: 'Truck',
        dataIndex: 'truck_rate',
        key: 'truck_rate',
        width: container ? 140 : 100,
        sorter: true,
        sortOrder: toSortOrder('truck_rate'),
        render: (text, record) => {
          return (
            <RenderCompareRate
              rate={record.truck_rate}
              compareRate={get(container, 'sell_rate.base_rate')}
            />
          );
        },
      },
      {
        title: 'Chassis',
        width: 100,
        render: (text, record) => {
          return (
            <RatePossibleChargeText
              rate={record}
              container={container}
              code="CHAFE"
            />
          );
        },
      },
      {
        title: 'Prepull',
        width: 100,
        render: (text, record) => {
          return (
            <RatePossibleChargeText
              rate={record}
              container={container}
              code="PRECH"
            />
          );
        },
      },
      {
        title: 'Yard Storage',
        width: 100,
        render: (text, record) => {
          return (
            <RatePossibleChargeText
              rate={record}
              container={container}
              code="YARST"
            />
          );
        },
      },
      {
        title: 'Drop Fee',
        width: 100,
        dataIndex: 'drop_fee',
        render: (text, record) => {
          return text ? text : '-';
        },
      },
      {
        title: 'Chassis Split',
        width: 110,
        render: (text, record) => {
          return (
            <RatePossibleChargeText
              rate={record}
              container={container}
              code="CHASP"
            />
          );
        },
      },
      {
        title: 'Total',
        dataIndex: 'total_rate',
        key: 'total_rate',
        width: container ? 140 : 120,
        sorter: true,
        sortOrder: toSortOrder('total_rate'),
        render: (text, record) => {
          const totalRate = get(container, 'sell_rate.total_rate');

          return (
            <>
              <RenderCompareRate
                rate={record.total_rate}
                compareRate={totalRate}
              />
              {record.rate_items.length ? (
                <RateItemsQuickViewPopover items={record.rate_items} />
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        title: 'Scen1',
        dataIndex: 'best_scenario_total_amount',
        key: 'best_scenario_total_amount',
        width: container ? 150 : 120,
        sorter: true,
        sortOrder: toSortOrder('best_scenario_total_amount'),
        render: (text, record) => {
          const { whsRate, hours, freeHours } = getWhsRateAndHours(record);
          const bestScenario = container?.snapshot?.scenarios?.find((s) =>
            s.name.startsWith('Best Scenario'),
          );

          return (
            <Popover
              placement="top"
              className="ml-sm"
              trigger="click"
              content={
                <>
                  <div>Base: {record.base_rate}</div>
                  <div>Fuel: {record.fuel_surcharge}</div>
                  <div>
                    <RatePossibleChargeText
                      rate={record}
                      code="CHAFE"
                      label="Chassis"
                      qty={1}
                      container={container}
                      showEmptyLabel={true}
                    />
                  </div>
                  <div>
                    {' '}
                    <WhsWaitingRateText
                      whsRate={whsRate}
                      hours={hours}
                      freeHours={freeHours}
                    />
                  </div>
                </>
              }
              arrowPointAtCenter>
              <Space>
                <span
                  className={
                    record.has_lack_of_best_scenario_total_amount ? 'red' : ''
                  }>
                  {/* {new Decimal(record.best_scenario_total_amount).toFixed(2)} */}
                  <RenderCompareRate
                    rate={new Decimal(
                      record.best_scenario_total_amount,
                    ).toFixed(2)}
                    compareRate={bestScenario?.total_rate}
                  />
                </span>
                <span className="cursor-pointer">
                  <QuestionCircleOutlined />
                </span>
              </Space>
            </Popover>
          );
        },
      },
      {
        title: 'Scen2',
        width: container ? 150 : 120,
        dataIndex: 'average_scenario_total_amount',
        key: 'average_scenario_total_amount',
        sortOrder: toSortOrder('average_scenario_total_amount'),
        sorter: true,
        render: (text, record) => {
          const { whsRate, hours, freeHours } = getWhsRateAndHours(record);
          const averageScenario = container?.snapshot?.scenarios?.find((s) =>
            s.name.startsWith('Average Scenario'),
          );
          return (
            <Popover
              placement="top"
              className="ml-sm"
              trigger="click"
              content={
                <>
                  <div>Base: {record.base_rate}</div>
                  <div>Fuel: {record.fuel_surcharge}</div>
                  <div>
                    <RatePossibleChargeText
                      rate={record}
                      code="CHAFE"
                      label="Chassis"
                      qty={1}
                      container={container}
                      showEmptyLabel={true}
                    />
                  </div>
                  <div>
                    {' '}
                    <WhsWaitingRateText
                      whsRate={whsRate}
                      hours={hours}
                      freeHours={freeHours}
                    />
                  </div>
                  <div>
                    <RatePossibleChargeText
                      rate={record}
                      code="PRECH"
                      label="Prepull"
                      qty={1}
                      container={container}
                      showEmptyLabel={true}
                    />
                  </div>
                  <div>
                    {' '}
                    <RatePossibleChargeText
                      rate={record}
                      code="YARST"
                      label="Yard storage"
                      qty={1}
                      container={container}
                      showEmptyLabel={true}
                    />
                  </div>
                </>
              }
              arrowPointAtCenter>
              <Space>
                <span
                  className={
                    record.has_lack_of_average_scenario_total_amount
                      ? 'red'
                      : ''
                  }>
                  <RenderCompareRate
                    rate={new Decimal(
                      record.average_scenario_total_amount,
                    ).toFixed(2)}
                    compareRate={averageScenario?.total_rate}
                  />
                </span>
                <span className="cursor-pointer">
                  <QuestionCircleOutlined />
                </span>
              </Space>
            </Popover>
          );
        },
      },
      {
        title: 'Special Terms',
        dataIndex: 'special_terms',
        key: 'special_terms',
        width: 120,
        render: (text, record) => {
          return (
            <>
              {record.vendor?.special_terms?.length > 0 ? (
                <Popover
                  placement="left"
                  content={
                    <List
                      size="small"
                      itemLayout="horizontal"
                      dataSource={record.vendor?.special_terms.map(
                        (t: any) => `${t.name || ''} - ${t.special_term || ''}`,
                      )}
                      renderItem={(item) => (
                        <List.Item>
                          <strong>{item}</strong>
                        </List.Item>
                      )}
                    />
                  }
                  trigger="click">
                  <Tag color="blue">{record.vendor?.special_terms.length}</Tag>
                </Popover>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        title: 'CNTR Size',
        dataIndex: 'container_sizes',
        key: 'container_sizes',
        width: 100,
        render: (text, record) =>
          record.container_sizes && record.container_sizes.length
            ? record.container_sizes.join(',')
            : 'All',
      },
      {
        title: 'Attributes',
        dataIndex: 'cargo_type',
        key: 'cargo_type',
        width: 120,
        render: (text, record) =>
          ['is_haz', 'is_ow', 'is_reefer', 'is_soc', 'is_residential']
            .map((attribute) => {
              return record[attribute]
                ? attribute.replace('is_', '').toUpperCase()
                : '';
            })
            .filter((a) => a)
            .join(',') || '-',
      },
      {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
        width: 220,
        render: (text, record) => (
          <>
            <div>{get(record, 'intermodal_region.name', '')}</div>
            <div className="mt-sm">{record.from_facility_type}</div>
          </>
        ),
      },
      {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
        width: 220,
        render: (text, record) => (
          <>
            <div>{get(record, 'to_city.full_name', '')}</div>
            <div className="mt-sm">
              {' '}
              {record.to_zipcode} {record.to_facility_type}
            </div>
          </>
        ),
      },
      {
        title: 'Valid',
        dataIndex: 'valid',
        key: 'valid',
        width: 100,
        render: (text, record) => {
          return (
            <>
              <Switch
                checked={record.valid}
                onClick={(v) => handleSetValid(record.id, v)}
              />
            </>
          );
        },
      },
      {
        title: 'Visible',
        dataIndex: 'visible',
        key: 'visible',
        width: 100,
        render: (text, record) => {
          return (
            <>
              <Switch
                checked={record.visible}
                onClick={(v) => handleSetVisibility(record.id, v)}
              />
            </>
          );
        },
      },
      {
        title: 'Favored',
        dataIndex: 'favored',
        key: 'favored',
        width: 100,
        render: (text, record) => {
          return (
            <>
              <Switch
                checked={record.admin_favored}
                onClick={(v) => handleSetAdminFavored(record.id, v)}
              />
            </>
          );
        },
      },
      {
        title: 'Locked',
        dataIndex: 'locked',
        key: 'locked',
        width: 100,
        render: (text, record) => {
          return (
            <>
              <Switch
                checked={record.locked}
                onClick={(v) => handleSetLocked(record.id, v)}
              />
            </>
          );
        },
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 100,
        sorter: true,
        sortOrder: toSortOrder('created_at'),
      },
      {
        title: 'Expired',
        dataIndex: 'expired_at',
        key: 'expired_at',
        width: 100,
        sorter: true,
        sortOrder: toSortOrder('expired_at'),
      },
      {
        title: 'Earliest CAP.',
        dataIndex: 'earliest_capacity_at',
        key: 'earliest_capacity_at',
        width: 100,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 250,
        render: (text, record) => (
          <Space split={<Divider type="vertical" />}>
            <a onClick={() => handleEdit(record.id)}>Edit</a>

            <Popconfirm
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}>
              <a>Delete</a>
            </Popconfirm>

            <a onClick={() => handleCopy(record)}>Copy</a>

            <LogActivities id={record.id} type={'rates'} />
          </Space>
        ),
      },
    ];
  }, [data, filterSortBy, filterSortValue, container]);

  return (
    <div className={styles.main}>
      <Header
        title="Buy Rates"
        rightElement={
          <Space>
            <CompareRates rates={selectedRows} />

            <Import onUploaded={fetchData} />

            <Button type="primary" onClick={handleNew}>
              New Rate
            </Button>
            <Button onClick={handleBulkUpdate}>Bulk Update</Button>
          </Space>
        }></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}
        onCreateSellRate={handleCreateSellRate}>
        {' '}
      </Filter>

      {container && (
        <ContainerRow container={container} onSave={fetchContainer} />
      )}

      <div>
        <AutoResizeTable
          loading={loading}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          pagination={pagination}
          size="small"
          rowKey="id"
          columns={columns}
          onChange={(pagination, filters, sorter: any) => {
            let sortBy = sorter.field;
            let sortValue = null;
            if (!sorter.order) {
              sortBy = null;
              sortValue = null;
            } else {
              sortValue = sorter.order === 'ascend' ? 'asc' : 'desc';
            }
            fetchData(pagination, sortBy, sortValue);
          }}
          dataSource={data}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>

      <BuyRateDrawer
        id={rate}
        copyRecord={copyRecord}
        open={visible}
        isNew={isNew}
        onClose={onClose}
        onSaved={handleOnSaved}
      />

      <DrawerFormBulkUpdate
        ids={selectedRowKeys}
        visible={visibleBulkUpdate}
        onClose={handelCloseBulkUpdate}
        onSubmit={handleSaveBulkUpdate}
      />

      <VendorDrawer
        id={vendorId}
        onSaved={fetchData}
        onClose={handleCloseVendorDrawer}
        visible={openVendorDrawer}
      />

      <SellRateDrawer
        visible={openSellRateDrawer}
        id={0}
        initialValues={initialSellRateFormValues}
        onSaved={handleSavedSellRateDrawer}
        onClose={handleCloseSellRateDrawer}
      />
      <VendorRateFormDrawer />
    </div>
  );
};

export default Index;
