import {
  TTarget,
  TARGET_TYPE_LTL_CONTAINERS,
  TARGET_TYPE_LTL_SHIPMENT,
  TARGET_TYPE_FTL_SHIPMENT,
} from './ActionInterface';

const TASK_TYPE_DOC = 'doc';
const TASK_TYPE_PICKUP_WAREHOUSE = 'pickup_warehouse';
const TASK_TYPE_CAR = 'car';
const TASK_TYPE_RECEIVING_WAREHOUSE = 'receiving_warehouse';

const TASK_TYPE_FILE = 'file';
const TASK_TYPE_VENDOR = 'vendor';
const TASK_TYPE_WAREHOUSE = 'warehouse';
const TASK_TYPE_TODOLIST = 'todo_list'; //only for frontend

const LTLSHIPEMNT_TASK_TYPES = {
  [TASK_TYPE_DOC]: 'Doc',
  [TASK_TYPE_PICKUP_WAREHOUSE]: 'Pickup Warehouse',
  [TASK_TYPE_CAR]: 'Car',
  [TASK_TYPE_RECEIVING_WAREHOUSE]: 'Receiving Warehouse',
  [TASK_TYPE_TODOLIST]: 'To Do List',
};

const FTLSHIPEMNT_TASK_TYPES = {
  [TASK_TYPE_DOC]: 'Doc',
  [TASK_TYPE_PICKUP_WAREHOUSE]: 'Pickup Warehouse',
  [TASK_TYPE_CAR]: 'Car',
  [TASK_TYPE_RECEIVING_WAREHOUSE]: 'Receiving Warehouse',
  [TASK_TYPE_TODOLIST]: 'To Do List',
};

const CONTAINER_TASK_TYPES = {
  [TASK_TYPE_FILE]: 'Doc',
  [TASK_TYPE_VENDOR]: 'Car',
  [TASK_TYPE_WAREHOUSE]: 'WHS',
  [TASK_TYPE_TODOLIST]: 'To Do List',
};

const getCheckedTaskTypeList = (target: TTarget['target']) => {
  switch (target) {
    case TARGET_TYPE_LTL_SHIPMENT:
      return Object.keys(LTLSHIPEMNT_TASK_TYPES);
    case TARGET_TYPE_FTL_SHIPMENT:
      return Object.keys(FTLSHIPEMNT_TASK_TYPES);
    case TARGET_TYPE_LTL_CONTAINERS:
      return Object.keys(CONTAINER_TASK_TYPES);
  }
};

const getOptions = (target: TTarget['target']) => {
  const taskTypes = getCheckedTaskTypeList(target);

  const setOption = (label, value) => ({ label, value });

  switch (target) {
    case TARGET_TYPE_LTL_SHIPMENT:
      return taskTypes.map((taskType) =>
        setOption(LTLSHIPEMNT_TASK_TYPES[taskType], taskType),
      );
    case TARGET_TYPE_FTL_SHIPMENT:
      return taskTypes.map((taskType) =>
        setOption(FTLSHIPEMNT_TASK_TYPES[taskType], taskType),
      );
    case TARGET_TYPE_LTL_CONTAINERS:
      return taskTypes.map((taskType) =>
        setOption(CONTAINER_TASK_TYPES[taskType], taskType),
      );
  }
};

export default {
  TASK_TYPE_DOC,
  TASK_TYPE_PICKUP_WAREHOUSE,
  TASK_TYPE_CAR,
  TASK_TYPE_RECEIVING_WAREHOUSE,
  LTLSHIPEMNT_TASK_TYPES,
  FTLSHIPEMNT_TASK_TYPES,
  CONTAINER_TASK_TYPES,
  getCheckedTaskTypeList,
  getOptions,
  TASK_TYPE_FILE,
  TASK_TYPE_VENDOR,
  TASK_TYPE_WAREHOUSE,
  TASK_TYPE_TODOLIST,
} as const;
