import { Select } from 'antd';
import React, { useMemo } from 'react';
import {
  DOCUMENT_FILE_TYPES,
  DOCUMENT_FILE_TYPES_DEFAULT_CUSTOMER_VISIBLE_MAP,
} from './data';
import { get, has } from 'lodash';

interface ISelectDocumentType {
  onChange: (values: any) => void;
}

export const SelectDocumentType: React.FC<ISelectDocumentType> = ({
  onChange,
  ...props
}) => {
  const handleUpdateDocumentType = (val: any) => {
    const values = { document_type: val };

    if (has(DOCUMENT_FILE_TYPES_DEFAULT_CUSTOMER_VISIBLE_MAP, val)) {
      values.customer_visible =
        DOCUMENT_FILE_TYPES_DEFAULT_CUSTOMER_VISIBLE_MAP[val];
    }

    onChange(values);
  };

  return (
    <Select
      {...props}
      onChange={handleUpdateDocumentType}
      showSearch
      filterOption={(input: any, option: any) =>
        (option?.children ?? '').toLowerCase()?.includes(input.toLowerCase())
      }
      placeholder="Document type"
    >
      {DOCUMENT_FILE_TYPES.map((type, index) => (
        <Select.Option key={+index} value={type}>
          {type}
        </Select.Option>
      ))}
    </Select>
  );
};
